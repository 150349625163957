import { useState, useEffect } from 'react'
import '../../css/Common.scss'
import { Option } from '../../components/OrderSequence/type'
import { FilterSortOption } from '../../components/utility/utils'
import VerticalChoiceLayout from '../../components/OrderSequence/PageLayout/VerticalChoice'
export default function SelectSize() {
  let storage = sessionStorage
  let optionData = JSON.parse(storage.getItem('options') as string)
  const [isSetFinished, setIsSetFinished] = useState(false)
  const [sizeOptions, setSizeOptions] = useState<Option[]>([])

  // 로드된 옵션 중에서 size에 관한 것만 받아서 option list를 만든다.
  useEffect(() => {
    if (optionData && !isSetFinished) {
      let tempSort = FilterSortOption(optionData, 'size')
      const design = JSON.parse(storage.getItem('design') as string)

      // 디자인 요소에 따른 필터링
      if (design.name === '리스') {
        tempSort = tempSort.filter((x: any) => x.name !== '미니')
      }
      if (design.name === '보자기') {
        tempSort = tempSort.filter((x: any) => ['1호', '2호'].includes(x.name))
      }
      setSizeOptions(tempSort)
      setIsSetFinished(true)
    }
  }, [optionData])

  return (
    <VerticalChoiceLayout
      progressOrder={5}
      title={'크기 선택'}
      subTitle={'행사 규모에 맞추어 사이즈를 골라주세요'}
      argument={'size'}
      options={sizeOptions}
    />
  )
}
