import dayjs from 'dayjs'
import { CaretLeft, CaretRight } from 'phosphor-react'
import { useEffect, useMemo, useState } from 'react'
import { getWeekDays } from '../../../utils/get-week-days'
import {
  CalendarActions,
  CalendarBody,
  CalendarContainer,
  CalendarHeader,
} from './calanderUserStyles'
import CalendarAdminDay from './CalendarAdminDay'

interface CalendarWeek {
  week: number
  days: Array<{
    date: dayjs.Dayjs
    disabled: boolean
    blocked: boolean
    manualBlocked: boolean
    timeBlocked: boolean
  }>
}

type CalendarWeeks = CalendarWeek[]

export default function CalendarAdmin({
  lastSelectedDay,
  onClickTargetDay,
  selected,
  contentWidth,
  blockedDay,
  blockedDatesFormated,
  blockedTimes,
  information,
  startDate,
  endDate,
}: any) {
  const dayjs = require('dayjs')
  const timezone = require('dayjs/plugin/timezone')
  const utc = require('dayjs/plugin/utc')

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Asia/Seoul')

  const [currentDate, setCurrentDate] = useState(() => {
    return dayjs().set('date', 1)
  })
  useEffect(() => {
    if (information && 'defaultMonth' in information) {
      setCurrentDate(
        dayjs()
          .set('month', Number(information.defaultMonth) - 1)
          .set('date', 1),
      )
    }
  }, [information])

  function handlePreviousMonth() {
    // 이전달로 넘어갈 때에 currentDate의 달을 1달 줄인다.
    const previousMonthDate = currentDate.subtract(1, 'month')
    setCurrentDate(previousMonthDate)
  }

  function handleNextMonth() {
    // 다음 달로 넘어갈 때에 currentDate의 달을 1달 높인다.
    const nextMonthDate = currentDate.add(1, 'month')
    setCurrentDate(nextMonthDate)
  }

  // MON ~ SUN
  const shortWeekDays = getWeekDays({ short: true })

  const currentMonth = currentDate.format('MM')
  const currentYear = currentDate.format('YYYY')

  const [blockDates, setBlockDates] = useState<number[]>([])
  const [nextBlockDates, setNextBlockDates] = useState<number[]>([])
  useEffect(() => {
    if (blockedDatesFormated) {
      let datesFiltered: any = []
      let datesFilteredNextMonth: any = []
      blockedDatesFormated.forEach((dateString: string) => {
        const [month, date] = dateString.split(':')
        if (Number(currentMonth) === Number(month)) {
          datesFiltered.push(Number(date))
        }
        if (Number(currentMonth) + 1 === Number(month)) {
          datesFilteredNextMonth.push(Number(date))
        }
      })
      setBlockDates(datesFiltered)
      setNextBlockDates(datesFilteredNextMonth)
    }
  }, [blockedDatesFormated, currentDate])
  const [timeBlockedDates, setTimeBlockedDates] = useState<number[]>([])
  useEffect(() => {
    if (blockedTimes) {
      let timesFiltered: number[] = []
      for (let index = 0; index < blockedTimes.length; index++) {
        const date = blockedTimes[index]
        if (!timesFiltered.includes(date.get('date'))) {
          if (Number(currentMonth) - 1 === date.get('month')) {
            timesFiltered.push(date.get('date'))
          }
        }
      }
      setTimeBlockedDates(timesFiltered)
    }
  }, [blockedTimes, currentDate])

  const blockedDatesData = {
    blockedWeekDays: blockedDay,
    blockedDates: blockDates,
    timeBlockedDates: timeBlockedDates,
    blockedDatesNextMonth: nextBlockDates,
  }

  // calendarWeeks는 blockDates가 없으면 빈 리스트
  const calendarWeeks = useMemo(() => {
    if (!blockedDatesData) {
      return []
    }
    // currentDate 가 해당 달의 1일로 되어있기 때문에
    // 해당 달의 수만큼 날짜 array를 만든다.
    const daysInMonthArray = Array.from({
      length: currentDate.daysInMonth(),
    }).map((_, i) => {
      return currentDate.set('date', i + 1)
    })
    const todayDate = dayjs().tz()
    let startActiveDate = todayDate.add(3, 'day')
    let endActiveDate = todayDate.add(24, 'day')

    // 시작일과 종료일을 지정한 경우 변경
    if (startDate !== null && endDate !== null) {
      startActiveDate = dayjs(startDate)
      endActiveDate = dayjs(endDate).add(1, 'day')
    }

    // 그달의 1일 요일
    let firstWeekDay = currentDate.get('day')

    // 일요일인 경우 월~토까지 만들어져야 한다.
    if (firstWeekDay == 0) {
      firstWeekDay = 7
    }
    // 3월 1일이 목요일이라면
    // firstWeekDay = 3 (0부터 월요일)
    // 앞에 보여져야 하는 날짜들은 2월 26일 27일 28 일
    const previousMonthFillArray = Array.from({
      length: firstWeekDay,
    })
      .map((_, i) => {
        return currentDate.subtract(i + 1, 'day')
      })
      .reverse()
    // 3월 31일
    const lastDayInCurrentMonth = currentDate.set(
      'date',
      currentDate.daysInMonth(),
    )
    //토요일 -> 5
    const lastWeekDay = lastDayInCurrentMonth.get('day')

    // length:1 [4월 1일]
    const nextMonthFillArray = Array.from({
      length: 7 - lastWeekDay,
    }).map((_, i) => {
      return lastDayInCurrentMonth.add(i + 1, 'day')
    })

    // 달력에 표시되어야 하는 날짜들
    // 이전 달에 있지만, 표시되어야 하는 날짜들'
    // 이번 달 날짜들
    // 다음 달 날짜들
    // disabled는 선택가능한지 아닌지를 뜻한다.
    const calendarDays = [
      ...previousMonthFillArray.map(date => {
        return {
          date,
          disabled:
            date.endOf('day').isBefore(startActiveDate) ||
            date.endOf('day').isAfter(endActiveDate),
          manualBlocked: false,
          blocked: false,
          timeBlocked: blockedDatesData.timeBlockedDates.includes(
            date.get('date'),
          ),
        }
      }),
      ...daysInMonthArray.map(date => {
        return {
          date,
          disabled:
            // 오늘보다 과거이거나, block 요일 / 날짜에 속해있으면 disabled
            date.endOf('day').isBefore(startActiveDate) ||
            date.endOf('day').isAfter(endActiveDate),
          manualBlocked: blockedDatesData.blockedDates.includes(
            date.get('date'),
          ),
          blocked: blockedDatesData.blockedWeekDays.includes(date.get('day')),
          timeBlocked: blockedDatesData.timeBlockedDates.includes(
            date.get('date'),
          ),
        }
      }),
      ...nextMonthFillArray.map(date => {
        return {
          date,
          disabled:
            date.endOf('day').isBefore(startActiveDate) ||
            date.endOf('day').isAfter(endActiveDate),
          manualBlocked: blockedDatesData.blockedDatesNextMonth.includes(
            date.get('date'),
          ),
          blocked: blockedDatesData.blockedWeekDays.includes(date.get('day')),
          timeBlocked: blockedDatesData.timeBlockedDates.includes(
            date.get('date'),
          ),
        }
      }),
    ]
    // 날짜들을 주별로 나눠서 {week : number, days: Dayjs객체 } 객체로 집어넣음
    const calendarWeeks = calendarDays.reduce<CalendarWeeks>(
      (weeks, _, i, original) => {
        // 월요일 ~ 일요일이기 때문 (아니면 ===0)
        const isNewWeek = i % 7 === 1

        if (isNewWeek) {
          weeks.push({
            week: i / 7 + 1,
            days: original.slice(i, i + 7),
          })
        }
        return weeks
      },
      [],
    )
    return calendarWeeks
  }, [currentDate, blockedDatesData])

  const titleStyle = {
    marginTop: 32,
    marginBottom: 45,
    width: '300px',
    height: '50px',
    display: 'inline-block',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '25px',
    /* or 125% */

    textAlign: 'center' as 'center',
    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const dateTitleStyle = {
    fontFamily: 'SF Pro Display',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '25px',
    lineHeight: '30px',
    color: '#000000',
  }
  const legendStyle = {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: 34,
    marginBottom: 10,
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={titleStyle}>
        주문이 불가능한 요일과
        <br />
        픽업이 어려운 시간을 닫아주세요
      </div>
      <CalendarContainer style={{ width: contentWidth }}>
        <CalendarHeader>
          <div>
            <CalendarActions style={{ width: contentWidth }}>
              <button onClick={handlePreviousMonth} title="prev">
                <CaretLeft />
              </button>
              <div style={dateTitleStyle}>
                {currentYear}.{currentMonth}
              </div>
              <button onClick={handleNextMonth} title="next">
                <CaretRight />
              </button>
            </CalendarActions>
          </div>
        </CalendarHeader>

        <CalendarBody
          style={{
            marginLeft: 25,
            width: contentWidth - 50,
          }}
        >
          <thead>
            <tr>
              {shortWeekDays.map((weekDay: any) => (
                <th key={weekDay}>{weekDay}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {calendarWeeks.map(({ week, days }) => {
              return (
                <tr key={week}>
                  {days.map(
                    ({
                      date,
                      disabled,
                      blocked,
                      manualBlocked,
                      timeBlocked,
                    }) => {
                      return (
                        <td key={date.toString()}>
                          <CalendarAdminDay
                            onClickTargetDay={onClickTargetDay}
                            date={date}
                            disabled={disabled}
                            selected={selected}
                            blocked={blocked}
                            manualBlocked={manualBlocked}
                            timeBlocked={timeBlocked}
                          ></CalendarAdminDay>
                        </td>
                      )
                    },
                  )}
                </tr>
              )
            })}
          </tbody>
        </CalendarBody>
      </CalendarContainer>
      <div
        style={{
          display: 'inline-block',
          width: contentWidth - 68,
          borderBottom: '1px solid #EAEAEA',
          marginTop: 25,
          marginBottom: 20,
        }}
      ></div>
      <div style={legendStyle}>
        <div
          style={{
            height: 8,
            width: 8,
            marginRight: 8,
            background: '#E86C6C',
            borderRadius: '17.5px',
          }}
        ></div>
        <p
          style={{
            fontSize: 15,
            color: '#727272',
          }}
        >
          휴일
        </p>
      </div>
      <div style={legendStyle}>
        <div
          style={{
            height: 8,
            width: 8,
            marginRight: 8,
            background: '#C5C5C5',
            borderRadius: '17.5px',
          }}
        ></div>
        <p
          style={{
            fontSize: 15,
            color: '#727272',
          }}
        >
          주문 불가
        </p>
      </div>
      <div style={legendStyle}>
        <div
          style={{
            height: 8,
            width: 8,
            marginRight: 8,
            borderRadius: '17.5px',
            border: '1px dashed #B0B0B0',
          }}
        ></div>
        <p
          style={{
            fontSize: 15,
            color: '#727272',
          }}
        >
          일부 시간대 픽업 불가
        </p>
      </div>
    </div>
  )
}
