import { getShop, changeOrderSheet } from '../../../api'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ProtectedPage from '../../../components/ProtectedPage'
import dayjs, { Dayjs } from 'dayjs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PIckupTimeButtons from '../../../components/ShopAdmin/Calendar/PIckupTimeButtons'

import Modal from 'react-modal'
import { parseTime } from '../../../components/utility/utils'

export default function CalendarPickupOptions() {
  const { shopUuid, shopName } = useParams()
  const shopQuery = useQuery(['shop', shopUuid], getShop)

  //sessionStorage
  let storage = sessionStorage
  let contentWidth = 397
  contentWidth = Number(storage.getItem('contentWidth'))
  const orderPk = storage.getItem('orderPk')
  const orderType = storage.getItem('orderType')
  const dayjs = require('dayjs')
  const timezone = require('dayjs/plugin/timezone')
  const utc = require('dayjs/plugin/utc')
  const startTime = parseTime(storage.getItem('startTime') as string)
  const endTime = parseTime(storage.getItem('endTime') as string)

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Asia/Seoul')
  let currentDate = dayjs(storage.getItem('currentDate')).tz()

  storage.setItem('lastSelectedDay', currentDate.toString())
  // 날짜가 선택되면 해당 날짜에 세팅된
  const [timeSelected, setTimeSelected] = useState<string[]>([])
  const [blockedTimeOfDate, setBlockedTimeOfDate] = useState<string[]>([])
  let isBlockedOrigin = storage.getItem('currentBlocked') === 'true'

  const checkSame = (origin: any[], current: any[]) => {
    if (origin === current) return true
    if (origin == null || current == null) return false
    if (origin.length !== current.length) return false
    origin.sort()
    current.sort()
    for (var i = 0; i < origin.length; ++i)
      if (origin[i] !== current[i]) return false
    return true
  }

  // 시간, 날짜 중 하나라도 변했는지 확인
  const checkSomethingChanged = () => {
    return (
      !(isBlockedOrigin === currentDateBlocked) ||
      !checkSame(timeSelected, blockedTimeOfDate)
    )
  }

  // 모달 관련
  const [modalSuccessIsOpen, setModalSuccessIsOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const nav = useNavigate()
  const onClickX = () => {
    if (checkSomethingChanged()) {
      setModalIsOpen(true)
    } else {
      nav(-1)
    }
  }
  // 저장 프로세스 API -> Success 이면 모달
  const onClickSave = () => {
    changeOrderSheetMutation.mutate({
      pk: orderPk,
      shopUuid: shopUuid,
      is_date_changed: isBlockedOrigin !== currentDateBlocked,
      is_date_blocked: currentDateBlocked,
      is_time_changed: !checkSame(timeSelected, blockedTimeOfDate),
      current_date: currentDate,
      blocked_time: timeSelected,
    })
  }
  const changeOrderSheetMutation = useMutation(changeOrderSheet, {
    onSuccess: (data: any) => {
      setModalSuccessIsOpen(true)
    },
    onError: (data: any) => {
      alert(data)
    },
  })
  const [currentDateBlocked, setCurrentDateBlocked] = useState(false)
  // 날짜 시간 변경 감지,
  // 날짜 혹은 시간이 변경된 경우에는 Button을 Able 시키고,
  // X 눌렀을 때 modal이 뜨도록 해야 한다.
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  useEffect(() => {
    if (checkSomethingChanged()) {
      setSaveButtonDisabled(false)
    } else {
      setSaveButtonDisabled(true)
    }
  }, [currentDateBlocked, timeSelected])
  const [isFinished, setIsFinished] = useState(false)
  useEffect(() => {
    let blockedTimes = JSON.parse(storage.getItem('blockedTime') as string)
    if (blockedTimes && !isFinished) {
      const clickedMonth = currentDate.get('month')
      const clickedDate = currentDate.get('date')
      let timeTemp: string[] = []
      blockedTimes.forEach((time: string) => {
        let timeTmp = dayjs(time)
        const d = timeTmp.get('date')
        const m = timeTmp.get('month')

        if (d === clickedDate && m === clickedMonth) {
          timeTemp.push(timeTmp.format('HH:mm'))
        }
      })
      setTimeSelected(timeTemp)
      setBlockedTimeOfDate(timeTemp)
      setCurrentDateBlocked(isBlockedOrigin)
      setSaveButtonDisabled(isBlockedOrigin)
      setIsFinished(true)
    }
  }, [])

  useEffect(() => {
    if (timeSelected.length === 16) {
      setCurrentDateBlocked(true)
    }
  }, [timeSelected])
  const onClickDateDisabled = () => {
    setCurrentDateBlocked(true)
  }
  const onClickDateAbled = () => {
    setCurrentDateBlocked(false)
  }

  const onClickFinish = () => {
    nav(-1)
    setModalIsOpen(false)
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      textAlign: 'center' as 'center',
      width: 260,
      height: 167.94,
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
    },
  }
  const buttonDisabled = {
    width: (contentWidth - 7 - 40) / 2,
    height: '50px',
    /* Auto layout */

    justifyContent: 'center',
    alignItems: 'center',
    padding: '13px 30px',

    display: 'inline-block',
    background: '#FFFFFF',
    border: '1px solid #E2E5E8',
    borderRadius: '8px',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    /* identical to box height, or 100% */
    opacity: 0.5,

    letterSpacing: '-0.01em',

    color: '#222222',
  }
  const buttonActive = {
    width: (contentWidth - 7 - 40) / 2,
    height: '50px',
    /* Auto layout */

    justifyContent: 'center',
    alignItems: 'center',
    padding: '13px 30px',

    display: 'inline-block',
    background: '#EEFAF3',
    border: `2px solid #0BAA5D`,
    borderRadius: '8px',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    /* identical to box height, or 100% */

    letterSpacing: '-0.01em',

    color: '#222222',
  }

  const title = {
    width: '150px',
    height: '25px',

    fontFamily: 'SF Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '23px',
    lineHeight: '25px',
    /* identical to box height, or 109% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }

  const subTitle = {
    marginTop: 45,
    textAlign: 'left' as 'left',
    width: '300px',
    marginBottom: '16px',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const buttonDisabledStyle = {
    background: '#B8B8B8',
    borderRadius: '10px',
    height: 50,

    marginTop: 35,
    marginBottom: 125,
    width: contentWidth - 40,
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#FFFFFF',
  }
  const buttonActiveStyle = {
    background: '#0BAA5D',
    borderRadius: '10px',
    height: 50,

    marginTop: 35,
    marginBottom: 125,
    width: contentWidth - 40,
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#FFFFFF',
  }

  const modalTitle = {
    display: 'inline-block',
    marginTop: 30,
    width: '100%',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    marginBottom: 10,
    fontSize: '18px',
    lineHeight: '22px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const modalSubTitle = {
    display: 'inline-block',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 10,
    fontSize: '15px',
    lineHeight: '18px',
    paddingLeft: 10,
    paddingRight: 10,
    /* or 125% */

    letterSpacing: '-0.02em',

    color: '#727272',
  }
  return (
    <ProtectedPage shopQuery={shopQuery} shopUuid={shopUuid}>
      <div style={{ width: contentWidth }}>
        <div
          style={{
            marginLeft: 20,
            marginTop: 30,
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 35,
            width: contentWidth - 40,
          }}
        >
          <div style={title}>
            {currentDate ? currentDate.get('month') + 1 : 0}월{' '}
            {currentDate?.get('date')}일 설정
          </div>
          <button onClick={onClickX}>
            <img
              style={{
                height: 15.8,
                width: 15.8,
              }}
              src="/x.png"
            />
          </button>
          <Modal isOpen={modalIsOpen} style={customStyles}>
            <div style={modalTitle}>페이지를 나가시겠습니까?</div>
            <div style={modalSubTitle}>설정 내용은 저장되지 않습니다</div>

            <div
              style={{
                display: 'flex',
                marginTop: 'auto',
                position: 'absolute',
                bottom: 0,
                justifyContent: 'space-between',
                borderTop: '1px solid  #EAEAEA',
                width: '100%',
              }}
            >
              <button
                style={{
                  width: customStyles.content.width / 2,
                  height: 52,
                  borderRight: '1px solid  #EAEAEA',
                }}
                onClick={() => {
                  setModalIsOpen(false)
                }}
              >
                취소
              </button>
              <button
                style={{
                  width: customStyles.content.width / 2,
                  height: 52,
                }}
                onClick={onClickFinish}
              >
                나가기
              </button>
            </div>
          </Modal>
        </div>
        <div style={{ marginLeft: 20 }}>
          <div style={subTitle}>주문이 가능한 날인가요?</div>
        </div>
        <div
          style={{
            marginLeft: 20,
            display: 'flex',
            width: contentWidth - 40,
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={onClickDateAbled}
            style={currentDateBlocked ? buttonDisabled : buttonActive}
          >
            {' '}
            주문 가능
          </button>
          <button
            onClick={onClickDateDisabled}
            style={currentDateBlocked ? buttonActive : buttonDisabled}
          >
            {' '}
            주문 불가
          </button>
        </div>
        <div style={{ marginLeft: 20 }}>
          <div style={subTitle}>픽업이 어려운 시간은 닫아주세요</div>
          <PIckupTimeButtons
            currentDate={currentDate}
            dateBlocked={currentDateBlocked}
            contentWidth={contentWidth}
            timeSelected={timeSelected}
            setTimeSelected={setTimeSelected}
            blockedTimeOfDate={blockedTimeOfDate}
            setBlockedTimeOfDate={setBlockedTimeOfDate}
            color3="#EEFAF3"
            color2="#8BD2A8"
            startTime={startTime}
            endTime={endTime}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: contentWidth,
          }}
        >
          <button
            onClick={onClickSave}
            disabled={saveButtonDisabled}
            style={saveButtonDisabled ? buttonDisabledStyle : buttonActiveStyle}
          >
            {' '}
            저장
          </button>
        </div>

        <Modal isOpen={modalSuccessIsOpen} style={customStyles}>
          <div style={modalTitle}>주문가능일 변경완료</div>
          <div style={modalSubTitle}>
            유저에게도 변경된 날짜와 시간으로 보여집니다
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: 'auto',
              position: 'absolute',
              bottom: 0,
              justifyContent: 'space-between',
              borderTop: '1px solid  #EAEAEA',
              width: '100%',
            }}
          >
            <button
              style={{
                width: customStyles.content.width,
                height: 52,
                borderRight: '1px solid  #EAEAEA',
              }}
              onClick={() => {
                setModalSuccessIsOpen(false)
                nav(`/shopAdmin/${shopUuid}/${shopName}/${orderType}`)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      </div>
    </ProtectedPage>
  )
}
