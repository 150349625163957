import { SetStateAction, useState } from 'react'
import '../../../css/ShopAdmin/MainPage/OrderSheetToggle.scss'
import { useNavigate } from 'react-router-dom'

export default function OrderSheetToggle({
  shopUuid,
  shopName,
  orderType,
}: any) {
  const nav = useNavigate()
  const [selectedOrderType, setSelectedOrderType] = useState(orderType)

  const handleToggleClick = (orderType: SetStateAction<string>) => {
    setSelectedOrderType(orderType)
    nav(`/shopAdmin/${shopUuid}/${shopName}/${orderType}`)
  }

  return (
    <div className="order-sheet-toggle-container">
      <div
        className={`order-sheet-toggle-button ${
          selectedOrderType === 'C' ? 'order-sheet-toggle-button-selected' : ''
        }`}
        onClick={() => handleToggleClick('C')}
      >
        케이크 주문
      </div>
      <div
        className={`order-sheet-toggle-button ${
          selectedOrderType === 'P' ? 'order-sheet-toggle-button-selected' : ''
        }`}
        onClick={() => handleToggleClick('P')}
      >
        떡주문
      </div>
    </div>
  )
}
