import '../../../css/SlickProduct.scss'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useState } from 'react'

export default function ProductPhotoSlider({
  contentWidth,
  example_photos,
}: any) {
  const [activeSlide, setActiveSlide] = useState(0)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (next: any) => {
      setActiveSlide(next)
    },
    // afterChange: (current: any) => setActiveSlide2(current)
  }
  return (
    <>
      <Slider style={{ width: contentWidth, height: 250 }} {...settings}>
        {example_photos?.map((photo: any) => (
          <img
            style={{
              width: '100%',
              height: 250,
              objectFit: 'cover',
            }}
            src={photo.src}
          />
        ))}
      </Slider>
      <div style={{ height: 0, width: contentWidth, textAlign: 'center' }}>
        <div className="product-photo-number-nav">
          <span>
            {activeSlide + 1} / {example_photos ? example_photos.length : 0}
          </span>
        </div>
      </div>
    </>
  )
}
