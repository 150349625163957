import { useState, useEffect } from 'react'
import { Option } from '../type'
import { Address } from '../Address/PostCode'
import {
  CalculatePrice,
  FilterBySelectedOption,
  FilterSortOption,
  GetFilterFromOption,
} from '../../utility/utils'
import { UserInfo } from './OrderContent/UserInfo'
import { DateTime } from './OrderContent/DateTme'
import { OptionTitle } from './OrderContent/OptionTitle'
import { OptionContent } from './OrderContent/OptionContent'
import { CustomizeMessage } from './OrderContent/CustomMessage'
import { FinishButtonWithPrice } from '../PageElement/NextButton'
import { DesignContent } from './OrderContent/DesignContent'

interface CakeOrderSheetProps {
  onCapture: Function
  onLoadPage: Function
  setClipBoardCopyLoaded: React.Dispatch<React.SetStateAction<any>>
}
export function CakeOrderSheet({
  onCapture,
  onLoadPage,
  setClipBoardCopyLoaded,
}: CakeOrderSheetProps) {
  sessionStorage.setItem('checkMode', '1')
  const [storage, setStorage] = useState(sessionStorage)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const [displaySequence, setDisplaySequence] = useState<any[]>([])
  const color1 = storage.getItem('color1') as string
  const exampleImages = JSON.parse(storage.getItem('images') as string)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [anyVacant, setAnyVacant] = useState(false)
  const [designOption, setDesignOption] = useState<Option>()
  const [sizeOption, setSizeOption] = useState<Option>()
  const [packageOption, setPackageOption] = useState<Option>()
  const [letteringOption, setLetteringOption] = useState<Option>()
  const [baseOption, setBaseOption] = useState<Option>()
  const [numberOption, setNumberOption] = useState<Option>()
  const [letteringContent, setLetteringContent] = useState(
    storage.getItem('letteringContent'),
  )
  const [number, setNumber] = useState(storage.getItem('numberContent'))
  const [quickRequired, setQuickRequired] = useState(
    storage.getItem('quickRequired'),
  )
  const [isDesignFreeAllowed, setIsDesignFreeAllowed] = useState(false)
  const [originPrice, setOriginPrice] = useState(0)
  const checkFreeAllowed = () => {
    if (storage.getItem('startPoint') === 'Photo') {
      return true
    }
    return false
  }

  const changeOptionByOtherOption = (
    optionToChange: Option,
    designOption: Option,
  ) => {
    //args: optionToChange, designOption
    // designOption의 filter로 지정된 option들 중에서 optionToChange와 이름이 동일한 option을 찾아서 내보낸다.
    const allOptions = JSON.parse(sessionStorage.getItem('options') as string)
    let filter = GetFilterFromOption(designOption)
    if (!filter) {
      return optionToChange
    }
    if (designOption && allOptions) {
      const optionFiltered = FilterBySelectedOption(
        allOptions,
        optionToChange.option_type,
        [designOption],
      )
      const option = optionFiltered.find(
        (option: Option) => option.name === optionToChange.name,
      )
      sessionStorage.setItem(optionToChange.option_type, JSON.stringify(option))
      return option
    }
    throw new Error('design Option에 의해 변경되지 못했습니다')
  }
  const checkCompatible = (design: Option, otherOption: Option) => {
    let filter = GetFilterFromOption(design)
    if (!filter) {
      return otherOption
    }
    if (otherOption.option_type in design.information.filter) {
      if (
        design.information.filter[otherOption.option_type].includes(
          otherOption.id,
        )
      ) {
        return otherOption
      } else {
        return null
      }
    }
    return otherOption
  }
  useEffect(() => {
    setStorage(sessionStorage)
    let design = JSON.parse(sessionStorage.getItem('design') as string)

    setDesignOption(design)
    let size = JSON.parse(sessionStorage.getItem('size') as string)
    if (design && size) {
      size = checkCompatible(design, size)
    }
    setSizeOption(size)

    let packageOption = JSON.parse(sessionStorage.getItem('package') as string)
    if (design && packageOption) {
      packageOption = checkCompatible(design, packageOption)
    }
    setPackageOption(packageOption)
    setLetteringOption(
      JSON.parse(sessionStorage.getItem('lettering') as string),
    )
    let base = JSON.parse(sessionStorage.getItem('base') as string)

    if (size && base) {
      base = changeOptionByOtherOption(base, size as Option)
    }
    if (design && base) {
      base = changeOptionByOtherOption(base, design as Option)
    }
    setBaseOption(base)
    let number = JSON.parse(sessionStorage.getItem('number') as string)
    if (design && number) {
      number = changeOptionByOtherOption(number, design as Option)
    }
    setNumberOption(number)
    setQuickRequired(sessionStorage.getItem('quickRequired'))
    setNumber(sessionStorage.getItem('numberContent'))
    setLetteringContent(sessionStorage.getItem('letteringContent'))
    setOriginPrice(CalculatePrice(''))
    setIsDesignFreeAllowed(checkFreeAllowed())
  }, [])
  const isRequestSkip = (designOption: Option, request: any) => {
    if (designOption.next_point === 'number' && request.option == 'size') {
      return true
    }
    if (designOption.next_point === 'size' && request.option == 'number') {
      return true
    }
    return false
  }
  const [optionDisplayList, setOptionDisplayList] = useState<any[]>()
  useEffect(() => {
    if (designOption) {
      if (
        'requestList' in
        JSON.parse(sessionStorage.getItem('information') as string)
      ) {
        const requestList = JSON.parse(
          sessionStorage.getItem('information') as string,
        ).requestList
        let displaySeq: any[] = []
        requestList.map((request: any) => {
          if (!isRequestSkip(designOption, request))
            displaySeq.push([
              request.option,
              request.desc,
              JSON.parse(sessionStorage.getItem(request.option) as string),
            ])
        })
        setDisplaySequence(displaySeq)
      } else {
        //TODO Delete THis Else and Throw Error
        // Before Delete This Fill All RequestList in OrderSheet Information
        if (
          designOption.name === '숫자' ||
          designOption.next_point === 'number'
        ) {
          setDisplaySequence([
            ['number', '숫자 개수', numberOption],
            ['base', '설기의 맛', baseOption],
            ['lettering', '레터링 문구', letteringOption],
            ['package', '포장박스', packageOption],
          ])
        } else {
          setDisplaySequence([
            ['size', '케이크의 크기', sizeOption],
            ['base', '설기의 맛', baseOption],
            ['lettering', '레터링 문구', letteringOption],
            ['package', '포장박스', packageOption],
          ])
        }
      }
    }
  }, [designOption])

  const onClickSubmit = () => {
    if (designOption && designOption.name === '자유형') {
      onCapture()
    } else {
      if (exampleImages && exampleImages.length !== 0) {
        storage.setItem('link', exampleImages[0])
      } else {
        storage.setItem('link', '')
      }
      setClipBoardCopyLoaded(true)
    }
  }

  useEffect(() => {
    if (displaySequence.length !== 0) {
      let newDisplayList = displaySequence.map((dpRow: any[]) => {
        const storageKey = dpRow[0]
        const title = dpRow[1]
        const optionJson = dpRow[2]
        let optionType =
          storageKey.charAt(0).toUpperCase() + storageKey.slice(1)
        let message = ''
        let price = 0
        let isVacant = false
        if (optionJson) {
          if (storageKey === 'lettering') {
            // 문구 있음의 경우 letter를 대신 표시해야 함
            message = letteringContent ? letteringContent : optionJson.name
            price = optionJson.value
          } else if (storageKey === 'number') {
            message = number ? number : optionJson.name
            price = optionJson.value
          } else {
            message = optionJson.name
            price = optionJson.value
          }
        } else {
          setAnyVacant(true)
          message = title
          isVacant = true
        }
        return { title, message, optionType, price, isVacant }
      })
      setOptionDisplayList(newDisplayList)
    }
  }, [displaySequence])

  const [address, setAddress] = useState<Address>({
    우편번호: '',
    기본주소: '',
    상세주소: '',
  })
  useEffect(() => {
    if (anyVacant) {
      setButtonDisabled(true)
    } else {
      if (quickRequired) {
        if (address.기본주소 && address.상세주소) {
          setButtonDisabled(false)
        } else {
          setButtonDisabled(true)
        }
      } else {
        setButtonDisabled(false)
      }
    }
  }, [quickRequired, address, anyVacant])
  return (
    <>
      <UserInfo />
      <DateTime address={address} setAddress={setAddress} />
      <div>
        <OptionTitle title={'원하는 디자인'} mandatory={true} />
        <DesignContent
          isDesignFreeAllowed={isDesignFreeAllowed}
          exampleImages={exampleImages}
          design={designOption as Option}
        />
      </div>
      {optionDisplayList?.map(value => (
        <div key={value.title}>
          <OptionTitle title={value.title} mandatory={true} />
          <OptionContent
            optionType={value.optionType}
            message={value.message}
            imgs={[]}
            price={value.price}
            isVacant={value.isVacant}
          />
        </div>
      ))}
      <CustomizeMessage />
      <FinishButtonWithPrice
        onLoad={onLoadPage}
        buttonDisabled={buttonDisabled}
        onClickSubmit={onClickSubmit}
        contentWidth={contentWidth}
        color1={color1}
        estimatePrice={originPrice.toLocaleString('en-US')}
      />
    </>
  )
}
