import Modal from 'react-modal'
import '../css/ClipBoard.scss'

export function AdminModalLayout({
  modalIsOpen,
  setModalIsOpen,
  onClickFinish,
}: any) {
  const customStyles = {
    content: {
      top: window.innerHeight / 2 - 100,
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      textAlign: 'center' as 'center',
      width: 260,
      height: 167.94,
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
    },
  }

  const modalTitle = {
    display: 'inline-block',
    marginTop: 30,
    width: '100%',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    marginBottom: 10,
    fontSize: '18px',
    lineHeight: '22px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const modalSubTitle = {
    display: 'inline-block',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 10,
    fontSize: '15px',
    lineHeight: '18px',
    paddingLeft: 10,
    paddingRight: 10,
    /* or 125% */

    letterSpacing: '-0.02em',

    color: '#727272',
  }
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <div style={modalTitle}>페이지를 나가시겠습니까?</div>
      <div style={modalSubTitle}>설정 내용은 저장되지 않습니다</div>

      <div
        style={{
          display: 'flex',
          marginTop: 'auto',
          position: 'absolute',
          bottom: 0,
          justifyContent: 'space-between',
          borderTop: '1px solid  #EAEAEA',
          width: '100%',
        }}
      >
        <button
          style={{
            width: customStyles.content.width / 2,
            height: 52,
            borderRight: '1px solid  #EAEAEA',
          }}
          onClick={() => {
            setModalIsOpen(false)
          }}
        >
          취소
        </button>
        <button
          style={{
            width: customStyles.content.width / 2,
            height: 52,
          }}
          onClick={onClickFinish}
        >
          나가기
        </button>
      </div>
    </Modal>
  )
}

export function ModalLayout({
  title,
  subTitle,
  modalIsOpen,
  setModalIsOpen,
  onClickFinish,
}: any) {
  const customStyles = {
    content: {
      position: 'fixed',
      top: window.innerHeight / 2 - 100,
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      textAlign: 'center' as 'center',
      width: 260,
      height: 167.94,
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
    },
  }

  const modalTitle = {
    display: 'inline-block',
    marginTop: 30,
    width: '100%',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    marginBottom: 10,
    fontSize: '18px',
    lineHeight: '22px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const modalSubTitle = {
    display: 'inline-block',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 10,
    fontSize: '15px',
    lineHeight: '18px',
    paddingLeft: 10,
    paddingRight: 10,
    /* or 125% */

    letterSpacing: '-0.02em',

    color: '#727272',
  }
  const DivideTitle = (_title: string, context: string) => {
    if (_title.includes('_')) {
      const titleList = _title.split('_')
      if (context === 'title') {
        return (
          <div>
            {titleList[0]}
            <br />
            {titleList[1]}
          </div>
        )
      } else {
        return (
          <div>
            {titleList[0]}
            <br />
            {titleList[1]}
          </div>
        )
      }
    } else {
      if (context === 'title') {
        return <div>{_title}</div>
      } else {
        return <div>{_title}</div>
      }
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      overlayClassName="modal-over-lay"
    >
      <div style={modalTitle}>{title}</div>
      <div style={modalSubTitle}>{DivideTitle(subTitle, 'subTitle')}</div>

      <div
        style={{
          display: 'flex',
          marginTop: 'auto',
          position: 'absolute',
          bottom: 0,
          justifyContent: 'space-between',
          borderTop: '1px solid  #EAEAEA',
          width: '100%',
        }}
      >
        <button
          style={{
            width: customStyles.content.width,
            height: 52,
          }}
          onClick={onClickFinish}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
