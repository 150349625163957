import { useEffect, useState } from 'react'
import '../../../css/Common.scss'
import { CalculatePrice } from '../../utility/utils'
import ProgressBar from '../PageElement/ProgressBar'
import { NextButtonWithPrice, NextButtonWithoutPrice } from '../PageElement/NextButton'
import { VerticalChoiceButton, Title } from '../Format'
import { Option } from '../type'
import { useNavigate, useParams } from 'react-router-dom'

export default function VerticalChoiceLayout({
  progressOrder,
  title,
  subTitle,
  argument,
  options,
}: any) {
  let storage = sessionStorage
  let originPrice = CalculatePrice(argument)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const color1 = storage.getItem('color1') as string
  const checkMode = storage.getItem('checkMode')

  const [estimatePrice, setEstimatePrice] = useState(originPrice)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    if (options.length !== 0) {
      let tmp: Option = JSON.parse(storage.getItem(argument) as string)
      if (tmp) {
        setSelectedOption(tmp)
        setEstimatePrice(estimatePrice + tmp.value)
      } else {
        setSelectedOption(options[0])
        setEstimatePrice(estimatePrice + options[0].value)
      }
    }
    setButtonDisabled(false)
  }, [options])

  const onClickButton = (option: any) => {
    if (selectedOption && option.name === selectedOption.name) {
      let x = { ...option }
      x.name = ''
      setSelectedOption(x)
      setButtonDisabled(true)
    } else {
      setSelectedOption(option)
      setButtonDisabled(false)
      setEstimatePrice(originPrice + option.value)
    }
  }
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const [selectedOption, setSelectedOption] = useState<Option>()

  const onClickSubmit = () => {
    if (selectedOption) {
      storage.setItem(argument, JSON.stringify(selectedOption))
      if (checkMode) {
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        nav(`/order/${shopName}/${orderPk}/${selectedOption.next_point}`)
      }
    }
  }
  return (
    <>
      <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={progressOrder} />

      <div style={{ width: contentWidth }}>
        <Title contentWidth={contentWidth} title={title} subTitle={subTitle} />
      </div>

      <div style={{ marginLeft: 25, marginTop: 35, marginBottom: 100 }}>
        <div style={{ width: contentWidth }}>
          {options.length !== 0
            ? options.map((option: any) => (
              <VerticalChoiceButton
                key={`${option.name}${option.value}`}
                contentWidth={contentWidth}
                option={option}
                selectedOption={selectedOption}
                onClickButton={onClickButton}
                color1={color1}
              />
            ))
            : null}
        </div>
      </div>
      {checkMode ?
        <NextButtonWithoutPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
        /> :
        <NextButtonWithPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
          estimatePrice={estimatePrice}
        />
      }
    </>
  )
}
