import dayjs, { Dayjs } from 'dayjs'
import { CaretLeft, CaretRight } from 'phosphor-react'
import { useMemo, useState } from 'react'
import { getWeekDays } from '../../../utils/get-week-days'
import { getEventOfDate } from '../../../utils/dates'
import {
    CalendarActions,
    CalendarBody,
    CalendarContainer,
    CalendarHeader,
    CalendarTitle,
} from './calanderUserStyles'
import CalendarUserDay from './CalendarUserDay'
import { useEffect } from 'react'

interface CalendarWeek {
    week: number
    days: Array<{
        date: dayjs.Dayjs
        disabled: boolean
        blocked: boolean
    }>
}

type CalendarWeeks = CalendarWeek[]

interface BlockedDates {
    blockedWeekDays: number[]
    blockedDates: number[]
}

interface CalendarProps {
    selectedDate: Date | null
    onDateSelected: (date: Date) => void
}
const dateTitleStyle = {
    fontFamily: 'SF Pro Display',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '25px',
    lineHeight: '30px',
    color: '#000000',
}
export default function CalendarUser({
    selectColor,
    selected,
    onClickTargetDay,
    contentWidth,
    blockedDay,
    blockedDates,
    startDate,
    endDate,
    startDateBuffer,
    endDateBuffer
}: any) {
    const [blockedDatesInPrevMonth, setBlockedDatesInPrevMonth] = useState<
        number[]
    >([])
    const [blockedDatesInMonth, setBlockedDatesInMonth] = useState<number[]>([])
    const [blockedDatesInNextMonth, setBlockedDatesInNextMonth] = useState<
        number[]
    >([])

    // currentDate의 초깃값은 그 달의 첫날이다.
    const [currentDate, setCurrentDate] = useState<Dayjs>(() => {
        const information = JSON.parse(
            sessionStorage.getItem('information') as string,
        )
        if ('defaultMonth' in information) {
            return dayjs()
                .set('month', Number(information.defaultMonth) - 1)
                .set('date', 1)
        }
        return dayjs().set('date', 1)
    })

    useEffect(() => {
        if (selected) {
            setCurrentDate(dayjs().set('year', selected.get('year')).set('month', selected.get('month')).set('date', 1))
        }
    }, [selected])

    useEffect(() => {
        if (blockedDates) {
            let datesFiltered = []
            let datesPrevMonthFiltered = []
            let datesNextMonthFiltered = []
            for (let index = 0; index < blockedDates.length; index++) {
                const date = blockedDates[index]
                if (Number(currentMonth) - 1 === date.get('month')) {
                    datesFiltered.push(date.get('date'))
                }
                if (Number(currentMonth) === date.get('month')) {
                    datesNextMonthFiltered.push(date.get('date'))
                }
                if (Number(currentMonth) - 2 === date.get('month')) {
                    datesPrevMonthFiltered.push(date.get('date'))
                }
            }
            setBlockedDatesInMonth(datesFiltered)
            setBlockedDatesInNextMonth(datesNextMonthFiltered)
            setBlockedDatesInPrevMonth(datesPrevMonthFiltered)
        }
    }, [blockedDates, currentDate])
    function handlePreviousMonth() {
        // 이전달로 넘어갈 때에 currentDate의 달을 1달 줄인다.
        const previousMonthDate = currentDate.subtract(1, 'month')
        setCurrentDate(previousMonthDate)
    }

    function handleNextMonth() {
        // 다음 달로 넘어갈 때에 currentDate의 달을 1달 높인다.
        const nextMonthDate = currentDate.add(1, 'month')
        setCurrentDate(nextMonthDate)
    }

    // MON ~ SUN
    const shortWeekDays = getWeekDays({ short: true })

    const currentMonth = currentDate.format('MM')
    const currentYear = currentDate.format('YYYY')

    // const username = String(router.query.username)
    //임시 추후에 API 연결할 것
    const blockedDatesDict = {
        blockedWeekDays: blockedDay ? blockedDay : [],
        blockedDates: blockedDatesInMonth,
    }
    // const { data: blockedDates } = useQuery<BlockedDates>(
    //     ['blocked-dates', currentDate.get('year'), currentDate.get('month')],
    //     async () => {
    //         const response = await api.get(`/users/${username}/blocked-dates`, {
    //             params: {
    //                 year: currentDate.get('year'),
    //                 month: currentDate.get('month') + 1,
    //             },
    //         })

    //         return response.data
    //     },
    // )

    // calendarWeeks는 blockDates가 없으면 빈 리스트
    const calendarWeeks = useMemo(() => {
        if (!blockedDates) {
            return []
        }
        // currentDate 가 해당 달의 1일로 되어있기 때문에
        // 해당 달의 수만큼 날짜 array를 만든다.
        const daysInMonthArray = Array.from({
            length: currentDate.daysInMonth(),
        }).map((_, i) => {
            return currentDate.set('date', i + 1)
        })
        const todayDate = dayjs()
        let dateOpening = todayDate.add(3, 'day')
        let dateClosing = todayDate.add(24, 'day')
        // 시작일과 종료일을 지정한 경우 변경
        if ((startDate !== null && endDate !== null)) {
            dateOpening = dayjs(startDate)
            dateClosing = dayjs(endDate).set('date', dayjs(endDate).date() + 1)
        }
        if (startDateBuffer !== null && endDateBuffer !== null) {
            dateOpening = todayDate.add(startDateBuffer, 'day')
            dateClosing = todayDate.add(endDateBuffer, 'day')
        }
        // 그달의 1일 요일
        let firstWeekDay = currentDate.get('day')

        // 일요일인 경우 월~토까지 만들어져야 한다.
        if (firstWeekDay == 0) {
            firstWeekDay = 7
        }
        // 3월 1일이 목요일이라면
        // firstWeekDay = 3 (0부터 월요일)
        // 앞에 보여져야 하는 날짜들은 2월 26일 27일 28 일
        const previousMonthFillArray = Array.from({
            length: firstWeekDay,
        })
            .map((_, i) => {
                return currentDate.subtract(i + 1, 'day')
            })
            .reverse()
        // 3월 31일
        const lastDayInCurrentMonth = currentDate.set(
            'date',
            currentDate.daysInMonth(),
        )
        //토요일 -> 5
        const lastWeekDay = lastDayInCurrentMonth.get('day')

        // length:1 [4월 1일]
        const nextMonthFillArray = Array.from({
            length: 7 - lastWeekDay,
        }).map((_, i) => {
            return lastDayInCurrentMonth.add(i + 1, 'day')
        })

        // 달력에 표시되어야 하는 날짜들
        // 이전 달에 있지만, 표시되어야 하는 날짜들'
        // 이번 달 날짜들
        // 다음 달 날짜들
        // disabled는 선택가능한지 아닌지를 뜻한다.
        const calendarDays = [
            ...previousMonthFillArray.map(date => {
                let isDisabledPrevMonth = blockedDatesInPrevMonth.includes(
                    date.get('date'),
                )
                return {
                    date,
                    disabled:
                        isDisabledPrevMonth ||
                        date.endOf('day').isBefore(dateOpening) ||
                        date.endOf('day').isAfter(dateClosing),
                    blocked: blockedDatesDict.blockedWeekDays.includes(date.get('day')),
                }
            }),
            ...daysInMonthArray.map(date => {
                // const isDisabled = isDateDisabled(date)
                const isDisabled = blockedDatesInMonth.includes(date.get('date'))

                return {
                    date,
                    disabled:
                        isDisabled ||
                        // 오늘보다 과거이거나, block 요일 / 날짜에 속해있으면 disabled
                        date.endOf('day').isBefore(dateOpening) ||
                        date.endOf('day').isAfter(dateClosing),
                    blocked: blockedDatesDict.blockedWeekDays.includes(date.get('day')),
                }
            }),
            ...nextMonthFillArray.map(date => {
                let isDisabledNextMonth = blockedDatesInNextMonth.includes(
                    date.get('date'),
                )
                return {
                    date,
                    disabled:
                        isDisabledNextMonth ||
                        date.endOf('day').isBefore(dateOpening) ||
                        date.endOf('day').isAfter(dateClosing),
                    blocked: blockedDatesDict.blockedWeekDays.includes(date.get('day')),
                }
            }),
        ]
        // 날짜들을 주별로 나눠서 {week : number, days: Dayjs객체 } 객체로 집어넣음
        const calendarWeeks = calendarDays.reduce<CalendarWeeks>(
            (weeks, _, i, original) => {
                // 월요일 ~ 일요일이기 때문 (아니면 ===0)
                const isNewWeek = i % 7 === 1

                if (isNewWeek) {
                    weeks.push({
                        week: i / 7 + 1,
                        days: original.slice(i, i + 7),
                    })
                }
                return weeks
            },
            [],
        )
        return calendarWeeks
    }, [blockedDatesInMonth])

    return (
        <CalendarContainer style={{ width: contentWidth }}>
            <CalendarHeader>
                <CalendarActions>
                    <button onClick={handlePreviousMonth} title="prev">
                        <CaretLeft />
                    </button>
                    <div style={dateTitleStyle}>
                        {currentYear}.{currentMonth}
                    </div>
                    <button onClick={handleNextMonth} title="next">
                        <CaretRight />
                    </button>
                </CalendarActions>
            </CalendarHeader>

            <CalendarBody
                style={{
                    left: 25,
                    width: contentWidth - 50,
                }}
            >
                <thead>
                    <tr>
                        {shortWeekDays.map((weekDay: any) => (
                            <th key={weekDay}>{weekDay}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {calendarWeeks.map(({ week, days }) => {
                        return (
                            <tr key={week}>
                                {days.map(({ date, disabled, blocked }) => {
                                    return (
                                        <td key={date.toString()}>
                                            <CalendarUserDay
                                                onClickTargetDay={onClickTargetDay}
                                                date={date}
                                                disabled={disabled}
                                                selectColor={selectColor}
                                                selected={selected}
                                                blocked={blocked}
                                            ></CalendarUserDay>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </CalendarBody>
        </CalendarContainer>
    )
}
