import { useEffect, memo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import '../../../css/SelectDesignInstagram.scss'
import { CalculateDesignMinPrice } from "../../utility/utils"
const DesignCardNarrow = ({
    checkIsSelected,
    option,
    onClickButton,
}: any) => {
    const nav = useNavigate()
    const { shopName, orderPk } = useParams();
    const onClickImage = (designOption: any) => {
        sessionStorage.setItem('lastPosition', window.scrollY.toString())
        nav(`/order/${shopName}/${orderPk}/designDetail/${designOption.id}`)
    }
    const ChoiceButton = () => {
        return (<button
            onClick={() => { onClickButton(option) }}
            className={'choice-button'}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                <path d="M0.893066 3.55209L4.12894 6.83664L9.71818 1.16333" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            선택
        </button>)
    }
    const ChosenButton = () => {
        return (<button
            onClick={() => { onClickButton(option) }}
            className={'choice-button'}
            style={{
                border: `none`,
                background: sessionStorage.getItem('color1') as string,
                color: '#fff'
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                <path d="M0.893066 3.55209L4.12894 6.83664L9.71818 1.16333" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            선택
        </button>)
    }

    let image = "https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/aa080608-d89d-4d7f-39cf-1c41bfc22c00/public"
    if (option && option.example_photos.length !== 0) {
        image = (option.example_photos[0].src)
    }
    return (
        <div className='design-card-narrow'>
            <img
                className='card-image-shrink'
                onClick={() => onClickImage(option)}
                src={image}
            />
            <div
                className="flex-box">
                <div
                    style={{ textAlign: 'left' }}
                >
                    <div className="title">
                        {option.name}
                    </div>
                    <div className="min-price">
                        <span>{CalculateDesignMinPrice(option).toLocaleString('us-en')}원</span>부터~
                    </div>

                </div>
                {checkIsSelected(option) ? <ChosenButton /> : <ChoiceButton />}
            </div>
        </div>
    )
}


export default memo(DesignCardNarrow)