import { memo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CheckSoldOut } from "../../utility/utils"
const DesignCard = ({ checkIsSelected, option }: any) => {
    const nav = useNavigate()
    const { shopName, orderPk } = useParams();
    const isSoldOut = CheckSoldOut(option)
    const onClickCard = () => {
        if (isSoldOut) {
            return
        }
        sessionStorage.setItem('lastPosition', window.scrollY.toString())
        nav(`/order/${shopName}/${orderPk}/designDetail/${option.id}`)
    }
    const ChoiceButton = () => {
        return (<button
            style={{ opacity: isSoldOut ? 0.4 : 1 }}
            onClick={onClickCard}
            className={'choice-button'}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                <path d="M0.893066 3.55209L4.12894 6.83664L9.71818 1.16333" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            선택
        </button>)
    }
    const ChosenButton = () => {
        return (<button
            onClick={onClickCard}
            disabled={isSoldOut}
            className={'choice-button'}
            style={{
                border: `none`,
                background: sessionStorage.getItem('color1') as string,
                color: '#fff'
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
                <path d="M0.893066 3.55209L4.12894 6.83664L9.71818 1.16333" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            선택
        </button>)
    }

    let image = "https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/aa080608-d89d-4d7f-39cf-1c41bfc22c00/public"
    if (option && option.example_photos.length !== 0) {
        image = (option.example_photos[0].src)
    }

    return (
        <div className='design-card'>
            <img
                style={{ opacity: isSoldOut ? 0.4 : 1 }}
                className='card-image'
                onClick={onClickCard}
                src={image}
            />
            <div >
                <span
                    style={{ opacity: isSoldOut ? 0.2 : 1 }}
                >
                    {option.name}
                </span>
                {checkIsSelected(option) ? <ChosenButton /> : <ChoiceButton />}
            </div>
        </div>
    )
}


export default memo(DesignCard)