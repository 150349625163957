import axios from 'axios'
import Cookie from 'js-cookie'
import { QueryFunctionContext } from '@tanstack/react-query'

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://127.0.0.1:8000/api/v1/'
      : process.env.REACT_APP_BACKEND_TARGET_DOMAIN,
  withCredentials: true,
})

export async function getShop({ queryKey }: QueryFunctionContext) {
  const [_, shopUuid] = queryKey
  const response = await axiosInstance.get(`shops/${shopUuid}`)
  return response.data
}

export async function getShopByName({ queryKey }: QueryFunctionContext) {
  const [_, shopName] = queryKey
  const response = await axiosInstance.get(`shops/name/${shopName}`)
  return response.data
}

export async function getOrderSheet({ queryKey }: QueryFunctionContext) {
  const [_, shopName, orderPk] = queryKey
  const response = await axiosInstance.get(
    `ordersheets?shopName=${shopName}&orderPk=${orderPk}`,
  )
  return response.data
}

export async function getOrderSheetsByShop({ queryKey }: QueryFunctionContext) {
  const [shopUuid] = queryKey
  const response = await axiosInstance.get(`/ordersheets/${shopUuid}}`)
  return response.data
}

export const uploadOrderRequest = ({
  customerName,
  customerPhoneNo,
  clipBoardMessage,
  shopName,
  orderPk,
  pickupDate,
  pickupTime,
  design,
  size,
  base,
  lettering,
  packageOption,
  customMessage,
  imageLink,
  quickRequiredMessage,
  estimatePrice,
  numberContent,
}: any) =>
  axiosInstance
    .post(
      `/order_requests/`,
      {
        customerName,
        customerPhoneNo,
        clipBoardMessage,
        shopName,
        orderPk,
        pickupDate,
        pickupTime,
        design,
        size,
        base,
        lettering,
        packageOption,
        customMessage,
        imageLink,
        quickRequiredMessage,
        estimatePrice,
        numberContent,
      },
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
        },
      },
    )
    .then(response => response.data)

export const uploadFile = (form_data: any) =>
  axiosInstance
    .post(`/files/`, form_data, {
      headers: {
        'X-CSRFToken': Cookie.get('csrftoken') || '',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data)

export const changeOrderSheetTimeRange = ({
  pk,
  shopUuid,
  start_time,
  end_time,
}: any) =>
  axiosInstance
    .patch(
      `/ordersheets/${shopUuid}/timerange`,
      {
        pk,
        start_time,
        end_time,
      },
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
        },
      },
    )
    .then(response => response.data)

export const changeOrderSheetBlockedDay = ({
  pk,
  shopUuid,
  blocked_day,
}: any) =>
  axiosInstance
    .patch(
      `/ordersheets/${shopUuid}/blockedDay`,
      {
        pk,
        blocked_day,
      },
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
        },
      },
    )
    .then(response => response.data)

export const changeOrderSheet = ({
  pk,
  shopUuid,
  is_date_changed,
  is_date_blocked,
  is_time_changed,
  current_date,
  blocked_time,
}: any) =>
  axiosInstance
    .post(
      `/ordersheets/${shopUuid}`,
      {
        pk,
        is_date_changed,
        is_date_blocked,
        is_time_changed,
        current_date,
        blocked_time,
      },
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
        },
      },
    )
    .then(response => response.data)

export interface IUsernameLoginVariables {
  username: string
  password: string
}

export interface IUsernameLoginSuccess {
  ok: string
}

export interface IUsernameLoginError {
  error: string
}

export const userLogin = ({ username, password }: IUsernameLoginVariables) =>
  axiosInstance
    .post(
      `/users/login/`,
      { username, password },
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
        },
      },
    )
    .then(response => response.data)

export const getMe = () =>
  axiosInstance.get(`users/me/`).then(response => response.data)

// FILE UPLOAD
export const getUploadURL = ({ file }: any) =>
  axiosInstance
    .post(`files/get-url`, null, {
      headers: {
        'X-CSRFToken': Cookie.get('csrftoken') || '',
      },
    })
    .then(response => ({ file: file, data: response.data }))

export interface IUploadImageVariables {
  file: Blob
  uploadURL: string
}

export const uploadImage = ({ file, uploadURL }: IUploadImageVariables) => {
  const form = new FormData()
  form.append('file', file)
  return axios
    .post(uploadURL, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => response.data)
}

export const customUploadImage = ({ file, uploadURL, fileName }: any) => {
  const form = new FormData();
  form.append('file', file);
  return axios.post(uploadURL, form, { headers: { "Content-Type": "multipart/form-data", } }).then(response => {
      
      console.log(`${fileName}, https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/${response.data.result.id}/public`)
      return response.data})

  }
export const sendSlackMessage = (message: any) =>
  axiosInstance
    .post(
      `/slack_webhook/`,
      { text: `${JSON.stringify(message)}` },
      {
        withCredentials: true,
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
          'Content-Type': 'application/json',
        },
      },
    )
    .then(response => response.data)

export const patchOptions = ({ optionIds, soldOutStatus }: any) =>
  axiosInstance
    .patch(
      `/options/`,
      {
        optionIds,
        soldOutStatus,
      },
      {
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken') || '',
        },
      },
    )
    .then(response => response.data)
