import {
  NextButtonProductCount,
  NextButtonWithPrice,
  NextButtonProductCountWithPrice,
  NextButtonWithPriceProduct,
} from '../../components/OrderSequence/PageElement/NextButton'
import { useState, useEffect } from 'react'
import {
  CalculatorFromJson,
  ProductCalculator,
} from '../../components/utility/calculator'
import { Option } from '../../components/OrderSequence/type'
import { FilterSortOption } from '../../components/utility/utils'
import { useNavigate, useParams } from 'react-router-dom'
import ProductPhotoSlider from '../../components/OrderSequence/PageElement/ProductPhotoSlider'

export default function SelectProductPackage() {
  let storage = sessionStorage
  const productGroupSelected = JSON.parse(
    storage.getItem('productGroup') as string,
  )
  const contentWidth = Number(storage.getItem('contentWidth'))
  const color1 = storage.getItem('color1') as string
  const productCalculatorJSON = JSON.parse(
    storage.getItem('calculator') as string,
  )
  const [groupName, setGroupName] = useState('')
  const [groupDesc, setGroupDesc] = useState('')
  useEffect(() => {
    if (productGroupSelected) {
      setGroupName(productGroupSelected[0].option_group)
      setGroupDesc(productGroupSelected[0].desc)
    }
  }, [productGroupSelected])
  const [productCalculator, setProductCalculator] =
    useState<ProductCalculator>()
  const [calculatorLoaded, setCalculatorLoaded] = useState(false)

  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const checkMode = storage.getItem('checkMode')

  let packageOptions = JSON.parse(storage.getItem('packageOptions') as string)
  const [isSetFinished, SetIsSetFinished] = useState(false)
  const [packageSelected, setPackageSelected] = useState(packageOptions[0])
  const [count, setcount] = useState(0)
  const [draftPrice, setDraftPrice] = useState(0)
  const [price, setPrice] = useState(0)
  useEffect(() => {
    if (packageOptions && !isSetFinished) {
      setPackageSelected(packageOptions[0])
      SetIsSetFinished(true)
    }
  }, [packageOptions])
  useEffect(() => {
    if (productCalculatorJSON && !calculatorLoaded) {
      let calculator = CalculatorFromJson(productCalculatorJSON)
      setProductCalculator(calculator)
      setCalculatorLoaded(true)
    }
  }, [productCalculatorJSON])
  useEffect(() => {
    if (productCalculator && groupName != '' && packageSelected) {
      setcount(productCalculator.getGroupCount(groupName))
      setDraftPrice(productCalculator.getGroupPrice(groupName))
      setPrice(
        productCalculator.getGroupPrice(groupName) + packageSelected.value,
      )
    }
  }, [productCalculator, groupName, packageSelected])
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const isSelected = (option: Option) => {
    return option.name === packageSelected.name
  }
  const onClickPackage = (option: Option) => {
    setPrice(draftPrice + option.value)
    setPackageSelected(option)
  }

  const onClickSubmit = () => {
    productCalculator?.setPackage(groupName, packageSelected)
    storage.setItem('calculator', JSON.stringify(productCalculator?.toJson()))

    if (checkMode) {
      nav(`/order/${shopName}/${orderPk}/Check`)
    } else {
      nav(`/order/${shopName}/${orderPk}/Product`)
    }
  }
  const message = `${count}개 담기 ${price.toLocaleString('en-us')}원`
  return (
    <div
      style={{
        marginLeft: 20,
        width: contentWidth,
      }}
    >
      {/* 상단 이미지 */}
      <ProductPhotoSlider
        contentWidth={contentWidth}
        example_photos={packageOptions[0]?.example_photos}
      />
      <div className="count-mode-title">보자기 포장</div>
      <div className="count-mode-desc">
        고급스러운 보자기 포장을 원하시면 선택해주세요. 리본, 보자기 색상은 임의
        변동되어 나갑니다.
      </div>
      <div style={{ width: '100%', height: 8, background: '#F7F7FA' }}></div>
      <div className="package-selections" style={{ display: 'flex' }}>
        {packageOptions?.map((option: Option) => (
          <div
            onClick={() => {
              onClickPackage(option)
            }}
            style={
              isSelected(option)
                ? {
                  border: `1px solid ${color1}`,
                }
                : {}
            }
            className={
              isSelected(option) ? 'selected-option' : 'deselected-option'
            }
          >
            {option.value ? (
              <>
                <div>{option.name}</div>
                <div>{option.value.toLocaleString('en-us')}</div>
              </>
            ) : (
              <>
                <div
                  style={{
                    lineHeight: '55px',
                    height: 55,
                  }}
                >
                  {option.name}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <NextButtonProductCountWithPrice
        contentWidth={contentWidth}
        buttonDisabled={buttonDisabled}
        onClickSubmit={onClickSubmit}
        message={message}
        color1={color1}
        checkMode={false}
      />
    </div>
  )
}
