import { useNavigate } from 'react-router-dom'
import useUser from '../lib/useUser'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
interface IprotectedPageProps {
  children: React.ReactNode
  shopQuery: any
  shopUuid: any
}
export default function ProtectedPage({
  children,
  shopQuery,
}: IprotectedPageProps) {
  const { user, isLoggedIn, userLoading } = useUser()
  const navigate = useNavigate()
  const toast = useToast()
  useEffect(() => {
    if (!userLoading && !shopQuery.isLoading) {
      let isAuthenticated = false
      if (shopQuery.data.uuid === user.shop.uuid) {
        isAuthenticated = true
      }
      if (!isAuthenticated) {
        toast({
          title: 'Project Access Denied.',
          description: 'You are not authenticated to this project',
          status: 'error',
          isClosable: true,
        })
        navigate(`/
                `)
      }
    }
  }, [shopQuery, userLoading])
  return <>{children}</>
}
