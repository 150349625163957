import { Title } from "../../components/OrderSequence/Format";
import ProgressBar from "../../components/OrderSequence/PageElement/ProgressBar";
import { useState, useEffect, useRef } from "react";
import { CalculatePrice, FilterSortOption } from "../../components/utility/utils";
import "../../css/Format.scss"
import { NextButtonWithPrice } from "../../components/OrderSequence/PageElement/NextButton";
import { useNavigate, useParams } from "react-router-dom";
import { Option } from '../../components/OrderSequence/type'


export default function SelectCandle() {
    const storage = sessionStorage;
    const contentWidth = Number(storage.getItem("contentWidth"));
    const progressOrder = 4;
    const checkMode = storage.getItem('checkMode')
    const color1 = storage.getItem('color1') as string
    const argument = "candle"
    let originPrice = CalculatePrice(argument)
    const [estimatePrice, setEstimatePrice] = useState(originPrice)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    let optionData = JSON.parse(storage.getItem('options') as string)
    const [isSetFinished, SetIsSetFinished] = useState(false)
    const [options, setOptions] = useState<Option[]>([])
    const [candle, setCandle] = useState('')

    const [instructionMessage, setInstructionMessage] = useState("")
    const getInstructionMessage = (numberOptions: any) => {
        let numberOptionWithoutZero = [...numberOptions]
        numberOptionWithoutZero.splice(0, 1)
        if (numberOptionWithoutZero.length === 1) {
            return `갯수 상관 없이 추가금 ${numberOptionWithoutZero[0].value / 1000}천원`
        }
        let message = numberOptionWithoutZero.reduce(
            (acc: any, cur: any) =>
                acc + `${cur.name} ${cur.value / 1000}천원, `, '');
        message = message.replace(/,\s*$/, '');

        return message
    }

    useEffect(() => {
        if (optionData && !isSetFinished) {
            const tempSort = FilterSortOption(optionData, 'candle')
            setOptions(tempSort)
            setInstructionMessage(getInstructionMessage(tempSort))
            let candleOption = JSON.parse(storage.getItem('candle') as string)
            let candleContent = storage.getItem('candleContent') as string
            setInstructionMessage(getInstructionMessage(tempSort))
            if (candleOption) {
                setCandle(candleContent)
                setCandleOption(candleOption)

            }
            else {
                setCandleOption(tempSort[0])
            }
            setButtonDisabled(false)
            SetIsSetFinished(true)
        }
    }, [optionData])

    const [candleOption, setCandleOption] = useState<Option>();
    useEffect(() => {
        if (candleOption) {
            setEstimatePrice(originPrice + candleOption.value)
        }
    }, [candleOption])


    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const HandleTextAreaChange = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'
            textareaRef.current.style.height = textareaRef.current?.scrollHeight + 'px'
        }
    }
    const onChangeText = (e: any) => {
        const countWithoutJump = e.target.value.replace(/\s/g, '').length
        if (countWithoutJump <= 10) {
            setCandle(e.target.value)
            HandleTextAreaChange()
        }
        if (countWithoutJump == 0) {
            setCandleOption(options[0])
        } else {
            setCandleOption(options[1])

        }
    }

    const nav = useNavigate();
    const { shopName, orderPk } = useParams();
    const onClickSubmit = () => {
        if (candleOption) {
            storage.setItem('candleContent', candle)
            storage.setItem('candle', JSON.stringify(candleOption))
            if (checkMode) {
                nav(`/order/${shopName}/${orderPk}/Check`)
            } else {
                nav(`/order/${shopName}/${orderPk}/${candleOption.next_point}`)
            }
        }
    }
    let placeHolder = "ex) 8"
    let information = JSON.parse(sessionStorage.getItem('information') as string)
    if (information && 'letteringPlaceHolder' in information) {

        placeHolder = information.letteringPlaceHolder
    }
    return (
        <div
            style={{
                width: contentWidth,
                textAlign: 'center',
            }}
        >
            <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={progressOrder} />

            <Title
                title={'초 갯수 입력'}
                subTitle={'필요하지 않은 경우 다음으로 넘어가세요'} />
            <input
                type="number"
                className="lettering-input-wide"
                placeholder={placeHolder}
                value={candle}
                onChange={onChangeText}
            />
            <div style={{
                marginTop: 5,
                display: 'flex',
                width: contentWidth - 50,
                marginLeft: 25,
                alignItems: "start"
            }}>
                <span
                    className="instruction-message">{instructionMessage}</span>


            </div>


            <NextButtonWithPrice
                buttonDisabled={buttonDisabled}
                onClickSubmit={onClickSubmit}
                contentWidth={contentWidth}
                color1={color1}
                checkMode={checkMode}
                estimatePrice={estimatePrice}
            />
        </div >)

}