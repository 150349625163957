interface OptionTitleProps {
    title: string;
    mandatory: Boolean;
}
export const OptionTitle = ({ title, mandatory }: OptionTitleProps) => {
    return (
        <div className="category-style">
            <div>
                <p>{title}</p>
                {mandatory ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                        <path d="M3.19905 7.47559C3.61763 7.47559 3.75591 7.36347 3.75591 6.9561V4.9529L5.47882 5.94702C5.82265 6.15257 5.97214 6.10399 6.20012 5.75268L6.23002 5.71157C6.46173 5.35653 6.38325 5.1734 6.03942 4.97906L4.25298 3.94756L6.03568 2.97212C6.38325 2.78151 6.43183 2.60586 6.23002 2.24334L6.20012 2.19849C5.98709 1.83223 5.81518 1.80607 5.47508 1.99668L3.75591 2.99454V0.995073C3.75591 0.587706 3.61763 0.475586 3.19905 0.475586H3.15421C2.73563 0.475586 2.59734 0.587706 2.59734 0.995073V2.99454L0.878177 1.99668C0.541819 1.7986 0.325054 1.80607 0.141925 2.19849L0.123239 2.2396C-0.0561526 2.62081 -0.0262541 2.77404 0.321317 2.97585L2.10028 3.94756L0.313842 4.97906C-0.0299914 5.17713 -0.0897885 5.34531 0.126976 5.71531L0.145663 5.74894C0.354953 6.1152 0.530607 6.15257 0.87444 5.94702L2.59734 4.9529V6.9561C2.59734 7.36347 2.73563 7.47559 3.15421 7.47559H3.19905Z" fill="#DA0202" />
                    </svg>
                    : null}
            </div>
        </div>
    )
}