import React, { useEffect } from 'react'

interface BlockedDaySelectorProps {
  selectedBlockedDays: number[]
  setSelectedBlockedDays: React.Dispatch<React.SetStateAction<number[]>>
}

const BlockedDaySelector: React.FC<BlockedDaySelectorProps> = ({
  selectedBlockedDays,
  setSelectedBlockedDays,
}) => {
  let storage = sessionStorage
  const currentBlockedDaysString = storage.getItem('blockedDay')

  const currentBlockedDays: number[] = currentBlockedDaysString
    ? JSON.parse(currentBlockedDaysString)
    : []

  useEffect(() => {
    setSelectedBlockedDays(currentBlockedDays)
  }, [])

  const toggleButton = (index: number) => {
    setSelectedBlockedDays(prevState =>
      prevState.includes(index)
        ? prevState.filter(i => i !== index)
        : [...prevState, index],
    )
  }

  const isButtonSelected = (index: number) => {
    return selectedBlockedDays.includes(index)
  }

  const setButtonStyle = (index: number): string => {
    const baseClassName = 'base-day-button'
    const selectedClassName = 'selected-day-button'

    return isButtonSelected(index) ? selectedClassName : baseClassName
  }

  return (
    <div>
      <div className="sub-title" style={{ marginTop: 40 }}>
        {' '}
        정기휴일 설정
      </div>
      <div className="day-button-container">
        <button className={setButtonStyle(1)} onClick={() => toggleButton(1)}>
          월
        </button>
        <button className={setButtonStyle(2)} onClick={() => toggleButton(2)}>
          화
        </button>
        <button className={setButtonStyle(3)} onClick={() => toggleButton(3)}>
          수
        </button>
        <button className={setButtonStyle(4)} onClick={() => toggleButton(4)}>
          목
        </button>
        <button className={setButtonStyle(5)} onClick={() => toggleButton(5)}>
          금
        </button>
        <button className={setButtonStyle(6)} onClick={() => toggleButton(6)}>
          토
        </button>
        <button className={setButtonStyle(0)} onClick={() => toggleButton(0)}>
          일
        </button>
      </div>
    </div>
  )
}

export default BlockedDaySelector
