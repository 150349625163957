import { useState, useEffect } from 'react'
import {
  FilterSortOption,
  CalculatePrice,
} from '../../components/utility/utils'
import VerticalChoiceLayout from '../../components/OrderSequence/PageLayout/VerticalChoice'

export default function SelectBase() {
  let storage = sessionStorage
  let optionData = JSON.parse(storage.getItem('options') as string)

  const [isSetFinished, SetIsSetFinished] = useState(false)
  const [baseOptions, setBaseOptions] = useState<any[]>([])

  useEffect(() => {
    if (optionData && !isSetFinished) {
      const tempSort = FilterSortOption(optionData, 'base')
      setBaseOptions(tempSort)
      SetIsSetFinished(true)
    }
  }, [optionData])

  return (
    <VerticalChoiceLayout
      progressOrder={6}
      title={'설기 맛 선택'}
      subTitle={'원하시 맛을 선택해주세요'}
      argument={'base'}
      options={baseOptions}
    />
  )
}
