import { useEffect, useState } from "react"
import { OptionTitle } from "./OptionTitle"
export const UserInfo = () => {
    let storage = sessionStorage
    const [userName, setUserName] = useState('')
    const [userNumber, setUserNumber] = useState('')
    useEffect(() => {
        setUserName(storage.getItem('userName') as string)
        setUserNumber(storage.getItem('userNumber') as string)
    }, [storage])
    return (
        <>
            <OptionTitle title={'예약자 정보'} mandatory={true} />
            <input
                onChange={e => {
                    setUserName(e.target.value)
                    storage.setItem('userName', e.target.value)
                }}
                placeholder="입금자명과 동일한 이름을 적어주세요"
                value={userName}
            />
            <input
                type="tel"
                placeholder="연락받을 번호를 적어주세요"
                onChange={e => {
                    setUserNumber(e.target.value)
                    storage.setItem('userNumber', e.target.value)
                }}
                value={userNumber}
            />
        </>
    )
}
