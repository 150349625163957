import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'

interface TimeSelectorProps {
  contentWidth: number
  selectedStartTime: string
  handleStartTimeClick: () => void
  handleStartTimeSelection: (time: string) => void
  isStartTimeDropdownVisible: boolean
  selectedEndTime: string
  handleEndTimeClick: () => void
  handleEndTimeSelection: (time: string) => void
  isEndTimeDropdownVisible: boolean
}

const getHourSelectorButtonStyle = (
  time: string,
  selectedTime: string,
  contentWidth: number,
) => {
  return {
    height: 36,
    width: contentWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #E8E8E8',
    fontSize: '15px',
    background: selectedTime === time ? '#EFFAF5' : 'transparent',
    color: selectedTime === time ? '#0BAA5D' : '#424242',
    fontWeight: selectedTime === time ? 'bold' : 'normal',
  }
}

const generateTimeOptions = () => {
  const timeOptions = []
  for (let hour = 9; hour <= 22; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, '0')
      const formattedMinute = minute.toString().padStart(2, '0')
      const time = `${formattedHour}:${formattedMinute}`
      timeOptions.push(time)
    }
  }
  return timeOptions
}

const PickupTimeRangeSelector: React.FC<TimeSelectorProps> = ({
  contentWidth,
  selectedStartTime,
  handleStartTimeClick,
  handleStartTimeSelection,
  isStartTimeDropdownVisible,
  selectedEndTime,
  handleEndTimeClick,
  handleEndTimeSelection,
  isEndTimeDropdownVisible,
}) => {
  return (
    <div>
      <div className="time-default-button" onClick={handleStartTimeClick}>
        시작시간 {selectedStartTime}{' '}
        <IoIosArrowDown className="down-select-arrow" />
      </div>
      <div
        style={{
          marginTop: 10,
          width: contentWidth,
          borderBottom: !isStartTimeDropdownVisible
            ? '1px solid #EAEAEA'
            : '1px solid #0BAA5D',
        }}
      ></div>
      {isStartTimeDropdownVisible && (
        <div className="time-dropdown">
          {generateTimeOptions().map(timeOption => {
            const [hour, minute] = timeOption.split(':').map(Number)
            const [endHour, endMinute] = selectedEndTime.split(':').map(Number)

            const isDisabled =
              hour > endHour || (hour === endHour && minute >= endMinute)

            return (
              <div
                key={timeOption}
                onClick={() => {
                  if (!isDisabled) {
                    handleStartTimeSelection(timeOption)
                  }
                }}
                style={{
                  ...getHourSelectorButtonStyle(
                    timeOption,
                    selectedStartTime,
                    contentWidth,
                  ),
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  color: isDisabled
                    ? '#B8B8B8'
                    : getHourSelectorButtonStyle(
                        timeOption,
                        selectedStartTime,
                        contentWidth,
                      ).color,
                }}
              >
                {timeOption}
              </div>
            )
          })}
        </div>
      )}

      <div className="time-default-button" onClick={handleEndTimeClick}>
        종료시간 {selectedEndTime}{' '}
        <IoIosArrowDown className="down-select-arrow" />
      </div>
      <div
        style={{
          marginTop: 10,
          width: contentWidth,
          borderBottom: !isEndTimeDropdownVisible
            ? '1px solid #EAEAEA'
            : '1px solid #0BAA5D',
        }}
      ></div>
      {isEndTimeDropdownVisible && (
        <div className="time-dropdown">
          {generateTimeOptions().map(timeOption => {
            const [hour, minute] = timeOption.split(':').map(Number)
            const [startHour, startMinute] = selectedStartTime
              .split(':')
              .map(Number)

            const isDisabled =
              hour < startHour || (hour === startHour && minute <= startMinute)

            return (
              <div
                key={timeOption}
                onClick={() => {
                  if (!isDisabled) {
                    handleEndTimeSelection(timeOption)
                  }
                }}
                style={{
                  ...getHourSelectorButtonStyle(
                    timeOption,
                    selectedEndTime,
                    contentWidth,
                  ),
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  color: isDisabled
                    ? '#B8B8B8'
                    : getHourSelectorButtonStyle(
                        timeOption,
                        selectedEndTime,
                        contentWidth,
                      ).color,
                }}
              >
                {timeOption}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default PickupTimeRangeSelector
