export default function CalendarNoti({ message }: any) {
    if (message.includes('_')) {
        const messageList = message.split('_')
        return (
            <div className="gray-box">
                {messageList[0]}
                <br />
                {messageList[1]}
            </div>
        )
    } else {
        return <div className="gray-box">{message}</div>
    }
}