import '../../css/OrderSheet.scss'

export const ModifyButtons = ({
    onClickModify,
    menu,
    onClickDelete
}: any) => {
    return (<div className="modify-buttons">
        <div
            onClick={() => {
                onClickModify(menu)
            }}
        >
            수정
        </div>
        <div
            onClick={() => {
                onClickDelete(menu)
            }}
        >
            삭제
        </div>
    </div>)
}
export const EmptyProduct = ({ onClickToSelectProduct }: any) => {
    return (<div
        onClick={onClickToSelectProduct}
        className="empty-message">
        상품 새로 고르러 가기
        <img src="/OrderCheckFront.png" />
    </div>
    )
}
export const ButtonProduct = ({ menu, onClickDelete, onClickModify }: any) => {

    const groupName = menu.product.option_group
    const productName = menu.product.name
    const img = menu.product.example_photos[0]

    const price = menu.count * menu.product.value

    return (
        <div key={productName} className="option-product-button">
            <div style={{
                display: 'flex',
                width: '100%'
            }}>
                <img className="example-product-image" key={img} src={img.src} />
                <div className="product-message-skeleton">
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}>
                        <div className="product-group-name">{groupName}</div>
                        <ModifyButtons
                            onClickDelete={onClickDelete}
                            onClickModify={onClickModify}
                            menu={menu}
                        />
                    </div>
                    <div className="product-name">
                        {productName} {menu.count}개
                        <span style={{ color: '#000000', opacity: 0.2 }}> • </span>
                        {menu.package.name}
                    </div>
                </div>
            </div>
        </div>
    )
}
