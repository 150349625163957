import { useNavigate } from 'react-router-dom'

export default function ProgressBar({ checkMode, contentWidth, orderIndex }: any) {
  const barWidth = contentWidth - 79
  const stepWidth = barWidth / 10

  const nav = useNavigate()
  const clickBackward = () => {
    nav(-1)
  }
  const barBaseStyle = {
    height: '4px',
    width: barWidth,
    background: '#EFF1ED',
    borderRadius: '100px',
    marginLeft: 19,
  }

  const backArrowStyle = {
    width: '11px',
    height: '21px',
  }
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: contentWidth,
          height: '13.5px',
          background: 'white',
          zIndex: 10,
        }}
      ></div>
      {checkMode ? <div
        style={{
          position: 'fixed',
          paddingLeft: '25px',
          paddingRight: '25px',
          width: contentWidth,
          top: 13.5,
          height: 45,
          background: 'white',
          zIndex: 10,
        }}
      ></div> :
        <div
          style={{
            position: 'fixed',
            paddingLeft: '25px',
            paddingRight: '25px',
            width: contentWidth,
            top: 13.5,
            height: 45,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'white',
            zIndex: 10,
          }}
        >
          {orderIndex === 9 ? (
            <div></div>
          ) : (
            <img onClick={clickBackward} style={backArrowStyle} src="/back.png" />
          )}
          <div style={barBaseStyle}>
            <div
              style={{
                position: 'absolute',
                width: stepWidth * orderIndex,
                height: '4px',
                background: '#2D2D2D',
                borderRadius: '100px',
              }}
            ></div>
          </div>
        </div>
      }
    </div>
  )
}
