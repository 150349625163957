import { useNavigate, useParams } from 'react-router-dom'
import '../../css/Intro.scss'
import {
  NextButtonIntro,
  NextButtonIntroSimple,
} from '../../components/OrderSequence/PageElement/NextButton'
import { useState, useEffect } from 'react'
import { Loading } from '../../components/OrderSequence/PageLayout/Loading'
const IntroBox = ({
  orderType,
  onLoad,
  shopData,
  orderData,
  contentWidth,
  color1,
}: any) => {
  let startMessage = ''
  let soldOut = false
  const information = JSON.parse(sessionStorage.getItem('information') as string)

  if (information && 'orderSheetSoldOut' in information) {
    if (information.orderSheetSoldOut === 'true' || information.orderSheetSoldOut === 'True') {
      soldOut = true
    }
  }
  if (!soldOut) {

    if (orderType === 'C') {
      startMessage = ('케이크 주문하러 가기')
    } else if (orderType === 'P') {
      startMessage = ('주문하러 가기')
    }
  } else {
    startMessage = "모든 예약이 마감되었습니다."
  }

  const startPoint = sessionStorage.getItem('startPoint')
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickStartOrder = () => {
    if (orderType === 'C') {
      nav(`/order/${shopName}/${orderPk}/Date`)
    } else {
      nav(`/order/${shopName}/${orderPk}/${startPoint}`)
    }
  }

  return (
    <>
      {shopData && orderData ? (
        <div
          style={{
            marginTop: 20,
            width: contentWidth,
            textAlign: 'center' as 'center',
          }}
          onLoad={onLoad}
        >
          <NextButtonIntroSimple
            onClick={soldOut ? () => { } : onClickStartOrder}
            color1={soldOut ? "#EEEEEE" : color1}
            message={startMessage}
          />

        </div>
      ) : null}
    </>
  )
}

const ToKakao = ({ onClickToKakao, orderType }: any) => {
  const message = orderType === "C" ? "케이크 말고 다른 주문을 원한다면?" : "다른 상품 주문을 원한다면?"
  return (
    <div className="to-kakao-box" onClick={onClickToKakao}>
      <div>{message}</div>
      <div>
        <img src="/kakao.png" />
        <p>카톡상담 바로가기</p>
      </div>
    </div>
  )
}
export default function Intro({ shopData, orderData, color1 }: any) {
  let storage = sessionStorage
  const contentWidth = Number(storage.getItem('contentWidth'))

  const orderType = storage.getItem('orderType')

  const [isLoading, setIsLoading] = useState(true)
  const onLoad = () => {
    setIsLoading(false)
  }
  const outLink = storage.getItem('outLink')
  const onClickToKakao = () => {
    if (outLink) {
      window.location.assign(outLink)
    } else {
      throw new Error("카카오 링크가 존재하지 않습니다.")
    }
  }
  const checkKakaoBackDoor = () => {
    const information = JSON.parse(storage.getItem('information') as string)
    if (information && 'kakaoBackDoor' in information) {
      if (information.kakaoBackDoor == 'true' || information.kakaoBackDoor == 'True') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loading
          height={window.innerHeight}
          width={contentWidth}
          message={'주문서를 가져오는 중입니다_잠시만 기다려주세요!'}
        />
      ) : null}
      <div className={checkKakaoBackDoor() ? "background-image" : "background-image-long"} style={{ width: contentWidth }}>
        {orderData ? <img
          src={orderData.main_img} /> : null}
        <div className="gradient"></div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: contentWidth,
        }}
      >
        <div className={checkKakaoBackDoor() ? "title-group-intro" : "title-group-intro-long"}>
          {shopData && orderData ? (
            <>
              <div className="main">{shopData.name_kr}</div>
              {orderData.subtitle.includes('_') ? (
                <div className="sub">
                  {orderData.subtitle.split('_').map((textline: string) => (
                    <div>{textline}</div>
                  ))}
                </div>
              ) : (
                <div className="sub">{orderData.subtitle}</div>
              )}
            </>
          ) : null}
        </div>
        <div>
          <IntroBox
            orderType={orderType}
            onLoad={onLoad}
            shopData={shopData}
            orderData={orderData}
            contentWidth={contentWidth}
            color1={color1}
          />
        </div>
        {checkKakaoBackDoor() ?
          <ToKakao
            orderType={orderType}
            onClickToKakao={onClickToKakao} />
          : null}
      </div>
    </div >
  )
}
