import 'react-calendar/dist/Calendar.css' // css import
import CalendarUser from '../../components/OrderSequence/Calendar/CalendarUser'
import '../../css/Common.scss'
import '../../css/Format.scss'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithoutPrice } from '../../components/OrderSequence/PageElement/NextButton'
import { Title } from '../../components/OrderSequence/Format'
import { MoveToTop } from '../../components/utility/utils'
import CalendarNoti from '../../components/OrderSequence/Calendar/CalendarNoti'
export default function SelectDate() {
  let storage = sessionStorage
  const color1 = storage.getItem('color1') as string
  const contentWidth = Number(storage.getItem('contentWidth'))
  const { shopName, orderPk } = useParams()

  if (contentWidth < 200) {
    window.location.assign(`/order/${shopName}/${orderPk}`)

  }
  let blocked_Dates = JSON.parse(storage.getItem('blockedDates') as string)
  const blockedDay = JSON.parse(storage.getItem('blockedDay') as string)
  const [selected, setSelected] = useState<Dayjs>()
  const checkMode = storage.getItem('checkMode')
  const startDate = JSON.parse(storage.getItem('startDate') as string)
  const endDate = JSON.parse(storage.getItem('endDate') as string)
  const [blockedDates, setBlockedDates] = useState([])
  const [isSetFinished, SetIsSetFinished] = useState(false)
  useEffect(() => {
    if (blocked_Dates && !isSetFinished) {
      let draftBlockDates: any = []
      const blockDates: any[] = blocked_Dates
      blockDates.forEach((date: any) => {
        var dateSplit = date.blocked_date.split('-')
        draftBlockDates.push(
          dayjs()
            .set('year', dateSplit[0])
            .set('month', Number(dateSplit[1]) - 1)
            .set('date', dateSplit[2])
        )
      })
      setBlockedDates(draftBlockDates)
      SetIsSetFinished(true)
    }
  }, [blocked_Dates])

  const nav = useNavigate()

  const onClickSubmitDate = () => {
    if (selected) {
      storage.setItem('date', selected?.toString())
      if (checkMode) {
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        nav(`/order/${shopName}/${orderPk}/Time`)
      }
    }
  }
  const [disabled, setDisabled] = useState(true)
  const onClickTargetDay = (dateSel: any) => {
    setSelected(dateSel)
    setDisabled(false)
  }
  useEffect(() => {
    let tmp = storage.getItem('date')
    if (tmp) {
      // 수정 string to dayjs
      setSelected(dayjs(tmp))
      setDisabled(false)
    }
    MoveToTop()
  }, [])
  const orderType = storage.getItem('orderType')

  const title = "날짜 선택"
  const subTitle = orderType === "C" ? "케이크 픽업을 하실 날짜를 선택해주세요" : "상품을 픽업 하실 날짜를 선택해주세요"
  const information = JSON.parse(storage.getItem('information') as string)
  let startDateBuffer = null
  let endDateBuffer = null
  let calendarNoti = ""
  if (information && 'calendarNoti' in information) {
    if ('calendarNoti' in information) {

      calendarNoti = information.calendarNoti
    }
    if ("startDateBuffer" in information) {
      startDateBuffer = information.startDateBuffer
    }
    if ("endDateBuffer" in information) {
      endDateBuffer = information.endDateBuffer
    }
  }
  return (
    <div>

      <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={1} />

      <div style={{ width: contentWidth }}>
        <Title
          title={title}
          subTitle={subTitle}
        />

        <CalendarUser
          selectColor={color1}
          contentWidth={contentWidth}
          selected={selected}
          onClickTargetDay={onClickTargetDay}
          blockedDay={blockedDay}
          blockedDates={blockedDates}
          startDate={startDate}
          endDate={endDate}
          startDateBuffer={startDateBuffer}
          endDateBuffer={endDateBuffer}
        />
        <CalendarNoti
          message={calendarNoti} />


        <NextButtonWithoutPrice
          buttonDisabled={disabled}
          onClickSubmit={onClickSubmitDate}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
        />
      </div>
    </div>
  )
}
