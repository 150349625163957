import { useParams } from "react-router-dom"
import { GetFilterFromOption } from "../../../utility/utils";

interface OptionContentProps {
    optionType: string;
    message: string;
    imgs: string[];
    price: number;
    isVacant?: Boolean;
}
export const OptionContent = (
    props: OptionContentProps
) => {

    const color1 = sessionStorage.getItem('color1') as string

    const PricePlus = (value: number) => {
        return value >= 0 ? (
            <span
                className="plus-price"
                style={{
                    color: value === 0 ? '#727272' : color1,
                }}
            >
                +{value}원
            </span>
        ) : (
            <span></span>
        )
    }
    const DisplayMessage = (_message: string, _price: number) => {
        if (_price) {
            return (
                <div className="button-message">
                    {_message}
                    {PricePlus(_price)}
                </div>
            )
        } else {
            return (
                <div className="button-message">
                    {_message}
                </div>

            )
        }
    }
    const { shopName, orderPk } = useParams()

    const onClockButton = () => {
        window.location.assign(`/order/${shopName}/${orderPk}/${props.optionType}`)
    }
    if (props.isVacant !== undefined && props.isVacant !== null && props.isVacant) {
        return (
            <>
                <div key={props.message} onClick={onClockButton} className="option-button-required">
                    <div
                        className="button-message-skeleton"
                        style={{
                            marginTop: props.imgs && props.imgs.length !== 0 ? 17 : 0,
                        }}
                    >
                        {DisplayMessage(`${props.message}를 선택해주세요`, props.price)}
                        <img src="/OrderCheckFront.png" />
                    </div>
                </div>
                <div className='mandatory-message'>*필수입력 사항입니다.</div>
            </>
        )
    } else {
        return (
            <>
                <div key={props.message} onClick={onClockButton} className="option-button">
                    <div
                        className="button-message-skeleton"
                        style={{
                            marginTop: props.imgs && props.imgs.length !== 0 ? 17 : 0,
                        }}
                    >
                        {DisplayMessage(props.message, props.price)}
                        <img src="/OrderCheckFront.png" />
                    </div>
                    {props.imgs ? (
                        <div id={props.optionType}>
                            {props.imgs?.map((x: any) => (
                                <img className="example-image" key={x} src={x} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </>

        )
    }
}