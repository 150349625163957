import { useState, useEffect } from 'react'
import '../../css/UserInfo.scss'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { Title } from '../../components/OrderSequence/Format'
import { NextButtonWithPrice } from '../../components/OrderSequence/PageElement/NextButton'
import { CalculatePrice, MoveToTop } from '../../components/utility/utils'

export default function UserInfo() {
  let storage = sessionStorage
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickSubmit = () => {
    storage.setItem('userName', userName)
    storage.setItem('userNumber', userNumber)
    nav(`/order/${shopName}/${orderPk}/Check`)
  }
  useEffect(() => {
    MoveToTop()
  }, [])
  const estimatePrice = CalculatePrice('')
  const color1 = storage.getItem('color1') as string
  const contentWidth = Number(storage.getItem('contentWidth'))
  let userNameTmp = ''
  let userNumberTmp = ''
  let userNameFromStorage = storage.getItem('userName') as string
  let userNumberTmpFromStorage = storage.getItem('userNumber') as string
  if (userNameFromStorage) {
    userNameTmp = userNameFromStorage
  }
  if (userNumberTmpFromStorage) {
    userNumberTmp = userNumberTmpFromStorage
  }
  const [userName, setUserName] = useState(userNameTmp)
  const [userNumber, setUserNumber] = useState(userNumberTmp)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  useEffect(() => {
    if (userName && userNumber) {
      if (userName == '' || userNumber.length < 10) {
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    }
  }, [userName, userNumber])
  return (
    <div
      style={{
        width: contentWidth,
        textAlign: 'center',
      }}
    >
      <ProgressBar contentWidth={contentWidth} orderIndex={8} />
      <Title
        contentWidth={contentWidth}
        title={'이름과 연락처'}
        subTitle="주문확인을 위해 연락을 드리니, 정확하게 남겨주세요"
      />
      <div className="user-input">
        <p>이름</p>
        <input
          onChange={e => {
            storage.setItem('userName', e.target.value)
            setUserName(e.target.value)
          }}
          placeholder="예약자명으로 적어주세요"
          value={userName}
        />
        <p>전화번호</p>
        <input
          type="tel"
          placeholder="연락받을 번호를 적어주세요"
          onChange={e => {
            storage.setItem('userNumber', e.target.value)
            setUserNumber(e.target.value)
          }}
          value={userNumber}
        />
      </div>
      <NextButtonWithPrice
        buttonDisabled={buttonDisabled}
        onClickSubmit={onClickSubmit}
        contentWidth={contentWidth}
        color1={color1}
        checkMode={false}
        estimatePrice={estimatePrice}
      />
    </div>
  )
}
