import { useState, useEffect, useCallback } from 'react'
import '../../css/SelectDesign.scss'
import '../../css/Common.scss'
import { Option, PhotoOption } from '../../components/OrderSequence/type'
import {
  CalculatePrice,
  FilterSortOption,
  MoveToTop,
  useHorizontalScroll,
} from '../../components/utility/utils'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithPrice } from '../../components/OrderSequence/PageElement/NextButton'
import {
  NumberInput,
  TextAreaInput,
  Title,
} from '../../components/OrderSequence/Format'
import MenuCarousel from '../../components/OrderSequence/PageElement/MenuCarousel'
const PhotoList = ({
  color1,
  photoOption,
  photoSelected,
  onClickPhotoButton,
  contentWidth,
}: any) => {
  const margin = 7.75
  const buttonWidth = (contentWidth - 2 * 25 - margin * 3) / 4

  return (
    <div className="photo-list">
      {photoOption.length !== 0
        ? photoOption.map((option: any) => (
          <div
            id={option.name}
            style={{
              position: 'relative',
              width: buttonWidth,
              height: buttonWidth,
              marginRight: margin,
              textAlign: 'center' as 'center',
            }}
            onClick={() => {
              onClickPhotoButton(option)
            }}
            key={option.src}
          >
            <img className="photo-button-inactive" src={option.src} />
            {option === photoSelected ? (
              <div
                style={{
                  position: 'absolute' as 'absolute',
                  top: 0,
                  left: 0,
                  width: buttonWidth,
                  height: buttonWidth,
                  marginRight: margin,
                  display: 'inline-block',
                }}
              >
                <div
                  style={{
                    border: `2px solid ${color1}`,
                  }}
                  className="photo-button"
                ></div>
                <div className="photo-button-white"></div>
                <div
                  className="choice-mark"
                  style={{
                    background: color1,
                  }}
                >
                  <div>선택</div>
                  <img src={`/check_white.png`} />
                </div>
              </div>
            ) : null}
          </div>
        ))
        : null}
    </div>
  )
}

export default function SelectDesignToggle() {
  let storage = sessionStorage
  let originPrice = CalculatePrice('design')
  let optionData = JSON.parse(storage.getItem('options') as string)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const color1 = storage.getItem('color1') as string
  const checkMode = storage.getItem('checkMode')

  const [estimatePrice, setEstimatePrice] = useState(originPrice)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isSetFinished, SetIsSetFinished] = useState(false)

  const [designOption, setDesignOption] = useState<any[]>([])
  const [designSelected, setDesignSelected] = useState<Option>()
  const [numberPrices, setNumberPrices] = useState<any[]>([])

  //마우스 스크롤
  const [photoSelected, setPhotoSelected] = useState<PhotoOption>()
  const [imageIndex, setImageIndex] = useState<number>(1)
  const [photos, setPhotos] = useState<PhotoOption[]>()

  useEffect(() => {
    if (optionData && !isSetFinished) {
      const tempSort = FilterSortOption(optionData, 'design')
      setDesignOption(tempSort)
      setButtonDisabled(false)

      let tmp = JSON.parse(storage.getItem('design') as string)

      if (tmp) {
        if (tmp.name !== '자유형') {
          setDesignSelected(tmp)
          setImageIndex(Number(storage.getItem('image_index')))
          setPhotoSelected(
            tmp.example_photos[Number(storage.getItem('image_index')) - 1],
          )
          setPhotos(tmp.example_photos)
        } else {
          setDesignSelected(tmp)
          setImageIndex(Number(storage.getItem('image_index')))
          setPhotoSelected(
            tmp.example_photos[Number(storage.getItem('image_index')) - 1],
          )
          setPhotos(tmp.example_photos)
        }
      } else {
        setDesignSelected(tempSort[0])
        setImageIndex(1)
        setPhotoSelected(tempSort[0].example_photos[0])
        setPhotos(tempSort[0].example_photos)
      }
      const tempNumbercount = FilterSortOption(optionData, 'number_count')
      setNumberPrices(tempNumbercount)
      MoveToTop()
      SetIsSetFinished(true)
    }
  }, [optionData])
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  let customMessage = storage.getItem('customMessage')
  let draftMessage = ''
  if (customMessage) {
    draftMessage = customMessage
  }

  const maxLength = 200
  const [textCount, setTextCount] = useState(0)
  const [text, setText] = useState(draftMessage)
  const onChangeText = (e: any) => {
    setTextCount(e.target.value.length)
    if (e.target.value.length < maxLength) {
      setText(e.target.value)
    }
  }

  const onClickSubmit = () => {
    if (designSelected) {
      storage.setItem('design', JSON.stringify(designSelected))
      if (photos) {
        storage.setItem('images', JSON.stringify([photos[imageIndex - 1].src]))
        storage.setItem('image_index', imageIndex.toString())
        storage.setItem('designName', photos[imageIndex - 1].name.split('_')[1])
      }

      storage.setItem('customMessage', text)
      if (checkMode) {
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        nav(`/order/${shopName}/${orderPk}/${designSelected.next_point}`)
      }
    }
  }

  const onClickButton = (option: any) => {
    setDesignSelected(option)
    setEstimatePrice(originPrice + option.value)
    setButtonDisabled(false)
    setImageIndex(1)
    setPhotos(option.example_photos)
    setPhotoSelected(option.example_photos[0])
  }

  const onClickPhotoButton = (option: any) => {
    setPhotoSelected(option)
    let index: any = photos?.indexOf(option)
    setImageIndex(index + 1)
  }

  const designSpaceHolder =
    '별도 요청사항이 없는 경우 샘플사진을 바탕으로 제작됩니다.'
  const NumberTable = ({ designSelected }: any) => {
    return (
      <table className="desc-table">
        <tbody>
          <tr>
            <td>설명</td>
            <td>{designSelected.desc}</td>
          </tr>
          <tr>
            <td>추가금</td>
            <td>{numberDesc()}</td>
          </tr>
        </tbody>
      </table>
    )
  }
  const NormalTable = ({ designSelected }: any) => {
    return (
      <table className="desc-table">
        <tbody>
          <tr>
            <td>설명</td>
            <td>{designSelected.desc}</td>
          </tr>

          {designSelected.desc_price === 'None' ? null : (
            <tr>
              <td>추가금</td>
              <td>{designSelected.desc_price}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }
  const numberDesc = () => {
    let Desc = ''
    numberPrices.forEach((priceOption: any, index) => {
      if (index + 1 === numberPrices.length) {
        Desc += `${priceOption.name} ${priceOption.value.toLocaleString(
          'en-US',
        )}원`
      } else {
        Desc += `${priceOption.name} ${priceOption.value.toLocaleString(
          'en-US',
        )}원 / `
      }
    })
    return Desc
  }
  return (
    <>
      <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={3} />

      <div
        style={{
          width: contentWidth,
          textAlign: 'center' as 'center',
        }}
      >
        <Title
          contentWidth={contentWidth}
          title="디자인 선택"
          subTitle="색상,형태,숫자 등 변경내용은 요청사항에 적어주세요"
        />
        <MenuCarousel
          color1={color1}
          designOption={designOption}
          designSelected={designSelected}
          onClickButton={onClickButton}
          contentWidth={contentWidth}
        />

        {designSelected ? (
          designSelected.name === '숫자' ? (
            <NumberTable designSelected={designSelected} />
          ) : (
            <NormalTable designSelected={designSelected} />
          )
        ) : null}
        {photoSelected ? (
          <div className="selected-photo">
            <img src={photoSelected.src} />
            <div className="design-number" style={{ background: color1 }}>
              {photoSelected.name.split('_')[1]}
            </div>
            <div className="design-number-nav">
              {imageIndex} / {photos ? photos.length : 0}
            </div>
          </div>
        ) : null}
        {designSelected ? (
          <PhotoList
            color1={color1}
            photoOption={designSelected.example_photos}
            photoSelected={photoSelected}
            onClickPhotoButton={onClickPhotoButton}
            contentWidth={contentWidth}
          />
        ) : null}

        <div style={{ width: contentWidth, height: 30 }}></div>

        <TextAreaInput
          contentWidth={contentWidth}
          text={text}
          onChangeText={onChangeText}
          textCount={textCount}
          maxCount={maxLength}
          placeholder={designSpaceHolder}
        />
        <div style={{ width: contentWidth, height: 100 }}></div>
        <NextButtonWithPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
          estimatePrice={estimatePrice}
        />
      </div>
    </>
  )
}
