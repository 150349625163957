import { createBrowserRouter } from 'react-router-dom'
import Home from './routes/ShopAdmin/LoginPage/Home'
import Cake from './routes/ShopAdmin/MainPage/Cake'
import CalendarPickupOptions from './routes/ShopAdmin/Calendar/CalendarPickupOptions'
import Login from './routes/ShopAdmin/LoginPage/Login'
import Root from './components/Root'
import NotFound from './routes/NotFound'
import Order from './routes/Order'
import CallBack from './routes/CallBack'
import SelectDate from './routes/OrderSequence/SelectDate'
import SelectTime from './routes/OrderSequence/SelectTime'
import SelectPhoto from './routes/OrderSequence/SelectPhoto'
import SelectDesignToggle from './routes/OrderSequence/SelectDesignToggle'
import SelectDesignScroll from './routes/OrderSequence/SelectDesignScroll'
import SelectDesignChristmas from './routes/OrderSequence/SelectDesignChristmas'
import SelectSize from './routes/OrderSequence/SelectSize'
import SelectBase from './routes/OrderSequence/SelectBase'
import SelectPackage from './routes/OrderSequence/SelectPackage'
import UserInfo from './routes/OrderSequence/UserInfo'
import OrderCheck from './routes/OrderSequence/OrderCheck'
import SelectProduct from './routes/OrderSequence/SelectProduct'
import ToKakao from './routes/OrderSequence/ToKakao'
import PickupSettings from './routes/ShopAdmin/SettingPages/PickupSettings'
import ClipboardCopyFailed from './routes/OrderSequence/ClipboardCopyFailed'
import SelectProductDetail from './routes/OrderSequence/SelectProductDetail'
import SelectProductPackage from './routes/OrderSequence/SelectProductPackage'
import Product from './routes/ShopAdmin/MainPage/Product'
import SoldOutSettings from './routes/ShopAdmin/SettingPages/SoldOutSettings'
import DesignDetail from './routes/OrderSequence/DesignDetail'
import SelectNumber from './routes/OrderSequence/SelectNumber'
import SelectLettering from './routes/OrderSequence/SelectLettering'
import SelectDesignScrollNarrow from './routes/OrderSequence/SelectDesignScrollNarrow'
import SelectCandle from './routes/OrderSequence/SelectCandle'
import UploadImage from './routes/UploadImage'
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'shopAdminHome',
        element: <Home />,
      },
      {
        path: 'shopAdminLogin',
        element: <Login />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/C',
        element: <Cake />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/P',
        element: <Product />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/P/soldOutAdmin',
        element: <SoldOutSettings />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/:orderPk/Time',
        element: <CalendarPickupOptions />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/:orderPk/PickupTime',
        element: <PickupSettings />,
      },
      {
        path: 'order/:shopName/:orderPk',
        element: <Order />,
      },
      {
        path: 'order/:shopName/:orderPk/Date',
        element: <SelectDate />,
      },
      {
        path: 'order/:shopName/:orderPk/Time',
        element: <SelectTime />,
      },
      {
        path: 'order/:shopName/:orderPk/Photo',
        element: <SelectPhoto />,
      },
      {
        path: 'order/:shopName/:orderPk/Product',
        element: <SelectProduct />,
      },
      {
        path: 'order/:shopName/:orderPk/Product/Select',
        element: <SelectProductDetail />,
      },
      {
        path: 'order/:shopName/:orderPk/Product/Package',
        element: <SelectProductPackage />,
      },
      {
        path: 'order/:shopName/:orderPk/DesignToggle',
        element: <SelectDesignToggle />,
      },
      {
        path: 'order/:shopName/:orderPk/DesignScroll',
        element: <SelectDesignScroll />,
      },
      {
        path: 'order/:shopName/:orderPk/DesignScrollNarrow',
        element: <SelectDesignScrollNarrow />,
      },
      {
        path: 'order/:shopName/:orderPk/DesignChristmas',
        element: <SelectDesignChristmas />,
      },
      {
        path: 'order/:shopName/:orderPk/Number',
        element: <SelectNumber />,
      },
      {
        path: 'order/:shopName/:orderPk/designDetail/:designPk',
        element: <DesignDetail />,
      },
      {
        path: 'order/:shopName/:orderPk/Size',
        element: <SelectSize />,
      },
      {
        path: 'order/:shopName/:orderPk/Base',
        element: <SelectBase />,
      },
      {
        path: 'order/:shopName/:orderPk/Lettering',
        element: <SelectLettering />,
      },
      {
        path: 'order/:shopName/:orderPk/Package',
        element: <SelectPackage />,
      },
      {
        path: 'order/:shopName/:orderPk/Candle',
        element: <SelectCandle />,
      },
      {
        path: 'order/:shopName/:orderPk/User',
        element: <UserInfo />,
      },
      {
        path: 'order/:shopName/:orderPk/Check',
        element: <OrderCheck />,
      },
      {
        path: 'order/:shopName/:orderPk/ToKakao',
        element: <ToKakao />,
      },
      {
        path: 'order/:shopName/:orderPk/ClipboardCopyFailed',
        element: <ClipboardCopyFailed />,
      },
      // {
      //   path: 'upload/custom/UploadImage',
      //   element: <UploadImage />,
      // },

      {
        path: 'callback',
        element: <CallBack />,
      },
    ],
  },
])
export default router
