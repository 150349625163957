export const slackMessage = (
  customerName: string | null,
  customerPhoneNo: string | null,
  shopName: string | undefined,
  orderPk: string | undefined,
  clipBoardMessage: string,
) => {
  return {
    '업체 이름': shopName,
    orderPk: orderPk,
    options: clipBoardMessage,
    '클릭 날짜 및 시간': new Date().toLocaleString('ko-KR', {
      timeZone: 'Asia/Seoul',
    }),
  }
}

export const slackMessageToKakao = {
  프로세스: `Move to Kakao : ${new Date().toLocaleString('ko-KR', {
    timeZone: 'Asia/Seoul',
  })}`,
}
