import SyncLoader from 'react-spinners/SyncLoader'

export function Loading({ width, height, message }: any) {
  const DivideMessage = (_message: string) => {
    const messageStyle = {
      color: '#000000',
      textAlign: 'center' as 'center',
      fontSize: 18,
      fontFamily: 'Apple SD Gothic Neo',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '25px',
      marginTop: 27,
      /* identical to box height, or 114% */
      letterSpacing: '-0.09px',
    }
    if (_message.includes('_')) {
      const messageList = _message.split('_')
      return (
        <div style={messageStyle}>
          {messageList[0]}
          <br />
          {messageList[1]}
        </div>
      )
    } else {
      return <div style={messageStyle}>{_message}</div>
    }
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: width,
        height: height,
        zIndex: 30,
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
      }}
    >
      <SyncLoader
        color={'#36d7b7'}
        size={8}
        cssOverride={{
          marginTop: 100,
          width: 100,
          display: 'inline-block',
        }}
      />
      {DivideMessage(message)}
    </div>
  )
}
