import { Grid } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'

import '../../css/SelectTime.scss'
import { useEffect, useRef, useState } from 'react'
import { NextButtonWithoutPrice } from '../../components/OrderSequence/PageElement/NextButton'
import { Title } from '../../components/OrderSequence/Format'
import { parseTime } from '../../components/utility/utils'
import { DividerLine } from '../../components/ProductSequence/Format'
import QuickNoti from '../../components/OrderSequence/PageElement/QuickNoti'

const TimeGrid = ({
  contentWidth,
  timeList,
  blockedTimesFormat,
  timeSelected,
  onClickTime,
  color1,
  color3,
}: any) => {
  return (
    <Grid
      marginLeft={25}
      width={contentWidth - 50}
      columnGap={'6px'}
      rowGap={'6px'}
      templateColumns={'repeat(4,1fr)'}
    >
      {timeList
        ? timeList.map((timeOption: any) => (
            <TimeButton
              key={timeOption.format('HH:mm')}
              timeOption={timeOption}
              blockedTimesFormat={blockedTimesFormat}
              timeSelected={timeSelected}
              onClickTime={onClickTime}
              color1={color1}
              color3={color3}
            />
          ))
        : null}
    </Grid>
  )
}

const TimeButton = ({
  timeOption,
  blockedTimesFormat,
  timeSelected,
  onClickTime,
  color1,
  color3,
}: any) => {
  const blockedTimeStyle = {
    border: `1px solid #D4D4D4`,
    background: '#FFFFFF',
    color: '#B8B8B8',
  }
  const openTimeStyle = {
    border: `1px solid ${color1}`,
    background: color3,
  }
  const selectedTimeStyle = {
    background: color1,
  }
  return (
    <button
      key={timeOption}
      disabled={blockedTimesFormat.includes(timeOption.format('HH:mm'))}
      onClick={() => onClickTime(timeOption)}
      style={
        blockedTimesFormat.includes(timeOption.format('HH:mm'))
          ? blockedTimeStyle
          : timeOption.format('HH:mm') === timeSelected.format('HH:mm')
          ? selectedTimeStyle
          : openTimeStyle
      }
      className={
        timeOption.format('HH:mm') === timeSelected.format('HH:mm')
          ? 'time_button_active'
          : 'time_button'
      }
    >
      {timeOption.format('HH:mm')}
    </button>
  )
}
const QuickInput = ({
  contentWidth,
  onClickQuickService,
  quickRequired,
  color1,
  quickTime,
  onChangeQuickTime,
}: any) => {
  return (
    <>
      <div
        style={{
          width: contentWidth - 50,
          marginLeft: 25,
          marginBottom: 150,
        }}
      >
        <DividerLine contentWidth={contentWidth} />
        <div style={{ display: 'flex' }}>
          <input
            onClick={onClickQuickService}
            checked={quickRequired}
            style={{ accentColor: color1 }}
            className="radio-button-time"
            type="radio"
          />
          <div
            className={quickRequired ? 'quick-legend' : 'quick-legend-disabled'}
          >
            퀵 배달로 받을래요
          </div>
        </div>
        {quickRequired ? (
          <>
            <input
              onChange={onChangeQuickTime}
              style={{ width: contentWidth - 50 }}
              placeholder="케이크 도착 희망시간 입력"
              value={quickTime}
              type="text"
              className="quick-input"
            />
            <QuickNoti />
          </>
        ) : null}
      </div>{' '}
    </>
  )
}
export default function SelectTime() {
  const dayjs = require('dayjs')
  const timezone = require('dayjs/plugin/timezone')
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Asia/Seoul')
  let storage = sessionStorage
  const color1 = storage.getItem('color1') as string
  const color3 = storage.getItem('color3') as string
  const date = dayjs(storage.getItem('date') as string)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const checkMode = storage.getItem('checkMode')
  const startTime = parseTime(storage.getItem('startTime') as string)
  const endTime = parseTime(storage.getItem('endTime') as string)
  const orderType = storage.getItem('orderType')

  let blocked_times = JSON.parse(storage.getItem('blockedTimes') as string)

  const [isSetFinished, SetIsSetFinished] = useState(false)
  useEffect(() => {
    if (blocked_times && !isSetFinished) {
      let draftBlockTimes: any = []
      let draftBlockTimesFormat: any = []
      blocked_times.forEach((time: any) => {
        var timeParsed = dayjs.tz(time.blocked_time)
        const dateTmp = timeParsed.get('date')
        const monthTmp = timeParsed.get('month')
        const yearTmp = timeParsed.get('year')
        if (
          date.get('date') === dateTmp &&
          date.get('month') === monthTmp &&
          date.get('year') === yearTmp
        ) {
          draftBlockTimes.push(timeParsed)
          draftBlockTimesFormat.push(timeParsed.format('HH:mm'))
        }
      })
      setBlockedTimesFormat(draftBlockTimesFormat)
      SetIsSetFinished(true)
    }
  }, [blocked_times])
  const [blockedTimesFormat, setBlockedTimesFormat] = useState<string[]>([])
  let midTime = startTime
  let timeList1 = []
  let timeList2 = []
  while (midTime <= endTime) {
    if (midTime.hour() < 12) {
      timeList1.push(midTime)
    } else {
      timeList2.push(midTime)
    }
    midTime = midTime.add(30, 'minute')
  }
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const startPoint = sessionStorage.getItem('startPoint')

  const initTime = dayjs().set('hour', 1).set('minute', 44)
  const [timeSelected, setTimeSelected] = useState(initTime)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const onClickTime = (timeOption: any) => {
    setTimeSelected(timeOption)
  }

  const [quickTime, setQuickTime] = useState('')
  useEffect(() => {
    let timeOrigin = storage.getItem('time') as string
    let quickTimeOrigin = storage.getItem('quickTime') as string
    let quickSelected = Boolean(storage.getItem('quickRequired'))
    if (quickSelected) {
      setQuickRequired(quickSelected)
      setQuickTime(quickTimeOrigin)
      setTimeSelected(dayjs(initTime))
    } else {
      if (timeOrigin) {
        setTimeSelected(dayjs(timeOrigin))
      }
    }
  }, [])

  const title = '시간 선택'
  const subTitle =
    orderType === 'C'
      ? '케이크 픽업을 하실 시간을 선택해주세요'
      : '상품을 픽업 하실 시간을 선택해주세요'

  const isQuickAllowed = JSON.parse(
    storage.getItem('information') as string,
  ).quickAllowed
  const [quickRequired, setQuickRequired] = useState(false)
  const onClickQuickService = () => {
    setQuickRequired(!quickRequired)
  }
  const ref = useRef<HTMLDivElement>(null)
  const [coverHeight, setCoverHeight] = useState(0)
  useEffect(() => {
    if (quickRequired) {
      if (ref.current) {
        setCoverHeight(ref.current.clientHeight)
        setTimeSelected(initTime)
      }
    }
  }, [quickRequired])
  const onChangeQuickTime = (event: any) => {
    setQuickTime(event.target.value)
  }

  // 버튼 활성화 기준
  useEffect(() => {
    if (!quickRequired) {
      if (timeSelected === initTime) {
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    } else {
      if (quickRequired && quickTime.length !== 0) {
        setButtonDisabled(false)
      } else {
        setButtonDisabled(true)
      }
    }
  }, [quickRequired, quickTime, timeSelected])

  const onClickSubmitTime = () => {
    if (!quickRequired) {
      storage.setItem('time', timeSelected?.toString())
    } else if (quickRequired) {
      storage.setItem('quickTime', quickTime)
      storage.setItem('quickRequired', quickRequired?.toString())
    }
    if (checkMode) {
      nav(`/order/${shopName}/${orderPk}/Check`)
    } else if (orderType === 'P') {
      nav(`/order/${shopName}/${orderPk}/User`)
    } else {
      nav(`/order/${shopName}/${orderPk}/${startPoint}`)
    }
  }
  return (
    <div className="select-time">
      <ProgressBar
        checkMode={checkMode}
        contentWidth={contentWidth}
        orderIndex={2}
      />

      <div style={{ width: contentWidth }}>
        <Title title={title} subTitle={subTitle} />
        <div className="time-range" ref={ref}>
          {quickRequired ? (
            <div
              style={{
                position: 'absolute',
                width: contentWidth,
                height: coverHeight,
                background: 'white',
                opacity: 0.8,
                zIndex: 3,
              }}
            >
              {' '}
            </div>
          ) : (
            <></>
          )}
          {timeList1.length > 0 && (
            <>
              <p className="time-start">오전</p>
              <TimeGrid
                contentWidth={contentWidth}
                timeList={timeList1}
                blockedTimesFormat={blockedTimesFormat}
                timeSelected={timeSelected}
                onClickTime={onClickTime}
                color1={color1}
                color3={color3}
              />
            </>
          )}

          {timeList2.length > 0 && (
            <>
              <p>오후</p>
              <TimeGrid
                contentWidth={contentWidth}
                timeList={timeList2}
                blockedTimesFormat={blockedTimesFormat}
                timeSelected={timeSelected}
                onClickTime={onClickTime}
                color1={color1}
                color3={color3}
              />
            </>
          )}

          <div className="legend">
            <div
              style={{
                border: `1px solid ${color1}`,
                background: color3,
              }}
            ></div>
            <div>픽업가능</div>
            <div
              style={{
                background: '#F3F3F3',
                border: '1px solid #DADADA',
              }}
            ></div>
            <div>픽업불가</div>
          </div>
        </div>

        {isQuickAllowed ? (
          <QuickInput
            contentWidth={contentWidth}
            onClickQuickService={onClickQuickService}
            quickRequired={quickRequired}
            color1={color1}
            quickTime={quickTime}
            onChangeQuickTime={onChangeQuickTime}
          />
        ) : null}
        <div style={{ height: 140, width: '100%' }}></div>
        <NextButtonWithoutPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmitTime}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
        />
      </div>
    </div>
  )
}
