import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { changeOrderSheetTimeRange } from '../../../api'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { mapBlockedDaysNumberToDay, parseTime } from '../../utility/utils'

export default function GeneralAdmin({ shopName, blockedDay }: any) {
  //sessionStorage
  let storage = sessionStorage
  let contentWidth = 397
  let color1 = '#000'
  let color2 = '#000'
  let color3 = '#000'
  contentWidth = Number(storage.getItem('contentWidth'))
  const orderPk = storage.getItem('orderPk')
  color1 = storage.getItem('color1') as string
  color2 = storage.getItem('color2') as string
  color3 = storage.getItem('color3') as string
  const startTime = parseTime(storage.getItem('startTime') as string)
  const endTime = parseTime(storage.getItem('endTime') as string)
  const sortedBlockedDay = blockedDay.sort((a: number, b: number) => a - b)

  const onChangeOpenTime = (e: any) => {
    console.log(e.target.value)
  }
  //시작시간과 종료시간은 모두 0시부터 24시까지 30분 간격으로 선택 가능
  //
  let midTime = startTime
  let timeList1: Dayjs[] = [startTime]
  while (midTime < endTime) {
    midTime = midTime.add(30, 'minute')
    timeList1.push(midTime)
  }

  // 버튼 선택시 selected에 추가, selected가 하나인 경우에 해당 시간 앞에 있는 애들은 disalbed
  // selected가 두개인 경우에는 두 시간 사이에 있는 시간만 액티브
  // 3번째 클릭되면 selected 다시 처음부터
  const [selectedTimes, setSelectedTimes] = useState<Dayjs[]>([])
  const onClickTime = (time: Dayjs) => {
    if (selectedTimes.length < 2) {
      let newSelectedTimes = [...selectedTimes]
      newSelectedTimes.push(time)
      setSelectedTimes(newSelectedTimes)
    } else {
      const newSelectedTimes = [time]
      setSelectedTimes(newSelectedTimes)
    }
  }
  const [activeTimeList, setActiveTimeList] = useState<Dayjs[]>(timeList1)

  useEffect(() => {
    if (selectedTimes.length === 1) {
      let selectedTime: Dayjs = selectedTimes[0]
      let newActiveTimeList = timeList1.filter(
        (time: Dayjs) => time >= selectedTime,
      )
      setActiveTimeList(newActiveTimeList)
    } else if (selectedTimes.length === 2) {
      let selectedTime: Dayjs = selectedTimes[1]
      let newActiveTimeList = activeTimeList.filter(
        (time: Dayjs) => time <= selectedTime,
      )
      setActiveTimeList(newActiveTimeList)
    }
  }, [selectedTimes])

  const TimeButton = ({ activeTimeList, timeOption, onClickTime }: any) => {
    const buttonDefaultStyle = {
      height: 45,
      width: (contentWidth - 50 - 18) / 4,
      background: color3,
      border: `1px solid ${color2}`,
      borderRadius: '4px',
    }
    const buttonDisabledStyle = {
      height: 45,
      width: (contentWidth - 50 - 18) / 4,
      background: '#FFF',
      border: '1px solid #D4D4D4',
      borderRadius: '4px',
      color: '#D4D4D4',
    }
    const checkIsDisabled = (timeOption: Dayjs) => {
      let isDisabled = true
      activeTimeList.forEach((time: Dayjs) => {
        if (
          time.isSame(timeOption, 'hour') &&
          time.isSame(timeOption, 'minute')
        ) {
          isDisabled = false
          return false
        }
      })
      return isDisabled
    }
    const disabled = checkIsDisabled(timeOption)
    return (
      <button
        onClick={() => {
          onClickTime(timeOption)
        }}
        key={timeOption.format('HH:mm')}
        style={disabled ? buttonDisabledStyle : buttonDefaultStyle}
      >
        {timeOption.format('HH:mm')}
      </button>
    )
  }
  const { shopUuid } = useParams()
  const onClickSubmit = () => {
    const startTime = activeTimeList[0]
    const lastTime = activeTimeList[activeTimeList.length - 1]
    changeTimeRangeMutation.mutate({
      pk: orderPk,
      shopUuid: shopUuid,
      start_time: startTime,
      end_time: lastTime,
    })
  }
  const nav = useNavigate()
  const onClickPickupTime = () => {
    nav(`/shopAdmin/${shopUuid}/${shopName}/${orderPk}/PickupTime`)
  }

  const changeTimeRangeMutation = useMutation(changeOrderSheetTimeRange, {
    onSuccess: (data: any) => {
      alert('성공~')
    },
    onError: (data: any) => {
      alert(data)
    },
  })
  return (
    <div
      style={{
        paddingBottom: 45,
      }}
    >
      <div
        style={{
          display: 'inline-block',
          width: contentWidth - 68,
          borderBottom: '1px solid #EAEAEA',
          marginTop: 20,
          marginBottom: 20,
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          marginLeft: 34,
          marginBottom: 10,
        }}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: 15,
            color: '#727272',
          }}
        >
          픽업시간
          <span style={{ fontWeight: 400 }}>
            {' '}
            {startTime.format('HH:mm')}~{endTime.format('HH:mm')}
          </span>
          <span
            style={{
              marginLeft: 4,
              width: '4px',
              height: '4px',
              backgroundColor: '#D9D9D9',
              borderRadius: '50%',
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          ></span>
          <span style={{ marginLeft: 2, fontWeight: 700 }}> 휴일 </span>
          <span
            style={{
              marginLeft: 2,
              fontWeight: 400,
            }}
          >
            {' '}
            {sortedBlockedDay.length > 0
              ? `매주 ${mapBlockedDaysNumberToDay(
                  sortedBlockedDay.filter(
                    (dayNumber: number) => dayNumber !== 0,
                  ),
                )?.join(', ')}${sortedBlockedDay.includes(0) ? ', 일' : ''}`
              : '없음'}
          </span>
          <button
            onClick={() => onClickPickupTime()}
            style={{
              marginLeft: 6,
              fontWeight: 500,
            }}
          >
            설정
            <div
              style={{
                marginTop: 1,
                width: '100%',
                borderBottom: '1px solid #767676',
              }}
            ></div>
          </button>
        </p>
      </div>
    </div>
  )
}
