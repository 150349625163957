import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import { useParams, useNavigate } from 'react-router-dom'
import { sendSlackMessage, uploadOrderRequest } from '../../../api'
import { slackMessage } from '../../../constants/slackMessage'
import { CalculatePrice } from '../../utility/utils'
import '../../../css/ClipBoard.scss'
import { ModalLayout } from '../../FormatModal'
import { JsonToMessage } from '../../utility/calculator'
import { useToast } from '@chakra-ui/react'

interface TimeMessageProps {
  quickRequired: Boolean
  time: Dayjs
  quickTime: string
}

const TimeMessage = ({ quickRequired, time, quickTime }: TimeMessageProps) => {
  if (quickRequired) {
    return quickTime
  } else {
    return `${time.format('HH')}시 ${time.format('mm')}분`
  }
}
const QuickMessage = ({ quickRequired, quickAddress }: any) => {
  if (quickRequired) {
    return `퀵 배송 : ${quickAddress.기본주소} ${quickAddress.상세주소}\n`
  } else {
    return ``
  }
}
const LoadCakeClipBoard = () => {
  let storage = sessionStorage
  let customerName = storage.getItem('userName')
  let customerPhoneNo = storage.getItem('userNumber')
  let date = dayjs(storage.getItem('date'))
  let time = dayjs(storage.getItem('time'))
  let quickTime = storage.getItem('quickTime') as string
  let design = JSON.parse(storage.getItem('design') as string)
  let designName = storage.getItem('designName' as string)
  if (!designName) {
    designName = ''
  }
  let price = CalculatePrice('')
  let imageLink = storage.getItem('link')
  let quickRequired = Boolean(storage.getItem('quickRequired'))
  let quickAddress = JSON.parse(storage.getItem('address') as string)
  let quickRequiredMessage = QuickMessage({ quickRequired, quickAddress })
  const clipBoardMessageByRequest = (request: any) => {
    let option = JSON.parse(storage.getItem(request.option) as string)
    if (!option) {
      return ''
    }
    let message = `${request.desc} : ${option.name} ${option.value.toLocaleString('en-US')}원\n`
    const contentName = request.option + 'Content'
    if (storage.getItem(contentName)) {
      message = `${request.desc} : ${storage.getItem(contentName)} ${option.value.toLocaleString('en-US')}원\n`
    }
    return message
  }
  const information = JSON.parse(storage.getItem('information') as string)
  if ('requestList' in information) {
    let messageSkeleton = `이름 : ${customerName}
연락처 : ${customerPhoneNo}
픽업일자 : ${date.format('MM')}월 ${date.format('DD')}일 
픽업시간 : ${TimeMessage({ quickRequired, time, quickTime })}
${quickRequiredMessage}형태 : ${design.name} ${designName} ${design.value.toLocaleString('en-US')}원\n`
    information.requestList.map((request: any) => {
      messageSkeleton += clipBoardMessageByRequest(request)
    })
    messageSkeleton += `예상금액 : ${price.toLocaleString('en-US')}원
이미지 링크 : ${imageLink}
ordered from ovening`
    return messageSkeleton
  }
  let numberContent = storage.getItem('numberContent') as string
  let number = JSON.parse(storage.getItem('number') as string)
  let size = JSON.parse(storage.getItem('size') as string)
  let base = JSON.parse(storage.getItem('base') as string)
  let lettering = JSON.parse(storage.getItem('lettering') as string)
  let letteringContent = storage.getItem('letteringContent')
  let packageOption = JSON.parse(storage.getItem('package') as string)
  let customMessage = storage.getItem('customMessage')
    ? storage.getItem('customMessage')
    : ''

  let clipBoardMessage = ''
  if (size) {
    clipBoardMessage = `이름 : ${customerName}
연락처 : ${customerPhoneNo}
픽업일자 : ${date.format('MM')}월 ${date.format('DD')}일 
픽업시간 : ${TimeMessage({ quickRequired, time, quickTime })}
${quickRequiredMessage}형태 : ${design.name} ${designName} ${design.value.toLocaleString('en-US')}원
사이즈 : ${size.name} ${size.value.toLocaleString('en-US')}원
설기 : ${base.name} ${base.value.toLocaleString('en-US')}원
문구 : ${letteringContent} ${lettering.value.toLocaleString('en-US')}원
포장 : ${packageOption.name} ${packageOption.value.toLocaleString('en-US')}원
예상금액 : ${price.toLocaleString('en-US')}원
커스텀 요청 : ${customMessage}
이미지 링크 : ${imageLink}
ordered from ovening
        `
  } else {
    clipBoardMessage = `이름 : ${customerName}
연락처 : ${customerPhoneNo}
픽업일자 : ${date.format('MM')}월 ${date.format('DD')}일 
픽업시간 : ${TimeMessage({ quickRequired, time, quickTime })}
퀵 배송 : ${quickRequired}
형태 : ${design.name} ${design.value.toLocaleString('en-US')}원
숫자 : ${numberContent} ${number.value.toLocaleString('en-US')}원
설기 : ${base.name} ${base.value.toLocaleString('en-US')}원
문구 : ${letteringContent} ${lettering.value.toLocaleString('en-US')}원
포장 : ${packageOption.name} ${packageOption.value.toLocaleString('en-US')}원
예상금액 : ${price.toLocaleString('en-US')}원
요청사항 : ${customMessage}
이미지 링크 : ${imageLink}

ordered from ovening
        `
  }
  return clipBoardMessage
}
const LoadProductClipBoard = () => {
  let storage = sessionStorage
  let customerName = storage.getItem('userName')
  let customerPhoneNo = storage.getItem('userNumber')
  let date = dayjs(storage.getItem('date'))
  let time = dayjs(storage.getItem('time'))
  const calculatorJson = JSON.parse(storage.getItem('calculator') as string)
  let customMessage = storage.getItem('customMessage')
  let clipBoardMessage =
    `
이름 : ${customerName}
연락처 : ${customerPhoneNo}
픽업일자 : ${date.format('MM')}월 ${date.format('DD')}일 
픽업시간 : ${time.format('HH')}시 ${time.format('mm')}분
` +
    JsonToMessage(calculatorJson) +
    `
요청사항 : ${customMessage}
    
ordered from ovening
    `
  return clipBoardMessage
}
export default function CopyClipBoard({ orderSheetHeight }: any) {
  let storage = sessionStorage
  const color1 = storage.getItem('color1') as string
  const contentWidth = Number(storage.getItem('contentWidth'))
  const orderType = storage.getItem('orderType')
  let customerName = storage.getItem('userName')
  let customerPhoneNo = storage.getItem('userNumber')
  let date = dayjs(storage.getItem('date'))
  let time = dayjs(storage.getItem('time'))
  let quickRequired = Boolean(storage.getItem('quickRequired'))
  let quickTime = storage.getItem('quickTime') as string
  let design = JSON.parse(storage.getItem('design') as string)
  let designName = storage.getItem('designName' as string)
  if (!designName) {
    designName = ''
  }
  let size = JSON.parse(storage.getItem('size') as string)
  let base = JSON.parse(storage.getItem('base') as string)
  let letteringContent = storage.getItem('letteringContent')
  let packageOption = JSON.parse(storage.getItem('package') as string)
  let customMessage = storage.getItem('customMessage')
    ? storage.getItem('customMessage')
    : ''
  let imageLink = storage.getItem('link')

  let pickupDate = `${date.format('MM')}월 ${date.format('DD')}일`
  let pickupTime = `${TimeMessage({ quickRequired, time, quickTime })}`
  let fullDesignName = `${design.name} ${designName}`
  let sizeName = `${size?.name}` // size 는 Optional 합니다. 아래 OrderRequest mutate할 때 필요하지만, 이 값은 nullabale합니다.
  let baseName = `${base?.name}`
  let packageOptionName = `${packageOption?.name}`
  let quickAddress = JSON.parse(storage.getItem('address') as string)
  let quickRequiredMessage = QuickMessage({ quickRequired, quickAddress })
  let price = CalculatePrice('')
  let numberContent = storage.getItem('numberContent') as string

  let clipBoardMessage = ''
  if (orderType === 'P') {
    clipBoardMessage = LoadProductClipBoard()
  } else {
    clipBoardMessage = LoadCakeClipBoard()
  }
  sessionStorage.setItem('clipBoardMessage', clipBoardMessage)
  const nav = useNavigate()

  const toast = useToast()

  const toastTextStyle = {
    color: '#FFF',
    textAlign: 'center' as 'center',
    fontFamily: 'Apple SD Gothic Neo',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '25px' /* 178.571% */,
    letterSpacing: '-0.07px',
  }
  // Error Case Handle
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const onClickFinish = () => {
    setModalIsOpen(false)
    nav(`/order/${shopName}/${orderPk}/ClipboardCopyFailed`)
  }

  const copyClipBoard = async () => {
    try {
      const clipBoard = clipBoardMessage
      await navigator.clipboard.writeText(clipBoard)
      uploadOrderRequestMutation.mutate({
        customerName,
        customerPhoneNo,
        clipBoardMessage,
        shopName,
        orderPk,
        pickupDate,
        pickupTime,
        design: fullDesignName,
        size: sizeName,
        base: baseName,
        lettering: letteringContent,
        packageOption: packageOptionName,
        customMessage,
        imageLink,
        quickRequiredMessage,
        estimatePrice: price,
        numberContent,
      })
      sendSlackMessage(
        slackMessage(
          customerName,
          customerPhoneNo,
          shopName,
          orderPk,
          clipBoardMessage,
        ),
      )
      toast({
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
          width: contentWidth
        },
        render: () => (
          <div
            style={{
              position: 'fixed',
              left: 25,
              bottom: 25,
              marginBottom: 100,
              display: 'flex',
              width: contentWidth - 50,
              height: 45,
              padding: '10px 55px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 12,
              background: 'rgba(0,0,0,0.6)',
            }}
          >
            <div style={toastTextStyle}>주문내용이 복사되었어요!</div>
          </div>
        )
      })
    } catch (e) {
      setModalIsOpen(true)
    }
  }
  const { shopName, orderPk } = useParams()

  const uploadOrderRequestMutation = useMutation(uploadOrderRequest, {
    onSuccess: (response: any) => {
      nav(`/order/${shopName}/${orderPk}/ToKaKao`)
    },
  })

  const clickCopyStyle = {
    color: '#2D2D2D',
    fontFamily: 'Apple SD Gothic Neo',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: -0.5,

    marginTop: 17,
  }

  return (
    <div>
      {/* background white */}
      <div
        style={{
          position: 'absolute',
          top: modalIsOpen ? -60 : 60,
          left: 0,
          width: contentWidth,
          height: orderSheetHeight,
          zIndex: -1,
          opacity: modalIsOpen ? 0.5 : 0.8,
          textAlign: 'center',
          justifyContent: 'center',
          background: modalIsOpen ? '#000' : 'white',
        }}
      ></div>
      {modalIsOpen ? (
        <ModalLayout
          title={'복사실패'}
          subTitle={'내용 복사에 실패했습니다._주문내용을 직접 복사해주세요'}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          onClickFinish={onClickFinish}
        />
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 60,
            left: 0,
            zIndex: 12,
            textAlign: 'center',
            justifyContent: 'center',
            width: contentWidth,
          }}
        >
          <div
            style={{
              width: contentWidth,
              position: 'fixed',
              bottom: 210,
            }}
          >
            <img
              src="/hand.png"
              style={{
                display: 'inline-block',
              }}
              width={100}
              height={100}
            />
            <p style={clickCopyStyle}>복사를 눌러보세요</p>
          </div>
          <button
            onClick={copyClipBoard}
            style={{
              width: contentWidth - 50,
              background: color1,
              position: 'fixed',
              height: 63,
              left: 25,
              bottom: 25,
            }}
            id="button_finish"
            className="button_next_square_float"
          >
            <p id="button_finish_p" className="button_text_main">
              {'내용 복사'}
            </p>
          </button>
        </div>
      )}
    </div>
  )
}
