import '../../css/Common.scss'
import { useState } from 'react'
import { CheckSelected } from '../../components/utility/utils'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

export function GrayDot() {
  return (
    <div
      style={{
        marginLeft: 4,
        marginRight: 4,
        width: 3,
        height: 3,
        background: '#000000',
        opacity: 0.2,
        display: 'inline',
      }}
    ></div>
  )
}

export function ExamplePhoto({ contentWidth, selected }: any) {
  const getDesc = (index: number) => {
    if (selected) {
      if (selected.desc.includes('_')) {
        return selected.desc.split('_')[index]
      } else {
        return selected.desc
      }
    }
  }
  return (
    <>
      <div
        style={{
          width: contentWidth - 50,
        }}
        className="sample_image_legend"
      >
        {selected.name === '투명 상자' ? getDesc(1) : null}
      </div>
      {selected.example_photos.map((photo: any) => (
        <img
          key={photo.src}
          style={{
            borderRadius: 10,
            objectFit: 'cover',
            width: contentWidth - 50,
            height: contentWidth - 50,
            marginTop: 10,
          }}
          src={photo.src}
        />
      ))}
    </>
  )
}
export function LetterInput({
  contentWidth,
  letter,
  onChangeText,
  textCount,
}: any) {
  return (
    <div
      style={{
        width: contentWidth - 50,
        textAlign: 'right',
        marginBottom: 150,
      }}
    >
      <textarea
        style={{
          width: contentWidth - 50,
          textAlign: 'left',
        }}
        value={letter}
        onChange={onChangeText}
        placeholder="ex) 엄마아빠 환갑 축하드려요!"
        className="lettering_input"
      />
      <span className="letter-count-main">{textCount}자</span>
      <span className="letter-count-sub">/ 20자</span>
    </div>
  )
}
export function VerticalChoiceButton({
  contentWidth,
  option,
  selectedOption,
  onClickButton,
  color1,
}: any) {

  return (
    <div
      key={option.name}
      style={
        CheckSelected(option, selectedOption)
          ? {
            marginTop: 10,
            width: contentWidth - 50,
            left: 25,
            border: `1px solid ${color1}`,
            background: '#FFFFFF',
          }
          : {
            marginTop: 10,
            width: contentWidth - 50,
            left: 25,
            background: '#F3F3F3',
          }
      }
      className={
        CheckSelected(option, selectedOption)
          ? 'size_button_active'
          : 'size_button'
      }
      onClick={() => onClickButton(option)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            className={
              CheckSelected(option, selectedOption)
                ? 'size_option_text_active'
                : 'size_option_text'
            }
          >
            {option.name}{' '}
            <span style={{ color: '#000000', opacity: 0.2 }}>•</span>{' '}
            {option.value.toLocaleString('en-US')}원
          </p>
          {typeof option.information === 'object' && "label" in option.information ? (
            CheckSelected(option, selectedOption) ? (
              <div style={{ background: color1 }} className="best-mark-active">
                {option.information.label}
              </div>
            ) : (
              <div className="best-mark-inactive">{option.information.label}</div>
            )
          ) : null}
        </div>

        {option === selectedOption ? (
          <button>
            <IoIosArrowUp />
          </button>
        ) : (
          <button>
            <IoIosArrowDown />
          </button>
        )}
      </div>
      {CheckSelected(option, selectedOption) ? (
        <>
          <p className="size_option_text_desc">{option.desc}</p>
        </>
      ) : null}
    </div>
  )
}
export function NumberInput({ contentWidth, no, setNo, numberPrices }: any) {
  const onClickQuestion = () => {
    setModalOpen(true)
  }
  const handleOutClick = () => {
    setModalOpen(false)
  }
  const [modalOpen, setModalOpen] = useState(false)

  const placeholder = 'ex) 60'

  const Modal = () => {
    const textStyle = {
      fontFamily: 'Apple SD Gothic Neo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '17px',
      /* identical to box height */
      letterSpacing: '-0.06em',
      color: '#222',
    }
    return (
      <div
        style={{
          position: 'absolute',
          left: contentWidth - 123 - 12,
          bottom: 230,
          textAlign: 'left',
          paddingLeft: 10,
          paddingTop: 12,
          border: '1px solid  #B8B8B8',
          borderRadius: 4,
          background: '#FFF',
          width: 123,
          height: 74,
        }}
      >
        {numberPrices?.map((numberOption: any) => (
          <div style={textStyle}>
            {numberOption.name}{' '}
            <b>{numberOption.value.toLocaleString('en-US')}원</b>
          </div>
        ))}
      </div>
    )
  }
  return (
    <div className="input-number">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div className="cake-no">
          케이크 숫자 <span className="mandatory-mark">*필수</span>
        </div>
        <div className="extra-cost" onClick={onClickQuestion}>
          <span>추가금</span>
          <div>?</div>
        </div>
        {modalOpen ? (
          <>
            {Modal()}
            <div className="background-layer" onClick={handleOutClick}></div>
          </>
        ) : null}
      </div>
      <input
        style={{
          width: contentWidth - 50,
          height: 50,
          textAlign: 'left',
          marginTop: 8,
        }}
        value={no}
        onChange={e => {
          if (e.target.value.length <= numberPrices.length) {
            setNo(e.target.value)
          }
        }}
        placeholder={placeholder}
        className="lettering_input"
      />
    </div>
  )
}

export function HorizontalChoiceButton({
  options,
  selected,
  contentWidth,
  color1,
  onClickButton,
}: any) {
  const PriceText = (option: any) => {
    if (option.value != 0) {
      return (
        <div
          style={{ marginTop: 10 }}
          className={
            CheckSelected(option, selected)
              ? 'lettering_option_text_sub_active'
              : 'lettering_option_text_sub'
          }
        >
          {option.value.toLocaleString('en-US')}원
        </div>
      )
    } else {
      return <></>
    }
  }
  const optionButton = {
    width: '100%',
    marginRight: 6,
    background: '#F3F3F3',
    height: 75,
    borderRadius: '10px',
  }

  const optionActiveButton = {
    width: '100%',
    marginRight: 6,
    border: `1px solid ${color1}`,
    background: '#FFFFFF',
    height: 75,
    borderRadius: '10px',
  }
  return (
    <div
      style={{
        width: contentWidth - 50,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {options.map((option: any) => (
        <button
          key={option.name}
          style={
            CheckSelected(option, selected) ? optionActiveButton : optionButton
          }
          onClick={() => onClickButton(option)}
        >
          <div>
            <p
              className={
                CheckSelected(option, selected)
                  ? 'lettering_option_text_active'
                  : 'lettering_option_text'
              }
            >
              {option.name}
            </p>
            {PriceText(option)}
          </div>
        </button>
      ))}
    </div>
  )
}
export function TextAreaInput({
  contentWidth,
  text,
  onChangeText,
  textCount,
  placeholder,
  maxCount,
}: any) {
  return (
    <div className="text-area-input">
      <div>
        <p>요청사항</p>
        <p>
          <span className="message-count">{textCount}자</span>{' '}
          <span className="message-count-max">/ {maxCount}자</span>
        </p>
      </div>
      <textarea
        style={{
          width: contentWidth - 50,
          textAlign: 'left',
        }}
        value={text}
        onChange={onChangeText}
        placeholder={placeholder}
        className="lettering_input"
      />
    </div>
  )
}
export function Title({ title, subTitle, customMarginTop }: any) {
  const DivideTitle = (_title: string, context: string) => {
    if (_title.includes('_')) {
      const titleList = _title.split('_')
      if (context === 'title') {
        return (
          <div>
            {titleList[0]}
            <br />
            {titleList[1]}
          </div>
        )
      } else {
        return (
          <div>
            {titleList[0]}
            <br />
            {titleList[1]}
          </div>
        )
      }
    } else {
      if (context === 'title') {
        return <div>{_title}</div>
      } else {
        return <div>{_title}</div>
      }
    }
  }
  return (
    <div className="title-group" style={customMarginTop ? {
      marginTop: customMarginTop
    } : {}}>
      {DivideTitle(title, 'title')}
      {DivideTitle(subTitle, 'subTitle')}
    </div>
  )
}
