import { useState, useEffect } from 'react'
import '../../css/Common.scss'
import '../../css/Buttons.scss'
import '../../css/SelectPhoto.scss'
import '../../css/Format.scss'
import '../../css/SelectSize.scss'
import { Option } from '../../components/OrderSequence/type'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import {
  FilterSortOption,
  CalculatePrice,
} from '../../components/utility/utils'
import { IconContext } from 'react-icons'
import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithPrice } from '../../components/OrderSequence/PageElement/NextButton'
import { Title } from '../../components/OrderSequence/Format'
const AddPhoto = ({
  selected,
  photoOption,
  images,
  setImages,
  onClickButton,
  color1,
  text,
  setText,
}: any) => {
  const [textCount, setTextCount] = useState(0)
  const onChangeText = (e: any) => {
    if (e.target.value.length > 200) {
      return
    }
    setTextCount(e.target.value.length)
    setText(e.target.value)
  }

  const [active, setActive] = useState(true)
  useEffect(() => {
    if (selected && selected.name == '사진/도안 있음') {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [selected])

  const onChangePhotoInput = (e: any) => {
    const targetFiles = (e.target as HTMLInputElement).files as FileList
    const targetFilesArray = Array.from(targetFiles)
    if (targetFilesArray.length > 3) {
      let length = targetFilesArray.length
      targetFilesArray.splice(3, length - 3)
      const selectedFiles: string[] = targetFilesArray.map(file => {
        return URL.createObjectURL(file)
      })
      setImages((prev: any) => prev.concat(selectedFiles))
    } else {
      const selectedFiles: string[] = targetFilesArray.map(file => {
        return URL.createObjectURL(file)
      })
      setImages((prev: any) => prev.concat(selectedFiles))
    }
  }
  const fileRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    fileRef?.current?.click()
  }
  const onClickDelete = (url: string) => {
    const newImages = [...images]
    const index = newImages.indexOf(url, 0)
    if (index > -1) {
      newImages.splice(index, 1)
    }
    setImages(newImages)
  }
  return active ? (
    <>
      <div
        onClick={() => {
          onClickButton(photoOption[0])
        }}
        style={{
          borderLeft: `1.5px solid ${color1}`,
          borderRight: `1.5px solid ${color1}`,
          borderTop: `1.5px solid ${color1}`,
        }}
        className="add-photo-header"
      >
        <div>
          <p className="button-text">사진/도안 첨부하기 </p>
          <button>
            <IconContext.Provider value={{ size: '18' }}>
              {' '}
              <IoIosArrowUp />
            </IconContext.Provider>
          </button>
        </div>
      </div>
      <div
        style={{
          borderLeft: `1.5px solid ${color1}`,
          borderRight: `1.5px solid ${color1}`,
          borderBottom: `1.5px solid ${color1}`,
        }}
        className="add-photo-body"
      >
        <div className="title-in-box">사진 (최대3장)</div>
        <input
          ref={fileRef}
          style={{ display: 'none' }}
          onChange={onChangePhotoInput}
          type="file"
          multiple
          accept="image/jpeg,image/jpg,image/png"
        />
        <div className="photo-input">
          <button disabled={images.length >= 3} onClick={handleClick}>
            <img src="/camera.png" />
            <p>{images ? images.length : 0}/3</p>
          </button>
          {images.map((url: string, i: number) => (
            <div className="preview-image-block">
              <img className="preview-image" key={url} src={url} />
              <button
                onClick={() => {
                  onClickDelete(url)
                }}
              >
                <img className="delete-button" src="/delete_photo.png" />
              </button>
            </div>
          ))}
        </div>
        <div className="custom-message">{`요청사항 (${textCount}/200)`}</div>
        <textarea
          onChange={onChangeText}
          value={text}
          placeholder="구체적으로 생각하시는 디자인이 있으시면 설명을 적어주세요"
        />
        <div>
          {' '}
          • 자유디자인의 경우 희망하시는 내용에 따라 진행이 불가능 하실 수 도
          있습니다
        </div>
      </div>
    </>
  ) : (
    <div
      className="button-not-selected"
      onClick={() => {
        onClickButton(photoOption[0])
      }}
    >
      <div>
        <p>사진/도안 첨부하기 </p>
        {active ? (
          <button>
            {' '}
            <IconContext.Provider value={{ size: '18' }}>
              {' '}
              <IoIosArrowUp />
            </IconContext.Provider>
          </button>
        ) : (
          <button>
            {' '}
            <IconContext.Provider value={{ size: '18' }}>
              {' '}
              <IoIosArrowDown />
            </IconContext.Provider>
          </button>
        )}
      </div>
    </div>
  )
}
const PhotoSelectBox = ({
  photoOption,
  selected,
  contentWidth,
  images,
  setImages,
  text,
  setText,
  color1,
  onClickButton,
  checkMode,
}: any) => {
  return (
    <div className="photo-select-box">
      <AddPhoto
        selected={selected}
        images={images}
        setImages={setImages}
        text={text}
        color1={color1}
        setText={setText}
        photoOption={photoOption}
        onClickButton={onClickButton}
        contentWidth={contentWidth}
      />
      {checkMode ? null : (
        <div
          className={
            selected && selected.name === '사진/도안 없음'
              ? 'button-selected'
              : 'button-not-selected'
          }
          style={
            selected && selected.name === '사진/도안 없음'
              ? {
                border: `1px solid ${color1}`,
              }
              : {}
          }
          onClick={() => onClickButton(photoOption[1])}
        >
          <div>
            <p>샘플에서 고르기 </p>
          </div>
        </div>
      )}
    </div>
  )
}
export default function SelectPhoto() {
  const [photoOption, setPhotoOption] = useState<any[]>([])
  const [selected, setSelected] = useState<Option>()
  let storage = sessionStorage

  let customImages = storage.getItem('images')
  let customPhoto = storage.getItem('customPhoto')
  const [images, setImages] = useState<string[]>([])

  let customMessage = storage.getItem('customMessage')
  let draftMessage = ''
  if (customMessage) {
    draftMessage = customMessage
  }
  const [text, setText] = useState<string>(draftMessage)
  const checkMode = storage.getItem('checkMode')
  let originPrice = CalculatePrice('')
  let optionData = JSON.parse(storage.getItem('options') as string)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const color1 = storage.getItem('color1') as string

  const [estimatePrice, setEstimatePrice] = useState(originPrice)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isSetFinished, SetIsSetFinished] = useState(false)
  useEffect(() => {
    if (optionData && !isSetFinished) {
      const tempSort = FilterSortOption(optionData, 'photo')
      setPhotoOption(tempSort)
      let draft_Images = []
      if (customImages && customPhoto) {
        draft_Images = JSON.parse(customImages)
        setImages(draft_Images)
        let optionTmp = JSON.parse(customPhoto as string)
        setSelected(optionTmp)
        setButtonDisabled(false)
      } else {
        setButtonDisabled(true)
      }
      setEstimatePrice(estimatePrice + tempSort[0].value)
      SetIsSetFinished(true)
    }
  }, [optionData])

  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickSubmit = () => {
    if (selected) {
      if (selected.name === '사진/도안 있음') {
        const designFreeOption = {
          id: 1,
          name: '자유형',
          option_type: 'design',
          value: 0,
        }
        storage.setItem('design', JSON.stringify(designFreeOption))
        storage.setItem('customPhoto', JSON.stringify(selected))
        storage.setItem('images', JSON.stringify(images))
        storage.setItem('customMessage', text)
        storage.setItem('designName', '')
      }

      if (checkMode) {
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        nav(`/order/${shopName}/${orderPk}/${selected.next_point}`)
      }
    }
  }
  const onClickButton = (option: any) => {
    if (selected && option.name === selected.name) {
      let x = { ...option }
      x.name = ''
      setSelected(x)
      setButtonDisabled(true)
    } else {
      setSelected(option)
      setButtonDisabled(false)
      setEstimatePrice(originPrice + option.value)
    }
  }

  useEffect(() => {
    if (images && selected) {
      if (selected.name === '사진/도안 있음' && images.length != 0) {
        setButtonDisabled(false)
      } else if (selected.name === '사진/도안 있음' && images.length === 0) {
        setButtonDisabled(true)
      } else if (selected.name === '사진/도안 없음') {
        setButtonDisabled(false)
      }
    }
  }, [images, selected])

  return (
    <div>
      <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={3} />

      <div style={{ width: contentWidth }}>
        <Title
          contentWidth={contentWidth}
          title={'원하는 케이크 디자인_사진/도안 첨부'}
          subTitle="포레스트데이 인스타에서 디자인을 캡쳐하시거나_
                    직접 만드신 도안을 전달하셔도 됩니다"
        />
        <PhotoSelectBox
          photoOption={photoOption}
          selected={selected}
          contentWidth={contentWidth}
          images={images}
          setImages={setImages}
          setText={setText}
          text={text}
          color1={color1}
          onClickButton={onClickButton}
          checkMode={checkMode}
        />
        <NextButtonWithPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
          estimatePrice={estimatePrice}
        />
      </div>
    </div>
  )
}
