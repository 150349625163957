import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FinishButtonWithPrice } from '../PageElement/NextButton'
import '../../../css/OrderSheet.scss'
import { CalculatorFromJson, ProductCalculator } from '../../utility/calculator'
import UserInfo from '../../../routes/OrderSequence/UserInfo'
import { DateTime } from './OrderContent/DateTme'
import { OptionTitle } from './OrderContent/OptionTitle'
import { EmptyProduct, ButtonProduct } from '../../ProductSequence/OrderSheet'
import { CustomizeMessage } from './OrderContent/CustomMessage'

export function ProductOrderSheet({ onLoadPage, setClipBoardCopyLoaded }: any) {
  let storage = sessionStorage
  const contentWidth = Number(storage.getItem('contentWidth'))

  const color1 = storage.getItem('color1') as string

  const calculatorJson = JSON.parse(storage.getItem('calculator') as string)
  const [priceTotal, setPriceTotal] = useState(0)
  const [productCalculator, setProductCalculator] = useState<any>()
  const [isCalculatorSet, setIsCalculatorSet] = useState(false)
  const [menuList, setMenuList] = useState(calculatorJson)
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    if (calculatorJson && !isCalculatorSet) {
      let calculator = CalculatorFromJson(calculatorJson)
      setProductCalculator(calculator)
      setPriceTotal(calculator.getTotalPrice())
      setIsCalculatorSet(true)
    }
  }, [calculatorJson])
  const onClickDelete = (menu: any) => {
    productCalculator.deleteProduct(menu.product)
    let calculatorJson = productCalculator.toJson()
    setMenuList(calculatorJson)
    storage.setItem('calculator', JSON.stringify(calculatorJson))
    setIsEmpty(calculatorJson.every((menu: any) => {
      return menu.count === 0
    }))
  }
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickModify = (menu: any) => {
    storage.setItem(
      'productGroup',
      JSON.stringify(
        productCalculator.productGroups[menu.product.option_group],
      ),
    )
    nav(`/order/${shopName}/${orderPk}/Product/Select`)
  }
  const onClickToSelectProduct = () => {
    nav(`/order/${shopName}/${orderPk}/Product`)
  }
  const onClickSubmit = () => {
    setClipBoardCopyLoaded(true)
  }
  return (
    <>
      <UserInfo />
      <DateTime />
      <OptionTitle title={'선택한 상품'} mandatory={true} />
      {isEmpty ? <EmptyProduct onClickToSelectProduct={onClickToSelectProduct} /> :
        menuList.map((menu: any) => {
          if (menu.count !== 0) {
            return (
              <ButtonProduct
                menu={menu}
                productCalculator={productCalculator}
                onClickDelete={onClickDelete}
                onClickModify={onClickModify}
              />
            )
          }
        })
      }

      <CustomizeMessage
      // title="요청사항"
      // placeholder="묶음포장이나 추가 요청사항이 있으시면 자유롭게 기재해주세요"
      />
      <FinishButtonWithPrice
        onLoad={onLoadPage}
        buttonDisabled={false}
        onClickSubmit={onClickSubmit}
        contentWidth={contentWidth}
        color1={color1}
        estimatePrice={priceTotal.toLocaleString('en-US')}
      />
    </>
  )
}