import {
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
  RefObject,
  memo,
} from 'react'

import '../../css/SelectDesign.scss'
import '../../css/SelectDesignInstagram.scss'
import '../../css/Common.scss'
import { Option } from '../../components/OrderSequence/type'
import {
  CalculatePrice,
  FilterSortOption,
} from '../../components/utility/utils'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import DesignGroupGrid from '../../components/OrderSequence/PageLayout/DesignGroupGrid'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithoutPrice } from '../../components/OrderSequence/PageElement/NextButton'
import { Title } from '../../components/OrderSequence/Format'
import { MenuGroupCarousel } from '../../components/OrderSequence/PageElement/MenuCarousel'

export default function SelectDesignScroll() {
  let storage = sessionStorage
  let originPrice = CalculatePrice('design')
  let optionData = JSON.parse(storage.getItem('options') as string)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const color1 = storage.getItem('color1') as string
  const checkMode = storage.getItem('checkMode')

  const [estimatePrice, setEstimatePrice] = useState(originPrice)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isSetFinished, SetIsSetFinished] = useState(false)

  const [designOptions, setDesignOptions] = useState<any[]>([])
  const [designSelected, setDesignSelected] = useState<Option>()
  useEffect(() => {
    if (optionData && !isSetFinished) {
      const tempSort = FilterSortOption(optionData, 'design')
      setDesignOptions(tempSort)
      setButtonDisabled(false)
      let tmp = JSON.parse(storage.getItem('design') as string)
      if (tmp) {
        setDesignSelected(tmp)
        setSelectedGroupName(tmp.option_group)
      } else {
        const optionNotSoldOut = tempSort.find((option: Option) => {
          if (
            ('soldOut' in option.information &&
              option.information.soldOut === 'True') ||
            option.information.soldOut === 'true'
          ) {
            return false
          } else {
            return true
          }
        })
        if (optionNotSoldOut) {
          setDesignSelected(optionNotSoldOut)
          setSelectedGroupName(optionNotSoldOut.option_group)
        }
      }
      SetIsSetFinished(true)
    }
  }, [optionData])
  const onClickButton = useCallback((option: Option) => {
    setDesignSelected(option)
    setEstimatePrice(originPrice + option.value)
    setButtonDisabled(false)
    setSelectedGroupName(option.option_group)
  }, [])
  const [scrollMoving, setScrollMoving] = useState(false)
  // Group 별로 컨트롤 하기 위한 딕셔너리
  const [designOptionGrouped, setDesignOptionGrouped] = useState<{
    [key: string]: Option[]
  }>()
  // AutoScroll을 위한 Ref 리스트
  const [elRefs, setElRefs] = useState<{
    [key: string]: RefObject<HTMLDivElement>
  }>()
  const options: IntersectionObserverInit = {
    root: null,
    threshold: 0.5,
  }

  const interSectionCallback: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[],
    observer,
  ) => {
    entries.forEach(entry => {
      const { isIntersecting } = entry
      // 관찰 요소와 교차될때마다 index를 계산하여 viewIndex의 값을 바꿔준다.
      if (isIntersecting && !scrollMoving) {
        setSelectedGroupName(entry.target.getAttribute('id') as string)
      }
    })
  }
  const io = new IntersectionObserver(interSectionCallback, options)
  //Intersection Observer
  useEffect(() => {
    if (elRefs && Object.keys(elRefs).length !== 0) {
      Object.keys(elRefs).forEach((key: any) => {
        if (elRefs[key]) {
          io.observe(elRefs[key].current!)
        }
      })
    }
  }, [elRefs, options])
  useEffect(() => {
    if (designOptions) {
      let designOptionsGroupedTmp = designOptions.reduce((acc, curr) => {
        // [1]
        const key = curr.option_group // [2]
        if (acc[key]) acc[key].push(curr)
        else acc[key] = [curr]
        return acc
      }, {})
      setDesignOptionGrouped(designOptionsGroupedTmp)
      let refArray: any = {}
      Object.keys(designOptionsGroupedTmp).map((designGroupName: string) => {
        refArray[designGroupName] = createRef()
      })
      setElRefs(refArray)
    }
  }, [designOptions])

  const focusToSelectedGroup = (groupName: any) => {
    setScrollMoving(true)
    if (elRefs) {
      Object.keys(elRefs).forEach((key: string) => {
        if (key === groupName) {
          let refCur: any = elRefs[key].current
          if (refCur) {
            let scrollPosition = refCur.offsetTop - 40
            window.scrollTo({
              top: scrollPosition,
              behavior: 'smooth' as ScrollBehavior,
            })
          }
        }
      })
    }
    setSelectedGroupName(groupName)
    setScrollMoving(false)
  }

  const [selectedGroupName, setSelectedGroupName] = useState('')
  const checkGroupSelected = (name: string) => {
    return name === selectedGroupName
  }
  const onClickCarouselButton = (groupName: string) => {
    setSelectedGroupName(groupName)
    if (elRefs) {
      focusToSelectedGroup(groupName)
    }
  }

  // 제출 시
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickSubmit = () => {
    if (designSelected) {
      storage.setItem('design', JSON.stringify(designSelected))
      storage.setItem(
        'images',
        JSON.stringify([designSelected.example_photos[0].src]),
      )
      if (checkMode) {
        if (designSelected.next_point === 'number') {
          storage.removeItem('size')
        }
        if (designSelected.next_point === 'size') {
          storage.removeItem('number')
        }
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        nav(`/order/${shopName}/${orderPk}/${designSelected.next_point}`)
      }
    }
  }
  const checkIsSelected = useCallback(
    (option: any) => {
      if (designSelected) {
        return (
          option.name === designSelected.name &&
          option.option_group === designSelected.option_group
        )
      }
      return false
    },
    [designSelected],
  )
  const location = useLocation()
  const onLoadMain = () => {
    if (location.state && location.state.prevPath === 'DesignDetail') {
      let lastPosition = Number(sessionStorage.getItem('lastPosition'))
      window.scrollTo({
        top: lastPosition,
        behavior: 'instant' as ScrollBehavior,
      })
      window.history.replaceState({}, document.title)
    } else {
      if (designSelected) {
        focusToSelectedGroup(designSelected.option_group)
      }
    }
  }
  return (
    <>
      <ProgressBar
        checkMode={checkMode}
        contentWidth={contentWidth}
        orderIndex={3}
      />
      <div
        style={{
          width: contentWidth,
          textAlign: 'center' as 'center',
        }}
      >
        <Title
          contentWidth={contentWidth}
          title="디자인 선택"
          subTitle="원하는 디자인을 선택해주세요"
        />
        <MenuGroupCarousel
          designOptionGrouped={designOptionGrouped}
          checkGroupSelected={checkGroupSelected}
          onClickCarouselButton={onClickCarouselButton}
          contentWidth={contentWidth}
        />
        <div onLoad={onLoadMain} style={{ marginTop: 115, marginBottom: 150 }}>
          {designOptionGrouped && elRefs
            ? Object.keys(designOptionGrouped).map(function (designGroupName) {
                return (
                  <DesignGroupGrid
                    key={designGroupName}
                    refOfDesignGroup={elRefs[designGroupName]}
                    designGroupName={designGroupName}
                    designOptions={designOptionGrouped[designGroupName]}
                    contentWidth={contentWidth}
                    color1={color1}
                    checkIsSelected={checkIsSelected}
                    onClickButton={onClickButton}
                  />
                )
              })
            : null}
        </div>
        <NextButtonWithoutPrice
          contentWidth={contentWidth}
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          color1={color1}
          checkMode={checkMode}
        />
      </div>
    </>
  )
}
