export default function KakaoPingPong() {
    const Balloon = () => {
        return (
            <div style={{
                position: 'absolute',
                left: 24,
                top: 6
            }}>
                <div style={{
                    position: 'absolute',
                    left: 24,
                    top: 14
                }}
                    className="to-paste"
                >붙여넣기</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="101" height="59" viewBox="0 0 101 59" fill="none">
                    <g filter="url(#filter0_d_3720_5854)">
                        <mask id="path-1-inside-1_3720_5854" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 4C12.4772 4 8 8.47715 8 14V31C8 36.5228 12.4772 41 18 41H45.215L48.9038 45.8859C49.704 46.9457 51.296 46.9457 52.0962 45.8859L55.785 41H83C88.5229 41 93 36.5228 93 31V14C93 8.47715 88.5229 4 83 4H18Z" />
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 4C12.4772 4 8 8.47715 8 14V31C8 36.5228 12.4772 41 18 41H45.215L48.9038 45.8859C49.704 46.9457 51.296 46.9457 52.0962 45.8859L55.785 41H83C88.5229 41 93 36.5228 93 31V14C93 8.47715 88.5229 4 83 4H18Z" fill="white" />
                        <path d="M45.215 41L46.0131 40.3974L45.713 40H45.215V41ZM48.9038 45.8859L49.7019 45.2833L49.7019 45.2833L48.9038 45.8859ZM52.0962 45.8859L52.8942 46.4884L52.0962 45.8859ZM55.785 41V40H55.287L54.9869 40.3974L55.785 41ZM9 14C9 9.02944 13.0294 5 18 5V3C11.9249 3 7 7.92487 7 14H9ZM9 31V14H7V31H9ZM18 40C13.0294 40 9 35.9706 9 31H7C7 37.0751 11.9249 42 18 42V40ZM45.215 40H18V42H45.215V40ZM49.7019 45.2833L46.0131 40.3974L44.4169 41.6026L48.1058 46.4884L49.7019 45.2833ZM51.2981 45.2833C50.898 45.8133 50.102 45.8133 49.7019 45.2833L48.1058 46.4884C49.3061 48.0782 51.694 48.0782 52.8942 46.4884L51.2981 45.2833ZM54.9869 40.3974L51.2981 45.2833L52.8942 46.4884L56.5831 41.6026L54.9869 40.3974ZM83 40H55.785V42H83V40ZM92 31C92 35.9706 87.9706 40 83 40V42C89.0751 42 94 37.0751 94 31H92ZM92 14V31H94V14H92ZM83 5C87.9706 5 92 9.02944 92 14H94C94 7.92487 89.0751 3 83 3V5ZM18 5H83V3H18V5Z" fill="#A5A5A5" mask="url(#path-1-inside-1_3720_5854)" />
                    </g>
                    <defs>
                        <filter id="filter0_d_3720_5854" x="0" y="0" width="101" height="58.6807" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="4" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3720_5854" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3720_5854" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        )

    }
    return <div style={{
        position: 'relative',
        height: 98,
        paddingTop: 39
    }}>
        <div className="kakao-ping-pong">
            상담직원에게 메시지 보내기
        </div>
        <Balloon />
    </div>
}