import '../../../css/SelectTime.scss'
export default function QuickNoti() {
    return (
        <>
            <div className='quick-noti'>
                * 착불 퀵 비용 별도 발생합니다.
            </div>
            <div className='quick-noti'>
                * 배송으로 인한 파손에 대하여 책임지지 않습니다.
            </div>
        </>


    )
}