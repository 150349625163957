import { styled, Text } from '@ignite-ui/react'

export const CalendarContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

export const CalendarHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const CalendarTitle = styled(Text, {
  fontFamily: 'SF Pro Display',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '25px',
  lineHeight: '30px',
  color: '#000000',
})

export const CalendarActions = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '$2',
  color: '$gray200',
  marginBottom: 15,
  button: {
    all: 'unset',
    cursor: 'pointer',
    lineHeight: 0,
    borderRadius: '$sm',

    svg: {
      width: '$5',
      height: '$5',
    },

    '&:hover': {
      color: '$gray100',
    },

    '&:focus': {
      boxShadow: '0 0 0 2px $colors$gray100',
    },
  },
})

export const CalendarBody = styled('table', {
  width: 308,
  height: 347,
  fontFamily: 'SF Pro Display',
  borderSpacing: '0.25rem',
  tableLayout: 'fixed',

  'thead th': {
    fontWeight: 500,
    fontSize: '13px',
    color: '#424242',
  },
})
