import { useState } from "react";
import { PostCode } from "./PostCode";
export default function AddressInput({
    address, setAddress, contentWidth, onChangeAddressDetail
}: any) {

    const [postCodeModal, setPostCodeModal] = useState(false);
    return (<>
        <input
            style={{
                width: contentWidth - 50,
                textAlign: 'left'
            }} className='option-button'
            placeholder="배달 받으실 주소를 검색해주세요"
            value={address.기본주소}
            onClick={() => { setPostCodeModal(true) }} >

        </input>
        <input
            placeholder='상세 주소 입력'
            onChange={onChangeAddressDetail}
            value={address.상세주소}
            style={{ width: contentWidth - 50 }} className='option-button' type="text" />
        {postCodeModal ?
            <div style={{
                width: contentWidth,
                height: '100vh',
                position: 'fixed',
                zIndex: 10,
                top: 0,
                left: 0,
                background: 'rgba(0, 0, 0, 0.5)'
            }}>
                <PostCode
                    address={address}
                    setAddress={setAddress}
                    setPostCodeModal={setPostCodeModal}
                />
            </div>
            : <></>
        }
    </>)
}