import { useState, useEffect, useCallback, useRef } from 'react'
import '../../css/SelectDesign.scss'
import '../../css/SelectDesignInstagram.scss'
import '../../css/Common.scss'
import { Option } from '../../components/OrderSequence/type'
import {
    CalculatePrice,
    FilterSortOption,
    CheckSoldOut,
    CalculateDesignMinPrice
} from '../../components/utility/utils'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithoutPrice } from '../../components/OrderSequence/PageElement/NextButton'
import {
    Title,
} from '../../components/OrderSequence/Format'
import RoundCheckButton from '../../components/OrderSequence/PageElement/RoundCheckButton'
const DesignRow = ({
    groupTitle,
    option,
    color1,
    color2,
    checkIsSelected
}: any) => {
    const { shopName, orderPk } = useParams();
    const nav = useNavigate();
    const isSoldOut = CheckSoldOut(option)
    const onClickImage = () => {
        if (isSoldOut) {
            return
        }
        sessionStorage.setItem('lastPosition', window.scrollY.toString())
        nav(`/order/${shopName}/${orderPk}/designDetail/${option.id}`)
    }

    const desc = option.desc
    const isSelected = checkIsSelected(option)
    const [elementHeight, setElementHeight] = useState<number>(0);
    const [elementWidth, setElementWidth] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setElementHeight(ref.current?.clientHeight as number)
        setElementWidth(ref.current?.clientWidth as number)
    }, [ref])

    let minPrice = CalculateDesignMinPrice(option)
    const imageSize = 95
    return (
        <>
            <div
                className="product-option"
                style={{
                    display: 'flex',
                    marginBottom: 48,
                    position: 'relative'
                }}
                ref={ref}
                onClick={onClickImage}
            >
                {isSoldOut ?
                    <div
                        style={{
                            zIndex: 3,
                        }}>
                        <div style={{
                            width: elementWidth - imageSize,
                            height: elementHeight,
                            position: "absolute",
                            left: imageSize,
                            top: 0,
                            backgroundColor: 'white',
                            opacity: 0.5
                        }}>
                        </div>
                        <div
                            className="sold-out-message"
                            style={{
                                position: 'absolute',
                                width: imageSize,
                                height: imageSize,
                                textAlign: 'center',
                                paddingTop: 45
                            }}>품절</div>
                    </div >
                    : null}
                <img
                    loading='lazy'
                    style={{
                        display: "block",
                        aspectRatio: '1/1',
                        height: imageSize,
                        width: imageSize,
                        objectFit: 'cover',
                        borderRadius: 10,
                        marginRight: 15,
                        filter: isSoldOut ? 'brightness(50%)' : 'brightness(100%)'
                    }}
                    src={option.example_photos[0].src}
                />
                <div
                    style={{
                        width: '100%',
                        height: 95,
                        display: 'flex',
                        marginRight: 20,
                        alignItems: 'start',
                        flexDirection: 'column',
                    }}
                >
                    <div className="product-title-row" >
                        <div className="product-title">{groupTitle}</div>
                        {'label' in option.information ?
                            <div className="product-label"
                                style={{
                                    color: color1,
                                    backgroundColor: color2
                                }}
                            >{option.information.label}</div> : null
                        }
                    </div>

                    <div className="product-desc">{desc}</div>
                    <div className="product-price" style={{ color: color1 }}>
                        {`${minPrice.toLocaleString("en-US")} 원 ~`}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: imageSize,
                    }}
                >
                    {isSelected ?
                        <RoundCheckButton />
                        : null}
                </div>
            </div>
        </>
    )
}
export default function SelectDesignChristmas() {
    let storage = sessionStorage
    let originPrice = CalculatePrice('design')
    let optionData = JSON.parse(storage.getItem('options') as string)
    const contentWidth = Number(storage.getItem('contentWidth'))
    const color1 = storage.getItem('color1') as string
    const color2 = storage.getItem('color2') as string
    const checkMode = storage.getItem('checkMode')
    const [isSetFinished, SetIsSetFinished] = useState(false)

    const [designOptions, setDesignOptions] = useState<any[]>([])
    const [designSelected, setDesignSelected] = useState<Option>()

    useEffect(() => {
        if (optionData && !isSetFinished) {
            const tempSort = FilterSortOption(optionData, 'design')
            setDesignOptions(tempSort)

            let tmp = JSON.parse(storage.getItem('design') as string)
            if (tmp) {
                setDesignSelected(tmp)
            } else {
                let initialOption = tempSort.find((item: Option) => !CheckSoldOut(item))
                setDesignSelected(initialOption)
            }
            SetIsSetFinished(true)
        }
    }, [optionData])


    // 제출 시 
    const nav = useNavigate();
    const { shopName, orderPk } = useParams();
    const onClickSubmit = () => {
        sessionStorage.removeItem('lastPosition')
        if (designSelected) {
            storage.setItem('design', JSON.stringify(designSelected))
            storage.setItem('images', JSON.stringify([designSelected.example_photos[0].src]))
            if (checkMode) {
                if (designSelected.next_point === "number") {
                    storage.removeItem("size")
                }
                if (designSelected.next_point === "size") {
                    storage.removeItem("number")
                }
                nav(`/order/${shopName}/${orderPk}/Check`)
            } else {
                nav(`/order/${shopName}/${orderPk}/${designSelected.next_point}`)
            }
        }
    }
    const checkIsSelected = useCallback((option: any) => {
        if (designSelected) {
            return (option.name === designSelected.name && option.option_group === designSelected.option_group)
        }
        return false
    }, [designSelected]
    )
    const onLoadMain = () => {
        let lastPosition = Number(sessionStorage.getItem('lastPosition'))
        if (lastPosition) {
            window.scrollTo({ top: lastPosition, behavior: "instant" as ScrollBehavior })
            window.history.replaceState({}, document.title)
        }
    }

    return <>
        <ProgressBar
            checkMode={checkMode}
            contentWidth={contentWidth} orderIndex={3} />
        <div
            style={{
                width: contentWidth,
                textAlign: 'center' as 'center',
            }}
        >
            <Title
                contentWidth={contentWidth}
                title="디자인 선택"
                subTitle="원하는 디자인을 선택해주세요"
            />

            <div
                style={{
                    width: contentWidth,
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginTop: 40,
                    marginBottom: 150,
                }}
                onLoad={onLoadMain}
            >
                {
                    designOptions?.map((option: Option) => (
                        <DesignRow
                            key={option.name + option.option_group}
                            groupTitle={option.name}
                            color1={color1}
                            color2={color2}
                            option={option}
                            checkIsSelected={checkIsSelected}
                        />))
                }
            </div>

            {/* <NextButtonWithoutPrice
                contentWidth={contentWidth}
                buttonDisabled={buttonDisabled}
                onClickSubmit={onClickSubmit}
                color1={color1}
                checkMode={checkMode}
            /> */}

        </div>
    </>
}