import { useState, useEffect } from 'react'
import { Option } from '../../type'
import { OptionContent } from './OptionContent'
const DesingFreeNoti = () => {
  return (
    <p className="category-desc">
      생각하시는 사진이나 도면이 있을 경우 이미지를 <br />
      <b>직접 업로드</b> 해주세요 없을 경우 <b>샘플에서 골라</b>
      주세요
    </p>
  )
}
interface DesignContentProps {
  isDesignFreeAllowed: Boolean
  exampleImages: string[]
  design: Option
}
export const DesignContent = ({
  isDesignFreeAllowed,
  exampleImages,
  design,
}: DesignContentProps) => {
  const [isDesignFree, setIsDesignFree] = useState(false)
  useEffect(() => {
    if (design && design.name === '자유형') {
      setIsDesignFree(true)
    }
  }, [design])
  let designName = ''
  if (design) {
    if (design.option_group) {
      designName = `${design.option_group} ${design.name}`
    } else {
      designName = `${design.name}`
    }
  }
  let designPageType = JSON.parse(
    sessionStorage.getItem('information') as string,
  ).designPageType

  return (
    <>
      {isDesignFreeAllowed ? (
        isDesignFree ? (
          <>
            <DesingFreeNoti />
            <OptionContent
              optionType="Photo"
              message="직접 업로드 할게요"
              imgs={exampleImages}
              price={-1}
            />
            <OptionContent
              optionType="DesignOption"
              message="샘플에서 고를게요"
              imgs={[]}
              price={-1}
            />
          </>
        ) : (
          <>
            <DesingFreeNoti />
            <OptionContent
              optionType="Photo"
              message="직접 업로드 할게요"
              imgs={[]}
              price={-1}
            />
            <OptionContent
              optionType="DesignOption"
              message="샘플에서 고를게요"
              imgs={exampleImages}
              price={-1}
            />
          </>
        )
      ) : (
        <OptionContent
          optionType={designPageType}
          message={designName}
          imgs={exampleImages}
          price={design?.value}
        />
      )}
    </>
  )
}
