import { Option } from '../../components/OrderSequence/type'
import { useState, useEffect } from 'react'
type Options = Option[]

class ProductCounter {
  product: Option
  count: number
  packageOption: Option
  defaultPackageOption: Option
  constructor(product: Option, count: number, packageOption: Option) {
    this.product = product
    this.count = count
    this.packageOption = packageOption
    this.defaultPackageOption = packageOption
  }
  plus() {
    this.count += 1
  }
  minus() {
    this.count -= 1
  }
  toJson() {
    return {
      product: this.product,
      count: this.count,
      package: this.packageOption,
    }
  }
  reset() {
    this.count = 0
    this.packageOption = this.defaultPackageOption
  }
  setPackageOption(packageOption: Option) {
    this.packageOption = packageOption
  }
}
export class ProductCalculator {
  products
  productCounters: ProductCounter[]
  productGroups: any
  constructor(products: Options, packageOptions: Options) {
    let defaultPackage = packageOptions.find(
      (option: Option) => option.value === 0,
    )
    this.products = products
    this.productCounters = []

    products.map((option: Option) => {
      let productCounter = new ProductCounter(
        option,
        0,
        defaultPackage as Option,
      )
      this.productCounters.push(productCounter)
    }, [])
    this.productGroups = products.reduce((acc: any, curr: Option) => {
      const { option_group } = curr
      if (acc[option_group]) {
        acc[option_group].push(curr)
      } else {
        acc[option_group] = [curr]
      }
      return acc
    }, {})
  }
  getGroupMinMaxPrice(groupName: string) {
    let productGroup = this.productGroups[groupName]
    let prices: number[] = []
    productGroup.forEach((product: Option) => {
      prices.push(product.value)
    })
    return { minPrice: Math.min(...prices), maxPrice: Math.max(...prices) }
  }
  getGroupCount(groupName: string) {
    let productGroup = this.productGroups[groupName]
    let count = 0
    productGroup.forEach((product: Option) => {
      let productCounter = this.getCounter(product)
      if (productCounter) {
        count += productCounter.count
      }
    })
    return count
  }
  getTotalPrice() {
    let price = 0
    this.productCounters.forEach((counter: ProductCounter) => {
      if (counter.product) {
        
        price +=
          counter.count * counter.product.value + counter.packageOption.value
      }
    })
    return price
  }
  getTotalCount() {
    let count = 0
    this.productCounters.forEach((counter: ProductCounter) => {
      count += counter.count
    })
    return count
  }
  getGroupPackage(groupName: string) { 
    let productGroup = this.productGroups[groupName]
    let productCounter = this.getCounter(productGroup[0])
    if (productCounter) {
      return productCounter.packageOption
    }
    throw new Error("package가 존재하지 않습니다.") 
  }
  getGroupPrice(groupName: string) {
    let productGroup = this.productGroups[groupName]
    let price = 0
    productGroup.forEach((product: Option) => {
      let productCounter = this.getCounter(product)
      if (productCounter) {
        price += productCounter.count *( productCounter.product.value +  productCounter.packageOption.value)
      }
    })
    return price
  }
  getCounter(product: Option) {
    return this.productCounters.find(
      productCounter => productCounter.product.name === product.name,
    )
  }
  setPackage(groupName: string, packageOption: Option) {
    let productGroup = this.productGroups[groupName]
    productGroup.forEach((product: Option) => {
      let counter = this.getCounter(product)
      if (counter) {
        counter.setPackageOption(packageOption)
      }
    })
  }
  deleteProduct(product: Option) {
    let counter = this.getCounter(product)
    if (counter) counter.reset()
  }
  toJson() {
    let res: {}[] = []
    this.productCounters.forEach(counter => {
      res.push(counter.toJson())
    })
    return res
  }
  fromJson(json: any) {
    json.forEach((menu: any) => {
      let counter = this.getCounter(menu.product)
      if (counter) {
        counter.count = menu.count
        counter.packageOption = menu.package
      }
    })
  }
}

export function CalculatorFromJson(json: any) {
  let packageOptions = JSON.parse(
    sessionStorage.getItem('packageOptions') as string,
  )
  const options: Options = []
  json.forEach((menu: any) => {
    options.push(menu.product)
  })
  let calculator = new ProductCalculator(
    options as Options,
    packageOptions as Options,
  )
  calculator.fromJson(json)
  return calculator
}
export function JsonToMessage(json: any) {
  let message = ''
  let price = 0
  json.forEach((menu: any) => {
    if (menu.count !== 0) {
      if (menu.package) {
        let menuPrice = menu.count * menu.product.value + menu.package.value
        message += `${menu.product.name} ${menu.count}개 ${menu.package.name} ${menuPrice}원 \n`
        price += menuPrice
      } else {
        let menuPrice = menu.count * menu.product.value
        message += `${menu.product.name} ${menu.count}개 포장 없음 ${
          menu.count * menu.product.value
        } \n`
        price += menuPrice
      }
    }
  })
  message += `총액 ${price}`
  return message
}
