
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export function MenuGroupCarousel({
    designOptionGrouped,
    checkGroupSelected,
    onClickCarouselButton,
    contentWidth,
}: any) {

    const settings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    }

    return (
        <div
            className="menu-group-carousel"
            style={{
                width: contentWidth,
                height: 55
            }}
        >
            <Slider
                style={{ width: contentWidth - 50, height: '45px' }}
                {...settings}
            >
                {designOptionGrouped ? Object.keys(designOptionGrouped).map((designOptionGroupName: any) => (
                    <div key={designOptionGroupName}>
                        <div
                            className={
                                checkGroupSelected(designOptionGroupName)
                                    ? 'design-button-active'
                                    : 'design-button-inactive'
                            }
                            onClick={() => onClickCarouselButton(designOptionGroupName)}
                        >
                            {designOptionGroupName}
                        </div>
                    </div>
                )) : null}
            </Slider>
        </div>
    )
}
export default function MenuCarousel({
    designOption,
    designSelected,
    onClickButton,
    contentWidth,
}: any) {
    const settings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    }

    return (
        <div
            className="menu-carousel"
            style={{
                width: contentWidth,
            }}
        >
            <Slider
                style={{ width: contentWidth - 50, height: '45px' }}
                {...settings}
            >
                {designOption?.map((option: any) => (
                    <div key={option.name}>
                        <div
                            className={
                                designSelected && option.name === designSelected.name
                                    ? 'design-button-active'
                                    : 'design-button-inactive'
                            }
                            onClick={() => onClickButton(option)}
                        >
                            {option.name}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}