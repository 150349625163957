import { Title } from '../../components/OrderSequence/Format'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import '../../css/SelectProduct.scss'
import { useState, useEffect } from 'react'
import { FilterSortOption, MoveToTop } from '../../components/utility/utils'
import { NextButtonWithPriceProduct } from '../../components/OrderSequence/PageElement/NextButton'
import {
  CalculatorFromJson,
  ProductCalculator,
} from '../../components/utility/calculator'
import { useNavigate, useParams } from 'react-router-dom'

const ProductMode = ({
  contentWidth,
  productCalculator,
  buttonDisabled,
  onClickSubmit,
  color1,
  checkMode,
  onClickProductGroup,
}: any) => {
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    if (productCalculator) {
      setTotalPrice(productCalculator.getTotalPrice())
      setTotalCount(productCalculator.getTotalCount())
    }
  }, [productCalculator])

  return (
    <>
      <div
        style={{
          width: contentWidth,
          textAlign: 'center' as 'center',
        }}
      >
        <Title
          contentWidth={contentWidth}
          title="종류 선택"
          subTitle="원하시는 상품을 담아주세요."
        />
      </div>
      <div
        style={{
          width: contentWidth,
          paddingLeft: 25,
          paddingRight: 25,
          marginTop: 40,
        }}
      >
        {productCalculator
          ? Object.keys(productCalculator.productGroups).map((key: any) => {
            return (
              <ProductOption
                contentWidth={contentWidth}
                key={key}
                productCalculator={productCalculator}
                onClickProductGroup={onClickProductGroup}
                groupTitle={key}
                color1={color1}
                optionGroup={productCalculator.productGroups[key]}
              />
            )
          })
          : null}
        <NextButtonWithPriceProduct
          count={totalCount}
          estimatePrice={totalPrice}
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          color1={color1}
          checkMode={checkMode}
        />
      </div>
    </>
  )
}

const ProductOption = ({
  contentWidth,
  productCalculator,
  groupTitle,
  optionGroup,
  onClickProductGroup,
  color1,
}: any) => {
  const src = optionGroup[0].example_photos[0].src
  const desc = optionGroup[0].desc
  const isParcel = 'parcel' in optionGroup[0].information
  const groupName = optionGroup[0].option_group
  const { minPrice, maxPrice } =
    productCalculator.getGroupMinMaxPrice(groupName)
  const count = productCalculator.getGroupCount(groupName)
  return (
    <>
      <div
        className="product-option"
        onClick={() => {
          onClickProductGroup(optionGroup)
        }}
        style={{
          display: 'flex',
          marginBottom: 48,

        }}
      >
        <img
          style={{
            height: '110px',
            width: '110px',
            objectFit: 'cover',
            borderRadius: 10,
            marginRight: 15,
          }}
          src={src}
        />
        <div
          style={{
            width: 175,
            height: 92,
            display: 'flex',
            marginRight: 20,
            flexDirection: 'column',
          }}
        >
          <div className="product-title-row" >
            <div className="product-title">{groupTitle}</div>
            {isParcel ? <div className="product-parcel">택배가능</div> : null}
          </div>
          <div className="product-desc">{desc?.substr(0, 20)}</div>
          <div className="product-price" style={{ color: color1 }}>
            {`${minPrice.toLocaleString('en-US')} ~ ${maxPrice.toLocaleString(
              'en-US',
            )}`}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 110,
          }}
        >
          {count !== 0 ? (
            <div
              style={{
                background: color1,
                border: 'none',
              }}
              className="count-product"
            >
              <div>
                {count}
              </div>
            </div>
          ) : (
            <div className="add-product">
              <div>
                +
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default function SelectProduct() {
  let storage = sessionStorage
  const contentWidth = Number(storage.getItem('contentWidth'))
  let optionData = JSON.parse(storage.getItem('options') as string)
  const color1 = storage.getItem('color1') as string
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isSetFinished, SetIsSetFinished] = useState(false)

  const [productCalculator, setProductCalculator] =
    useState<ProductCalculator>()

  useEffect(() => {
    if (optionData && !isSetFinished) {
      const packageOptions = FilterSortOption(optionData, 'package')
      storage.setItem('packageOptions', JSON.stringify(packageOptions))

      const tempSort = FilterSortOption(optionData, 'product')
      let calculatorJSON = JSON.parse(storage.getItem('calculator') as string)
      let calculator = null
      if (!calculatorJSON) {
        calculator = new ProductCalculator(tempSort, packageOptions)
      } else {
        calculator = CalculatorFromJson(calculatorJSON)
      }
      setProductCalculator(calculator)
      MoveToTop()
      SetIsSetFinished(true)
    }
  }, [optionData])
  useEffect(() => {
    if (productCalculator) {
      if (productCalculator.getTotalCount() === 0) {
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    }
  }, [productCalculator])
  const checkMode = storage.getItem('checkMode')
  const nav = useNavigate()
  const onClickProductGroup = (productGroup: any) => {
    storage.setItem('calculator', JSON.stringify(productCalculator?.toJson()))
    storage.setItem('productGroup', JSON.stringify(productGroup))
    nav(`/order/${shopName}/${orderPk}/Product/Select`)
  }

  const { shopName, orderPk } = useParams()
  const onClickSubmit = () => {
    if (productCalculator) {
      storage.setItem('calculator', JSON.stringify(productCalculator?.toJson()))
    }
    nav(`/order/${shopName}/${orderPk}/Date`)
  }
  return (
    <>
      <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={1} />

      <ProductMode
        contentWidth={contentWidth}
        productCalculator={productCalculator}
        buttonDisabled={buttonDisabled}
        onClickSubmit={onClickSubmit}
        color1={color1}
        checkMode={checkMode}
        onClickProductGroup={onClickProductGroup}
      />
      <div style={{ width: contentWidth, height: 130 }}></div>
    </>
  )
}
