import "../../../css/PageElement.scss"
export default function RoundCheckButton(
    { onClick }: any
) {
    return (<button
        className="check-button"
        onClick={onClick}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M2 7.11116L6.44444 11.5557L14 4" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </svg>
    </button>)
}