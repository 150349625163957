import { sendSlackMessage } from '../../api'
import { Title } from '../../components/OrderSequence/Format'
import InstructionRow from '../../components/OrderSequence/PageElement/Kakao/KakaoInstructionRow'
import KakaoPingPong from '../../components/OrderSequence/PageElement/Kakao/KakaoPingPong'
import { slackMessageToKakao } from '../../constants/slackMessage'
import '../../css/ToKakao.scss'
export default function ToKakao() {
  let storage = sessionStorage
  storage.getItem('')
  const kakaoLink = storage.getItem('outLink')
  const onClickButton = () => {
    sendSlackMessage(slackMessageToKakao)
    if (kakaoLink) {
      window.location.assign(kakaoLink)
    } else {
      throw new Error("카카오 링크가 존재하지 않습니다.")
    }
  }
  const shopName = JSON.parse(storage.getItem("shopData") as string).name_kr
  const contentWidth = Number(storage.getItem('contentWidth'))

  return (
    <div
      style={{
        width: contentWidth,
        background: '#fff',
      }}
    >
      <Title
        contentWidth={contentWidth}
        title={`${shopName} 카톡에 보내기`}
        subTitle="복사한 주문내용을 카톡으로 보내주세요"
        customMarginTop={35}
      />

      <div style={{
        marginLeft: 30,
        marginTop: 84
      }}>

        <InstructionRow
          no={1}
          isDone={true}
          message='주문을 작성하고 복사해요'
          subMessage={null}
          isLast={false}
          isAlert={false}
        />
        <InstructionRow
          no={2}
          isDone={false}
          message='카톡으로 붙여넣기 해주세요'
          subMessage='보내지 않으면 주문이 접수되지 않아요'
          isLast={false}
          isAlert={true}
          ExpressElement={KakaoPingPong}
        />
        <InstructionRow
          no={3}
          isDone={false}
          message='접수 끝! 조금만 기다려주세요'
          subMessage='사장님이 확인 후 카톡으로 입금 안내를 드려요'
          isLast={true}
          isAlert={false}
        />
      </div>

      <button
        onClick={onClickButton}
        style={{
          width: contentWidth - 50,
          background: '#FEE500',
          position: 'fixed',
          height: 55,
          left: 25,
          bottom: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        id="button_finish"
        className="button_next_square_float"
      >
        <img src="/kakao_outline.svg" width={23} height={21} />
        <p
          id="button_finish_p"
          style={{
            marginLeft: 7,
            color: '#381F1F',
            fontSize: 16,
            fontFamily: 'Apple SD Gothic Neo',
          }}
          className="button_text_main"
        >
          {'카톡열고 보내기'}
        </p>
      </button>
    </div>
  )
}
