import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  changeOrderSheetBlockedDay,
  changeOrderSheetTimeRange,
} from '../../../api'
import { useMutation } from '@tanstack/react-query'
import { parseTime } from '../../../components/utility/utils'
import { AdminModalLayout, ModalLayout } from '../../../components/FormatModal'
import '../../../css/ShopAdmin/SettingPages/PickupSetting.scss'
import PickupTimeRangeSelector from '../../../components/ShopAdmin/SettingPages/PickupSettings/PickupTimeRangeSelector'
import BlockedDaySelector from '../../../components/ShopAdmin/SettingPages/PickupSettings/BlockedDaySelector'

export default function PickupSettings() {
  let storage = sessionStorage
  let contentWidth = Number(storage.getItem('contentWidth')) - 40
  const orderPk = storage.getItem('orderPk')
  const originalStartTime = parseTime(
    storage.getItem('startTime') as string,
  ).format('HH:mm')
  const originalEndTime = parseTime(
    storage.getItem('endTime') as string,
  ).format('HH:mm')
  const nav = useNavigate()
  const originalBlockedDays = storage.getItem('blockedDay')

  const [selectedStartTime, setSelectedStartTime] = useState(originalStartTime)
  const [selectedEndTime, setSelectedEndTime] = useState(originalEndTime)

  const [isStartTimeDropdownVisible, setStartTimeDropdownVisibility] =
    useState(false)
  const [isEndTimeDropdownVisible, setEndTimeDropdownVisibility] =
    useState(false)

  const [selectedBlockedDays, setSelectedBlockedDays] = useState<number[]>([])

  const [areTimesSelected, setAreTimesSelected] = useState(false)
  const [areBlockedDaysSelected, setAreBlockedDaysSelected] = useState(false)

  const [closeModalIsOpen, setCloseModalIsOpen] = useState(false)
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false)

  const onClickFinishForCloseModal = () => {
    nav(-1)
    setCloseModalIsOpen(false)
  }

  const onClickFinishForSaveModal = () => {
    nav(-1)
  }

  const onClickX = () => {
    if (areTimesSelected || areBlockedDaysSelected) {
      setCloseModalIsOpen(true)
    } else {
      nav(-1)
    }
  }

  const handleStartTimeClick = () => {
    setStartTimeDropdownVisibility(!isStartTimeDropdownVisible)
  }

  const handleEndTimeClick = () => {
    setEndTimeDropdownVisibility(!isEndTimeDropdownVisible)
  }

  const handleStartTimeSelection = (time: string) => {
    setSelectedStartTime(time)
    setStartTimeDropdownVisibility(false)
  }

  const handleEndTimeSelection = (time: string) => {
    setSelectedEndTime(time)
    setEndTimeDropdownVisibility(false)
  }

  useEffect(() => {
    if (
      selectedStartTime !== originalStartTime ||
      selectedEndTime !== originalEndTime
    ) {
      setAreTimesSelected(true)
    } else {
      setAreTimesSelected(false)
    }

    const originalBlockedDaysArray: number[] = originalBlockedDays
      ? JSON.parse(originalBlockedDays)
      : []

    const sortedSelectedBlockedDays = [...selectedBlockedDays].sort()
    const sortedOriginalBlockedDaysArray = [...originalBlockedDaysArray].sort()

    if (
      JSON.stringify(sortedSelectedBlockedDays) !==
      JSON.stringify(sortedOriginalBlockedDaysArray)
    ) {
      setAreBlockedDaysSelected(true)
    } else {
      setAreBlockedDaysSelected(false)
    }
  }, [selectedStartTime, selectedEndTime, selectedBlockedDays])

  const { shopUuid } = useParams()

  const changeTimeRangeMutation = useMutation(changeOrderSheetTimeRange, {
    onError: (data: any) => {
      alert(data)
    },
  })

  const changeBlockedDayMutation = useMutation(changeOrderSheetBlockedDay, {
    onError: (data: any) => {
      alert(data)
    },
  })

  const handleSaveClick = () => {
    if (areTimesSelected) {
      changeTimeRangeMutation.mutate({
        pk: orderPk,
        shopUuid: shopUuid,
        start_time: selectedStartTime,
        end_time: selectedEndTime,
      })
      setSaveModalIsOpen(true)
    }
    if (areBlockedDaysSelected) {
      changeBlockedDayMutation.mutate({
        pk: orderPk,
        shopUuid: shopUuid,
        blocked_day: selectedBlockedDays,
      })
      setSaveModalIsOpen(true)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'stretch',
      }}
    >
      <div
        style={{
          marginLeft: 20,
          marginTop: 30,
          display: 'flex',
          justifyContent: 'space-between',
          width: contentWidth,
        }}
      >
        <div className="title">주문서 설정</div>
        <button onClick={onClickX}>
          <img
            style={{
              height: 15.8,
              width: 15.8,
            }}
            src="/x.png"
          />
        </button>
        <AdminModalLayout
          modalIsOpen={closeModalIsOpen}
          setModalIsOpen={setCloseModalIsOpen}
          onClickFinish={onClickFinishForCloseModal}
        ></AdminModalLayout>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginTop: 45,
          width: contentWidth,
        }}
      >
        <div className="sub-title">픽업시간 설정</div>
        <div>
          <PickupTimeRangeSelector
            contentWidth={contentWidth}
            selectedStartTime={selectedStartTime}
            handleStartTimeClick={handleStartTimeClick}
            handleStartTimeSelection={handleStartTimeSelection}
            isStartTimeDropdownVisible={isStartTimeDropdownVisible}
            selectedEndTime={selectedEndTime}
            handleEndTimeClick={handleEndTimeClick}
            handleEndTimeSelection={handleEndTimeSelection}
            isEndTimeDropdownVisible={isEndTimeDropdownVisible}
          />
        </div>
        <BlockedDaySelector
          selectedBlockedDays={selectedBlockedDays}
          setSelectedBlockedDays={setSelectedBlockedDays}
        />
      </div>
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginLeft: 20,
          width: contentWidth,
        }}
      >
        <button
          onClick={handleSaveClick}
          disabled={!areTimesSelected && !areBlockedDaysSelected}
          className="save-button"
          style={{
            width: contentWidth,
            background:
              areTimesSelected || areBlockedDaysSelected
                ? '#0baa5d'
                : '#B8B8B8',
          }}
        >
          저장
        </button>
        <ModalLayout
          title="주문서 설정 변경 완료"
          subTitle="고객에게도 변경된 내용으로 보여집니다"
          modalIsOpen={saveModalIsOpen}
          onClickFinish={onClickFinishForSaveModal}
        ></ModalLayout>
      </div>
    </div>
  )
}
