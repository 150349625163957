import { useNavigate, useParams } from "react-router-dom"
import "../../css/DesignDetail.scss"
import { CalculateDesignMinPrice } from "../../components/utility/utils";
export default function DesignDetail() {
    const { shopName, orderPk, designPk } = useParams();
    const storage = sessionStorage;
    let optionData = JSON.parse(storage.getItem('options') as string)
    const color1 = storage.getItem('color1')

    let designOption = optionData.find((option: any) => option.id === Number(designPk))
    let minPrice = CalculateDesignMinPrice(designOption)

    const contentWidth = Number(storage.getItem("contentWidth"))
    const nav = useNavigate()

    const designPageType = JSON.parse(storage.getItem("information") as string).designPageType
    const onClickBack = () => {
        nav(`/order/${shopName}/${orderPk}/${designPageType}`, { state: { prevPath: 'DesignDetail' } })
    }
    const checkMode = storage.getItem('checkMode')
    const onClickButton = () => {
        if (designOption) {
            storage.setItem('design', JSON.stringify(designOption))
            storage.setItem('images', JSON.stringify([designOption.example_photos[0].src]))
            if (checkMode) {
                if (designOption.next_point === "number") {
                    storage.removeItem("size")
                }
                if (designOption.next_point === "size") {
                    storage.removeItem("number")
                }
                nav(`/order/${shopName}/${orderPk}/Check`)
            }
            nav(`/order/${shopName}/${orderPk}/${designOption.next_point}`)
        }
    }
    const DivideDesc = (str: string) => {

        if (str && str.includes('_')) {
            const strList = str.split('_')
            return (
                <div>
                    {strList[0]}
                    <br />
                    {strList[1]}
                </div>
            )
        }
        return str
    }
    return (<div
        onLoad={() => {
            window.scrollTo({ top: 0 })
        }}
        style={{
            width: contentWidth,
        }}>
        <div
            className="top-gradient"
            style={{
                position: "fixed",
                width: contentWidth,
                height: "70px",
                zIndex: 2
            }}></div>
        <div
            onClick={onClickBack}
            style={{
                zIndex: 3,
                position: "fixed",
                top: "20px",
                left: "20px"
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path d="M11 1L2 10.5L11 20" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
        <img
            style={{
                height: contentWidth,
                width: contentWidth,
                objectFit: "cover"
            }}
            src={designOption.example_photos[0].src} alt="" />
        <div
            style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "24px",
                paddingBottom: "60px"
            }}>
            {designOption ?
                <div>
                    <div className="design-detail-title">
                        <div>{designOption.name}</div>
                        {'label' in designOption.information ?
                            <div>*{designOption.information.label}</div> : null
                        }

                    </div>
                    <div className="design-detail-desc">
                        <div style={{ color: color1 as string }}>{minPrice.toLocaleString('en-US')}원 부터</div>
                        {DivideDesc(designOption.desc)}

                    </div>
                </div>
                : null
            }
        </div>
        <div
            style={{
                width: '100%',
                display: 'flex',
                paddingLeft: 20,
                paddingRight: 20,
            }}>
            <button
                style={{
                    width: '100%',
                    background: "#eaeaea",
                    marginBottom: 25,
                    marginRight: 9
                }}
                className="close-button"
                onClick={onClickBack}>닫기</button>
            <button
                onClick={onClickButton}
                className="close-button"
                style={{
                    color: 'white',
                    width: '100%',
                    background: color1 as string, marginBottom: 25
                }}
            >
                <span>{'케이크 선택하기'}</span>
            </button>
        </div>
    </div>)
}