import { Option } from '../../components/OrderSequence/type'
import {
  CalculatorFromJson,
  ProductCalculator,
} from '../../components/utility/calculator'
import "../../css/Buttons.scss"
import { useState, useEffect } from 'react'
import { NextButtonProductCount } from '../../components/OrderSequence/PageElement/NextButton'
import { useNavigate, useParams } from 'react-router-dom'
import ProductPhotoSlider from '../../components/OrderSequence/PageElement/ProductPhotoSlider'
import { Divider } from '../../components/ProductSequence/Format'
type CountModeProp = {
  productCalculator: ProductCalculator
  contentWidth: number
  productGroupSelected: Option[]
  setViewMode: any
  color1: string
}
const OptionCountInput = ({
  countChanged,
  setCountChanged,
  productCalculator,
  contentWidth,
  product,
  isLast,
  color1
}: any) => {
  const [counter, setCounter] = useState<any>()
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (productCalculator) {
      setCounter(productCalculator.getCounter(product))
    }
  }, [productCalculator])
  useEffect(() => {
    if (productCalculator) {
      setCount(productCalculator.getCounter(product).count)
    }
  }, [productCalculator])
  const onClickMinus = () => {
    counter.minus()
    setCount(counter.count)
    setCountChanged(!countChanged)
  }
  const onClickPlus = () => {
    counter.plus()
    setCount(counter.count)
    setCountChanged(!countChanged)
  }

  const optionName = product.name
  return (
    <div
      style={
        isLast
          ? {
            height: 32,
            width: contentWidth - 40,
            display: 'flex',
            justifyContent: 'space-between',
          }
          : {
            height: 47,
            width: contentWidth - 40,
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px #EBEBEB solid',
            marginBottom: 15,
          }
      }
    >
      <div
        className="option-name"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>{optionName}</div>
        <span
          style={{
            marginLeft: 5,
            marginRight: 5,
            color: '#000000',
            opacity: 0.2,
          }}
        >
          •
        </span>
        <div>{product.value.toLocaleString('en-us')}원</div>
      </div>
      {counter ? (
        <div className="option-counter" >
          <button onClick={onClickMinus} disabled={counter.count === 0}>
            <div>
              -
            </div>
          </button>
          <div
            className='option-count'
            style={count !== 0 ? {
              color: color1,
              fontWeight: 'bold'
            } : {
              color: "black",
              fontWeight: 'bold'
            }}>{count}</div>
          <button onClick={onClickPlus}>
            <div>
              +
            </div>
          </button>
        </div>
      ) : null}
    </div>
  )
}
export default function SelectProductDetail() {
  let storage = sessionStorage
  const productGroupSelected = JSON.parse(
    storage.getItem('productGroup') as string,
  )
  const contentWidth = Number(storage.getItem('contentWidth'))
  const color1 = storage.getItem('color1') as string
  const productCalculatorJSON = JSON.parse(
    storage.getItem('calculator') as string,
  )
  const [groupName, setGroupName] = useState('')
  const [groupDesc, setGroupDesc] = useState('')
  const [groupIsPaecel, setGroupIsParcel] = useState(false)
  let packageOptions = JSON.parse(storage.getItem('packageOptions') as string)
  const [isSetFinished, SetIsSetFinished] = useState(false)
  const [packageSelected, setPackageSelected] = useState(packageOptions[0])


  const onClickPackage = () => {
    // setPrice(draftPrice + option.value)
    let optionChanged = packageOptions.find((packageOption: any) => packageOption.name !== packageSelected.name)
    setPackageSelected(optionChanged)
    productCalculator?.setPackage(groupName, optionChanged)
  }
  useEffect(() => {
    if (productGroupSelected) {
      setGroupName(productGroupSelected[0].option_group)
      setGroupDesc(productGroupSelected[0].desc)
      setGroupIsParcel('parcel' in productGroupSelected[0].information)
    }
  }, [productGroupSelected])
  const [productCalculator, setProductCalculator] =
    useState<ProductCalculator>()
  const [calculatorLoaded, setCalculatorLoaded] = useState(false)
  useEffect(() => {
    if (productCalculatorJSON && !calculatorLoaded) {
      let calculator = CalculatorFromJson(productCalculatorJSON)
      setProductCalculator(calculator)
      setCalculatorLoaded(true)
    }
  }, [productCalculatorJSON])
  useEffect(() => {
    if (productCalculator && packageOptions && !isSetFinished) {
      const groupPackage = productCalculator.getGroupPackage(groupName)
      console.log(groupPackage)
      setPackageSelected(groupPackage)

      SetIsSetFinished(true)
    }
  }, [packageOptions, productCalculator])

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [countChanged, setCountChanged] = useState(false)
  const [message, setMessage] = useState('상품을 골라주세요')
  useEffect(() => {
    if (productCalculator) {
      let countSum = productCalculator.getGroupCount(groupName)
      if (countSum !== 0) {
        setButtonDisabled(false)
        setMessage(
          `총 금액 ${productCalculator
            .getGroupPrice(groupName)
            .toLocaleString('en-us')}원`,
        )
      } else {
        setButtonDisabled(true)
        setMessage('상품을 골라주세요')
      }
    }
  }, [countChanged, productCalculator, packageSelected])
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const checkMode = storage.getItem('checkMode')

  const onClickSubmit = () => {
    storage.setItem('calculator', JSON.stringify(productCalculator?.toJson()))
    if (checkMode) {
      nav(`/order/${shopName}/${orderPk}/Check`)
    } else {
      nav(`/order/${shopName}/${orderPk}/Product`)
    }
  }


  return (
    <div
      style={{
        width: contentWidth,
      }}
    >
      {/* 상단 이미지 */}
      <ProductPhotoSlider
        contentWidth={contentWidth}
        example_photos={productGroupSelected[0].example_photos}
      />
      <div style={{ marginLeft: 20 }}>
        <div className="count-mode-title">{groupName}</div>
        <div className="count-mode-desc">{groupDesc}</div>
        {groupIsPaecel ? (
          <div className="ask-parcel">
            택배발송 문의는 카카오톡으로 주세요{' '}
            <span
              onClick={() => {
                const kakaoLink = 'https://pf.kakao.com/_vuvyT'
                window.location.assign(kakaoLink)
              }}
            >
              문의하기
            </span>
          </div>
        ) : null}
      </div>

      <Divider contentWidth={contentWidth} />
      <div style={{ marginLeft: 20 }}>
        <div className="count-mode-title">수량 선택</div>
        {productGroupSelected?.map((product: any, index: number) => (
          <div key={product.name + index.toString()}>
            <OptionCountInput
              countChanged={countChanged}
              setCountChanged={setCountChanged}
              productCalculator={productCalculator}
              contentWidth={contentWidth}
              product={product}
              isLast={index + 1 === productGroupSelected.length}
              color1={color1}
            />
          </div>
        ))}
      </div>
      <Divider contentWidth={contentWidth} />
      <div style={{ marginLeft: 20, marginBottom: 100 }}>
        <div className="count-mode-title">포장 <span>(선택)</span></div>
        <div style={{
          width: "100%",
          justifyContent: 'space-between',
          display: "flex",
          paddingRight: 20
        }}>
          <div>
            <div style={{
              display: "flex",
              width: "100%"
            }}>
              <div>
                <div
                  className="option-name"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div>보자기 포장</div>
                  <span
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      color: '#000000',
                      opacity: 0.2,
                    }}
                  >
                    •
                  </span>
                  <div>5,000 원</div>
                </div>
              </div>
            </div>
            <div>리본 보자기 색상은 임의변동됩니다.</div>
          </div>

          <input
            onClick={onClickPackage}
            checked={packageSelected.name === "포장하기"}
            style={{ accentColor: color1 }}
            className='radio-button' type="radio" />
        </div>

      </div>
      <NextButtonProductCount
        contentWidth={contentWidth}
        buttonDisabled={buttonDisabled}
        onClickSubmit={onClickSubmit}
        color1={color1}
        checkMode={false}
        message={message}
      />
    </div >
  )
}
