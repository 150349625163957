import { Box, Img, Input, InputGroup, Text, VStack } from '@chakra-ui/react'
import { Form, Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  IUsernameLoginError,
  IUsernameLoginSuccess,
  IUsernameLoginVariables,
  userLogin,
} from '../../../api'
import Modal from 'react-modal'

interface IForm {
  username: string
  password: string
}

interface ISigninForm {
  username: string
  password: string
  password_check: string
  phone_number: string
  email: string
}

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IForm>()
  const queryClient = useQueryClient()
  const nav = useNavigate()
  const userLoginMutation = useMutation<
    IUsernameLoginSuccess,
    IUsernameLoginError,
    IUsernameLoginVariables
  >(userLogin, {
    onMutate: () => {},
    onSuccess: (data: any) => {
      const shopUuid = data.shop.uuid
      const shopName = data.shop.name
      const orderType = 'C'
      queryClient.refetchQueries(['me'])
      reset()
      nav(`/shopAdmin/${shopUuid}/${shopName}/${orderType}`)
    },
    onError: (error: any) => {
      console.log(error)
      setModalOpen(true)
      setErrorMessage(error.error)
    },
  })
  const [errorMessage, setErrorMessage] = useState('')
  const onSubmit = ({ username, password }: IForm) => {
    userLoginMutation.mutate({ username, password })
  }
  const [modalOpen, setModalOpen] = useState(false)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      textAlign: 'center' as 'center',
      width: 260,
      height: 167.94,
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
    },
  }
  const modalTitle = {
    display: 'inline-block',
    marginTop: 30,
    width: '100%',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    marginBottom: 10,
    fontSize: '18px',
    lineHeight: '22px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const modalSubTitle = {
    display: 'inline-block',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 10,
    fontSize: '15px',
    lineHeight: '18px',
    paddingLeft: 10,
    paddingRight: 10,
    /* or 125% */

    letterSpacing: '-0.02em',

    color: '#727272',
  }
  const descStyle = {
    marginBottom: 45,
    height: '16px',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  const buttonStyle = {
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    /* or 125% */
    marginTop: 30,

    letterSpacing: '-0.01em',

    color: '#FFFFFF',
    background: '#494949',

    width: '335px',
    height: '50px',
    borderRadius: '10px',
  }
  const buttonModalStyle = {
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    /* or 125% */
    marginTop: 30,

    width: customStyles.content.width - 30,
    height: 45,
    borderRadius: '8px',

    letterSpacing: '-0.01em',

    color: '#FFFFFF',
    background: '#494949',
  }
  return (
    <Box>
      <VStack alignContent={'center'}>
        <Link to={'/'}>
          <Img
            marginTop={'94.77px'}
            marginBottom={'10px'}
            height={45}
            width={200}
            alt="images"
            src={`/logo_black.png`}
          />
        </Link>
        <div style={descStyle}>사장님을 위한 편리한 주문서</div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            width={'335px'}
            height={'52px'}
            marginBottom={'10px'}
            isInvalid={Boolean(errors.username?.message)}
            {...register('username', { required: true })}
            variant={'outline'}
            placeholder="아이디"
          />
          <InputGroup>
            <Input
              width={'335px'}
              height={'52px'}
              isInvalid={Boolean(errors.password?.message)}
              {...register('password', { required: true })}
              variant={'outline'}
              placeholder="비밀번호"
              type="password"
            />
          </InputGroup>
          {userLoginMutation.isError ? (
            <Text color="red.500" textAlign={'center'} fontSize="sm">
              {' '}
              Username or Password are wrong
            </Text>
          ) : null}
          <button
            style={buttonStyle}
            disabled={userLoginMutation.isLoading}
            type="submit"
          >
            로그인{' '}
          </button>
        </Form>
      </VStack>
      <Modal isOpen={modalOpen} style={customStyles}>
        <div style={modalTitle}>
          아이디나 비밀번호가
          <br /> 올바르지 않습니다
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 15,
            left: 15,
          }}
        >
          <button
            style={buttonModalStyle}
            onClick={() => {
              setModalOpen(false)
            }}
          >
            확인
          </button>
        </div>
      </Modal>
    </Box>
  )
}
