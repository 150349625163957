import { useEffect, useState } from 'react'
import { AspectRatio } from '@chakra-ui/react'
import '../../../css/UserCalendar.css'
export default function CalendarAdminDay({
  onClickTargetDay,
  date,
  disabled,
  blocked,
  manualBlocked,
  timeBlocked,
}: any) {
  //  날짜
  const [dateRef, setDateRef] = useState(0)
  useEffect(() => {
    if (date) {
      setDateRef(date.get('date'))
    }
  }, [date])
  const manualBlockedStyle = {
    width: '26px',
    height: '26px',
    borderRadius: '13px',
    background: '#D3D3D3',

    fontFamily: 'SF Pro Display',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height */

    textAlign: 'center' as 'center',
    letterSpacing: '-0.02em',
    color: '#222222',
  }
  const timeBlockedStyle = {
    width: '28px',
    height: '28px',
    borderRadius: '13px',
    border: '1px dashed #BABABA',

    fontFamily: 'SF Pro Display',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    /* identical to box height */

    textAlign: 'center' as 'center',
    letterSpacing: '-0.02em',
    color: '#222222',
  }
  return (
    <AspectRatio width={'100%'} ratio={1 / 1}>
      <div
        style={{
          justifyItems: 'center',
          width: '100%',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!disabled && !blocked) {
            onClickTargetDay(date, manualBlocked)
          }
        }}
      >
        {blocked && !disabled ? (
          <div className="block_day">
            {dateRef}
            <p>휴일</p>
          </div>
        ) : !disabled && manualBlocked ? (
          <div style={manualBlockedStyle}>{dateRef}</div>
        ) : !disabled && timeBlocked ? (
          <button style={timeBlockedStyle} className="day">
            {dateRef}
          </button>
        ) : (
          <button disabled={disabled} className="day">
            {dateRef}
          </button>
        )}
      </div>
    </AspectRatio>
  )
}
