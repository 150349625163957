import { useState, useEffect, useCallback, } from 'react'

import '../../css/SelectDesign.scss'
import '../../css/SelectDesignInstagram.scss'
import '../../css/Common.scss'
import { Option } from '../../components/OrderSequence/type'
import {
    CalculatePrice,
    FilterSortOption,
} from '../../components/utility/utils'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithoutPrice } from '../../components/OrderSequence/PageElement/NextButton'
import {
    Title,
} from '../../components/OrderSequence/Format'
import DesignCard from '../../components/OrderSequence/PageLayout/DesignCard'
import DesignCardNarrow from '../../components/OrderSequence/PageLayout/DesignCardNarrow'

export default function SelectDesignScrollNarrow() {
    let storage = sessionStorage
    let originPrice = CalculatePrice('design')
    let optionData = JSON.parse(storage.getItem('options') as string)
    const contentWidth = Number(storage.getItem('contentWidth'))
    const color1 = storage.getItem('color1') as string
    const checkMode = storage.getItem('checkMode')

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [isSetFinished, SetIsSetFinished] = useState(false)

    const [designOptions, setDesignOptions] = useState<any[]>([])
    const [designSelected, setDesignSelected] = useState<Option>()
    useEffect(() => {
        if (optionData && !isSetFinished) {
            const tempSort = FilterSortOption(optionData, 'design')
            setDesignOptions(tempSort)
            setButtonDisabled(false)
            let tmp = JSON.parse(storage.getItem('design') as string)
            if (tmp) {
                setDesignSelected(tmp)
            } else {
                setDesignSelected(tempSort[0])
            }
            SetIsSetFinished(true)
        }
    }, [optionData])
    const onClickButton = useCallback((option: Option) => {
        setDesignSelected(option)
        setButtonDisabled(false)
    }, []);


    // 제출 시 
    const nav = useNavigate();
    const { shopName, orderPk } = useParams();
    const onClickSubmit = () => {
        if (designSelected) {
            storage.setItem('design', JSON.stringify(designSelected))
            storage.setItem('images', JSON.stringify([designSelected.example_photos[0].src]))
            if (checkMode) {
                nav(`/order/${shopName}/${orderPk}/Check`)
            } else {
                nav(`/order/${shopName}/${orderPk}/${designSelected.next_point}`)
            }
        }
    }
    const checkIsSelected = useCallback((option: any) => {
        if (designSelected) {
            return (option.name === designSelected.name && option.option_group === designSelected.option_group)
        }
        return false
    }, [designSelected]
    )

    return <>
        <ProgressBar
            checkMode={checkMode}
            contentWidth={contentWidth} orderIndex={3} />
        <div
            style={{
                width: contentWidth,
                textAlign: 'center' as 'center',
            }}
        >
            <Title
                contentWidth={contentWidth}
                title="상품 선택"
                subTitle="주문을 원하시는 상품을 선택해주세요"
            />
            <div
                style={{
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 150
                }}>
                {
                    designOptions?.map((option: Option) => {
                        return (
                            <DesignCardNarrow
                                checkIsSelected={checkIsSelected}
                                option={option}
                                onClickButton={onClickButton}
                            />
                        )
                    })
                }
            </div>
            <NextButtonWithoutPrice
                contentWidth={contentWidth}
                buttonDisabled={buttonDisabled}
                onClickSubmit={onClickSubmit}
                color1={color1}
                checkMode={checkMode}
            />
        </div>
    </>
}