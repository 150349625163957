export default function InstructionRow({
    no, isDone, message, subMessage, isLast, isAlert, ExpressElement
}: any) {

    return (
        <div>
            <div
                className="instruction-row">
                <div className={isDone ? "instruction-no-done" : "instruction-no"}>{no}</div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={isDone ? "instruction-message-done" : "instruction-message"}>{message}</div>
                        {isDone ? <div className="finish-label">완료</div> : null}
                    </div>
                    {subMessage ?
                        <div className={isAlert ? "instruction-alert-message" : "instruction-sub-message"}>{subMessage}</div>
                        : null}
                </div>
            </div>
            {isLast ? null :
                <div
                    style={{
                        marginLeft: 16,
                        minHeight: 15,
                        borderLeft: '2px solid #E3E3E3',
                        height: ExpressElement ? ExpressElement.height : 10
                    }}>
                    {ExpressElement ? <ExpressElement /> : null}
                </div>


            }
        </div >
    )
}