import { VStack, Button, Img, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function Home() {
  const descStyle = {
    marginBottom: 45,
    height: '16px',
    fontFamily: 'Apple SD Gothic Neo',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    /* or 125% */

    letterSpacing: '-0.01em',

    color: '#000000',
  }
  return (
    <VStack height={'100%'}>
      <Img
        marginTop={94.77}
        height={45}
        width={200}
        alt="images"
        src={`/logo_black.png`}
      />
      <div style={descStyle}>사장님을 위한 편리한 주문서</div>

      <Button>
        <Link to={'/shopAdminLogin'}>로그인 하러가기</Link>
      </Button>
    </VStack>
  )
}
