import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import AddressInput from "../../Address/AddressInput"
import { OptionContent } from "./OptionContent"
import QuickNoti from "../../PageElement/QuickNoti"
import { OptionTitle } from "./OptionTitle"
import { Address } from "../../Address/PostCode"
interface QuickDateTimeProps {
    date: Dayjs;
    quickTime: string;
    address: Address;
    setAddress: React.Dispatch<React.SetStateAction<Address>>;
    contentWidth: number;
    onChangeAddressDetail: Function;
}
interface NormalDateTimeProps {
    date: Dayjs;
    time: Dayjs;
}
const QuickDateTime = ({ date, quickTime, address, setAddress, contentWidth, onChangeAddressDetail }: QuickDateTimeProps) => {
    return (<>
        <OptionTitle title={'퀵 배달 희망시간'} mandatory={true} />
        <OptionContent
            optionType={'Date'}
            message={`${date.format('YYYY')}년 ${date.format('MM')} 월 ${date.format('DD')}일`}
            imgs={[]}
            price={-1}
        />
        <OptionContent
            optionType={'Time'}
            message={quickTime}
            imgs={[]}
            price={-1}
        />
        <AddressInput
            address={address}
            setAddress={setAddress}
            contentWidth={contentWidth}
            onChangeAddressDetail={onChangeAddressDetail}
        />
        <QuickNoti />
    </>)
}
const NormalDateTime = ({ date, time }: NormalDateTimeProps) => {
    const dateFormat = `${date.format('YYYY')}년 ${date.format('MM')} 월 ${date.format('DD')}일`
    const timeFormat = `${time.format('HH')}시 ${time.format('mm')}분`
    return (<>
        <OptionTitle title={'픽업할 날짜와 시간'} mandatory={true} />
        <OptionContent
            optionType={'Date'}
            message={dateFormat}
            imgs={[]}
            price={-1}
        />
        <OptionContent
            optionType={'Time'}
            message={timeFormat}
            imgs={[]}
            price={-1}
        />
    </>)
}

export const DateTime = ({ address, setAddress }: any) => {
    let storage = sessionStorage

    const date = dayjs(storage.getItem('date'))
    const time = dayjs(storage.getItem('time'))
    const contentWidth = Number(storage.getItem('contentWidth'))

    const quickRequired = Boolean(storage.getItem('quickRequired'))
    const quickTime = storage.getItem('quickTime') as string

    const onChangeAddressDetail = (e: any) => {
        setAddress({
            ...address,
            상세주소: e.target.value
        });
    }
    useEffect(() => {
        //조건문 없으면 새로고침할 때, address 가 초기화된다.
        if (address.기본주소 || address.상세주소) {
            storage.setItem("address", JSON.stringify(address))
        }
    }, [address])
    const [addressSetFinished, setAddressSetFinished] = useState(false);
    useEffect(() => {
        if (!addressSetFinished) {
            let _address = storage.getItem('address')
            if (_address) {
                setAddress(JSON.parse(_address as string))
            }
            setAddressSetFinished(true)
        }
    }, [])
    if (quickRequired) {
        return (<QuickDateTime
            date={date}
            quickTime={quickTime}
            address={address}
            setAddress={setAddress}
            contentWidth={contentWidth}
            onChangeAddressDetail={onChangeAddressDetail}
        />)
    } else {
        return (<NormalDateTime
            date={date}
            time={time} />)
    }
}