import { useParams, useNavigate } from 'react-router-dom'
export default function ClipboardCopyFailed() {
  const nav = useNavigate()
  let storage = sessionStorage
  const color1 = storage.getItem('color1') as string
  const contentWidth = Number(storage.getItem('contentWidth'))
  const { shopName, orderPk } = useParams()
  const clipBoardMessage = storage.getItem('clipBoardMessage')
  return (
    <div style={{ width: contentWidth }}>
      <div className="title-group" style={{ marginTop: 58 }}>
        <div>주문내용 복사</div>
        <div>아래 내용을 직접 복사해주세요</div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 60,
          left: 0,
          zIndex: 12,
          textAlign: 'center',
          justifyContent: 'center',
          width: contentWidth,
        }}
      >
        <pre
          className="manual-copy"
          style={{
            width: contentWidth - 50,
            textAlign: 'left',
          }}
        >
          {clipBoardMessage}
        </pre>

        <button
          onClick={() => {
            nav(`/order/${shopName}/${orderPk}/ToKaKao`)
          }}
          style={{
            width: contentWidth - 50,
            background: color1,
            position: 'fixed',
            height: 63,
            left: 25,
            bottom: 25,
          }}
          id="button_finish"
          className="button_next_square_float"
        >
          <p id="button_finish_p" className="button_text_main">
            복사를 완료했어요
          </p>
        </button>
      </div>
    </div>
  )
}
