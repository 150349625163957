import { useState, useEffect } from 'react'
import { FilterSortOption } from '../../components/utility/utils'
import HorizontalChoiceLayout from '../../components/OrderSequence/PageLayout/HorizontalChoice'

export default function SelectPackage() {
  let storage = sessionStorage
  let optionData = JSON.parse(storage.getItem('options') as string)

  const [isSetFinished, SetIsSetFinished] = useState(false)

  const [options, setOptions] = useState<any[]>([])
  useEffect(() => {
    if (optionData && !isSetFinished) {
      const tempSort = FilterSortOption(optionData, 'package')
      setOptions(tempSort)
      SetIsSetFinished(true)
    }
  }, [optionData])

  const progressOrder = 8
  const title = '포장 선택'
  const subTitle = '상자의 형태를 선택해주세요'
  const argument = 'package'
  return (
    <HorizontalChoiceLayout
      progressOrder={progressOrder}
      title={title}
      subTitle={subTitle}
      argument={argument}
      options={options}
    />
  )
}
