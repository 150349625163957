import '../../css/OrderCheck.scss'
import '../../css/Common.scss'
import { useState, useEffect } from 'react'
import { MoveToTop } from '../../components/utility/utils'
import { useMutation } from '@tanstack/react-query'
import { getUploadURL, uploadImage } from '../../api'
import html2canvas from 'html2canvas'
import { Loading } from '../../components/OrderSequence/PageLayout/Loading'
import CopyClipBoard from '../../components/OrderSequence/PageLayout/CopyClipBoard'
import { CakeOrderSheet } from '../../components/OrderSequence/OrderCheck/CakeOrderSheet'
import { ProductOrderSheet } from '../../components/OrderSequence/OrderCheck/ProductOrderSheet'

interface IUploadURLResponse {
  id: string
  uploadURL: string
}

export default function OrderCheck() {
  let storage = sessionStorage
  storage.setItem('checkMode', '1')
  const orderType = storage.getItem('orderType')
  const contentWidth = Number(storage.getItem('contentWidth'))

  const onLoadPage = () => {
    MoveToTop()
  }
  const [clipboardCopyLoading, setClipBoardCopyLoading] = useState(false)

  const onCapture = async () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
    setClipBoardCopyLoading(true)
    if (window.innerWidth < 1024) {
      const viewport = document.getElementById('viewport')
      if (viewport) {
        viewport.setAttribute('content', 'width=1200px')
      }
    }
    const copyRange = document.getElementById('Photo') as HTMLElement

    html2canvas(copyRange, {
      allowTaint: true,
      useCORS: true,
      scale: 2,
    }).then(canvas => {
      canvas.toBlob(
        function (blob) {
          uploadURLMutation.mutate({ file: blob as Blob })
        },
        'image/png',
        1,
      )
    })
    if (window.innerWidth < 1024) {
      const viewport = document.getElementById('viewport')
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1')
      }
    }
  }

  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }: any) => {
      storage.setItem(
        'link',
        `https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/${result.id}/public`,
      )
      console.log(
        `https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/${result.id}/public`,
      )
      setClipBoardCopyLoading(false)
      setClipBoardCopyLoaded(true)
    },
  })

  const uploadURLMutation = useMutation(getUploadURL, {
    onSuccess: (response: any) => {
      const data: IUploadURLResponse = response.data
      const file = response.file

      uploadImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: file as Blob,
      })
    },
  })
  const [orderSheetHeight, setOrderSheetHeight] = useState(1200)

  const [clipboardCopyLoaded, setClipBoardCopyLoaded] = useState(false)
  const [title, setTitle] = useState('주문내용 최종 확인')
  const [subTitle, setSubTitle] = useState(
    '내용 변경이 필요한 경우 수정해주세요',
  )

  useEffect(() => {
    if (clipboardCopyLoaded) {
      setTitle('주문내용 복사')
      setSubTitle('복사버튼을 눌러 내용을 복사하세요')
    }
  }, [clipboardCopyLoaded])

  const onLoadOrderSheet = () => {
    const copyRange = document.getElementById('orderSheet') as HTMLElement
    let height = copyRange.getClientRects()[0].height
    if (height) {
      setOrderSheetHeight(height)
    }
  }

  return (
    <>
      <div id="viewport" onLoad={onLoadPage} style={{ width: contentWidth }}>
        <div className="title-group" style={{ marginTop: 58 }}>
          <div>{title}</div>
          <div>{subTitle}</div>
        </div>
        {clipboardCopyLoaded ? (
          <CopyClipBoard orderSheetHeight={orderSheetHeight} />
        ) : null}
        <div
          id="orderSheet"
          style={clipboardCopyLoaded ? { zIndex: -2 } : {}}
          className="order-sheet-checkboard"
          onLoad={onLoadOrderSheet}
        >
          {orderType === 'C' ? (
            <CakeOrderSheet
              onCapture={onCapture}
              onLoadPage={onLoadPage}
              setClipBoardCopyLoaded={setClipBoardCopyLoaded}
            />
          ) : (
            <ProductOrderSheet
              onLoadPage={onLoadPage}
              setClipBoardCopyLoaded={setClipBoardCopyLoaded}
            />
          )}
        </div>
      </div>
      {clipboardCopyLoading ? (
        <Loading
          width={contentWidth}
          height={orderSheetHeight}
          message={'주문 내용을 최종확정하고 있습니다_최대 1분 정도 소요됩니다'}
        />
      ) : null}
    </>
  )
}
