import { VStack, Grid } from '@chakra-ui/react'
import dayjs, { Dayjs } from 'dayjs'
import { useState, useEffect } from 'react'

export default function PIckupTimeButtons({
  currentDate,
  timeSelected,
  setTimeSelected,
  dateBlocked,
  contentWidth,
  color2,
  color3,
  startTime,
  endTime,
}: any) {
  // 시작시간 부터 종료시간까지 30분씩 더하면서 시간을 구한다.
  let midTime = startTime
  let timeList1 = [startTime]
  while (midTime < endTime) {
    midTime = midTime.add(30, 'minute')
    timeList1.push(midTime)
  }

  // 시간 클릭하면 block되어 있으면 block에서 풀어주고
  // block 되어있지 않으면 block시킨다.
  const onClickTime = (timeOption: any, blocked: boolean) => {
    if (blocked) {
      const newTimeSelected = [...timeSelected]
      newTimeSelected.splice(
        newTimeSelected.indexOf(timeOption.format('HH:mm')),
        1,
      )
      setTimeSelected(newTimeSelected)
    } else {
      let newTimeSelected = [...timeSelected]
      newTimeSelected.push(timeOption.format('HH:mm'))
      setTimeSelected(newTimeSelected)
    }
  }

  const buttonDefaultStyle = {
    height: 45,
    width: (contentWidth - 50 - 18) / 4,
    background: color3,
    border: `1px solid ${color2}`,
    borderRadius: '4px',
  }
  const buttonDisabledStyle = {
    height: 45,
    width: (contentWidth - 50 - 18) / 4,
    background: '#FFF',
    border: '1px solid #D4D4D4',
    borderRadius: '4px',
    color: '#D4D4D4',
  }
  return (
    <div>
      <Grid
        width={contentWidth - 50}
        columnGap={'6px'}
        rowGap={'6px'}
        templateColumns={'repeat(4,1fr)'}
      >
        {timeList1
          ? timeList1.map((timeOption: any) => (
              <button
                key={timeOption.format('HH:mm')}
                disabled={dateBlocked}
                onClick={() =>
                  onClickTime(
                    timeOption,
                    timeSelected.includes(timeOption.format('HH:mm')),
                  )
                }
                style={
                  timeSelected.includes(timeOption.format('HH:mm')) ||
                  dateBlocked
                    ? buttonDisabledStyle
                    : buttonDefaultStyle
                }
              >
                {timeOption.format('HH:mm')}
              </button>
            ))
          : null}
      </Grid>
    </div>
  )
}
