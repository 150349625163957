
import { useState, useEffect } from "react"
import { OptionTitle } from "./OptionTitle"
export const CustomizeMessage = () => {
    let title = "상세설명"
    let placeHolder = "케이크의 컬러, 디자인 등 상세한 내용을 설명해주세요"
    let information = JSON.parse(sessionStorage.getItem('information') as string)
    if (information && 'messageSkeleton' in information) {
        title = information.messageSkeleton.name
        placeHolder = information.messageSkeleton.placeHolder
    }
    let storage = sessionStorage
    const [customMessageResult, setCustomMessageResult] = useState('')
    const [messageSet, isMessageSet] = useState(false)

    const customMessage = storage.getItem('customMessage')
    useEffect(() => {
        if (customMessage && !messageSet) {
            setCustomMessageResult(customMessage)
            isMessageSet(true)
        }
    }, [customMessage])
    const onChangeCustomMessage = (e: any) => {
        setCustomMessageResult(e.target.value)
        storage.setItem('customMessage', e.target.value as string)
    }
    const [isWriting, setIsWriting] = useState(false)

    const textAreaStyle = {
        height: isWriting ? '240px' : '105px',
    }

    useEffect(() => {
        if (customMessageResult && customMessageResult.length > 0) {
            setIsWriting(true)
        } else {
            setIsWriting(false)
        }
    }, [customMessageResult])
    return (
        <div>
            <OptionTitle title={title} mandatory={false} />
            <textarea
                placeholder={placeHolder}
                className="text-area-style"
                onChange={onChangeCustomMessage}
                value={customMessageResult as string}
                style={textAreaStyle}
            ></textarea>
        </div>
    )
}