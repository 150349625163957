import DaumPostCode from "react-daum-postcode";

export interface Address {
    우편번호: string;
    기본주소: string;
    상세주소: string;
}

interface PostCodeProps {
    address: Address;
    setAddress: React.Dispatch<React.SetStateAction<Address>>;
    setPostCodeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PostCode({ address, setAddress, setPostCodeModal }: PostCodeProps) {
    const handleComplete = (data: any) => {
        setAddress({
            ...address,
            우편번호: data.zonecode,
            기본주소: data.roadAddress,
        });
        setPostCodeModal(false);
    };

    return (
        <div>
            < DaumPostCode style={{
                display: "block",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                zIndex: 100
            }} onComplete={handleComplete} />
        </div>

    )
};
