import { Title } from "../../components/OrderSequence/Format";
import ProgressBar from "../../components/OrderSequence/PageElement/ProgressBar";
import { useState, useEffect, useRef } from "react";
import { CalculatePrice, FilterSortOption } from "../../components/utility/utils";
import "../../css/Format.scss"
import { NextButtonWithPrice } from "../../components/OrderSequence/PageElement/NextButton";
import { useNavigate, useParams } from "react-router-dom";
import { Option } from '../../components/OrderSequence/type'


export default function SelectLetteringTest() {
    const storage = sessionStorage;
    const contentWidth = Number(storage.getItem("contentWidth"));
    const progressOrder = 4;
    const checkMode = storage.getItem('checkMode')
    const color1 = storage.getItem('color1') as string
    const argument = "lettering"
    let originPrice = CalculatePrice(argument)
    const [estimatePrice, setEstimatePrice] = useState(originPrice)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    let optionData = JSON.parse(storage.getItem('options') as string)
    const [isSetFinished, SetIsSetFinished] = useState(false)
    const [options, setOptions] = useState<any[]>([])

    const [maxLength, setMaxLength] = useState(0)
    const [instructionMessage, setInstructionMessage] = useState("")
    const getInstructionMessage = (numberOptions: any) => {
        let numberOptionWithoutZero = [...numberOptions]
        numberOptionWithoutZero.splice(0, 1)
        if (numberOptionWithoutZero.length === 1) {
            return `추가금 ${numberOptionWithoutZero[0].value / 1000}천원`
        }
        let message = numberOptionWithoutZero.reduce(
            (acc: any, cur: any) =>
                acc + `${cur.name} ${cur.value / 1000}천원, `, '');
        message = message.replace(/,\s*$/, '');

        return message
    }

    useEffect(() => {
        if (optionData && !isSetFinished) {
            const tempSort = FilterSortOption(optionData, 'lettering')
            setOptions(tempSort)
            setMaxLength(tempSort[tempSort.length - 1].information.letter_range[1])
            setInstructionMessage(getInstructionMessage(tempSort))
            let letterOption = JSON.parse(storage.getItem('lettering') as string)
            let letterContent = storage.getItem('letteringContent') as string
            setInstructionMessage(getInstructionMessage(tempSort))
            if (letterOption) {
                setLetteringContent(letterContent)
                setLetteringOption(letterOption)
                setTextCount(letterContent.replace(/\s/g, '').length)

            }
            else {
                setLetteringOption(tempSort[0])
            }
            setButtonDisabled(false)
            SetIsSetFinished(true)
        }
    }, [optionData])

    const [letteringOption, setLetteringOption] = useState<Option>();
    useEffect(() => {
        if (letteringOption) {
            setEstimatePrice(originPrice + letteringOption.value)
        }
    }, [letteringOption])

    const [textCount, setTextCount] = useState(0)
    const [letteringContent, setLetteringContent] = useState('')

    const FindLetteringOption = (count: number) => {
        let optionSelected = options[0]
        options.forEach((option: Option) => {
            const [startLetterCount, EndLetterCount] = option.information.letter_range
            if (startLetterCount <= count && count <= EndLetterCount) {
                optionSelected = option
            }
        })
        return optionSelected
    }
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const HandleTextAreaChange = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'
            textareaRef.current.style.height = textareaRef.current?.scrollHeight + 'px'
        }
    }
    const onChangeText = (e: any) => {
        const countWithoutJump = e.target.value.replace(/\s/g, '').length
        if (countWithoutJump <= maxLength) {
            setTextCount(countWithoutJump)
            setLetteringOption(FindLetteringOption(countWithoutJump))
            setLetteringContent(e.target.value)
            HandleTextAreaChange()
        }
    }

    const nav = useNavigate();
    const { shopName, orderPk } = useParams();
    const onClickSubmit = () => {
        if (letteringOption) {
            storage.setItem('letteringContent', letteringContent)
            storage.setItem('lettering', JSON.stringify(letteringOption))
            if (checkMode) {
                nav(`/order/${shopName}/${orderPk}/Check`)
            } else {
                nav(`/order/${shopName}/${orderPk}/${letteringOption.next_point}`)
            }
        }
    }
    let placeHolder = "ex) 엄마아빠 환갑 축하드려요!"
    let information = JSON.parse(sessionStorage.getItem('information') as string)
    if (information && 'letteringPlaceHolder' in information) {

        placeHolder = information.letteringPlaceHolder
    }
    return (
        <div
            style={{
                width: contentWidth,
                textAlign: 'center',
            }}
        >
            <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={progressOrder} />

            <Title
                title={'레터링 넣기'}
                subTitle={'필요하지 않은 경우 다음으로 넘어가세요'} />
            <textarea
                ref={textareaRef}
                rows={1}
                className="lettering-input-wide"
                placeholder={placeHolder}
                value={letteringContent}
                onChange={onChangeText}
            />
            <div style={{
                marginTop: 5,
                display: 'flex',
                width: contentWidth - 50,
                marginLeft: 25,
                alignItems: "start"
            }}>
                <span
                    className="instruction-message">{instructionMessage}</span>
                <div style={{
                    display: 'flex',
                    textAlign: 'start',
                    whiteSpace: 'nowrap'
                }}>
                    <span className="letter-count-main">{textCount}자</span>
                    <span className="letter-count-sub">/ {maxLength}자</span>
                </div>

            </div>


            <NextButtonWithPrice
                buttonDisabled={buttonDisabled}
                onClickSubmit={onClickSubmit}
                contentWidth={contentWidth}
                color1={color1}
                checkMode={checkMode}
                estimatePrice={estimatePrice}
            />
        </div >)

}