import { useEffect, useState } from 'react'
import { Box, Button, Text, VStack, AspectRatio } from '@chakra-ui/react'
import '../../../css/UserCalendar.css'
export default function CalendarUserDay({
  onClickTargetDay,
  date,
  disabled,
  selected,
  blocked,
  selectColor,
}: any) {
  //  날짜
  const [dateRef, setDateRef] = useState(0)
  const [selectedCheck, setSelectedCheck] = useState(false)
  useEffect(() => {
    if (date) {
      setDateRef(date.get('date'))
    }
    if (date && selected) {
      if (date.format('YYYY:MM:DD') === selected.format('YYYY:MM:DD')) {
        setSelectedCheck(true)
      } else {
        setSelectedCheck(false)
      }
    }
  }, [selected])

  return (
    <AspectRatio width={'100%'} ratio={1 / 1}>
      <div
        style={{
          justifyItems: 'center',
          width: '100%',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!blocked && !disabled) onClickTargetDay(date)
        }}
      >
        {blocked ? (
          <button disabled className="day">
            {dateRef}
          </button>
        ) : selectedCheck ? (
          <div style={{ background: selectColor }} className="active_mark">
            {dateRef}
          </div>
        ) : (
          <button disabled={disabled} className="day">
            {dateRef}
          </button>
        )}
      </div>
    </AspectRatio>
  )
}
