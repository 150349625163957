import { memo, useEffect } from "react";
import DesignCard from "./DesignCard";
import { CalculateDesignGroupMinPrice } from "../../utility/utils";
const DesignGroupGrid = ({
    refOfDesignGroup,
    designGroupName,
    designOptions,
    contentWidth,
    color1,
    checkIsSelected }: any) => {

    return (
        <div key={designGroupName}
            ref={refOfDesignGroup}
            id={designGroupName}>
            <div className='design-group-title'>
                <div
                >
                    {designGroupName}
                </div>
                <span
                    style={{ color: color1 }}>
                    {`${CalculateDesignGroupMinPrice(designGroupName).toLocaleString('en-us')}원 부터~`}
                </span>
            </div>
            <div className='design-grid'
                style={{ width: contentWidth - 50 }}>
                {designOptions?.map((option: any) => (
                    <DesignCard
                        key={designGroupName + option.name}
                        checkIsSelected={checkIsSelected}
                        option={option}
                    />))}
            </div >
        </div>
    )
}
export default memo(DesignGroupGrid)