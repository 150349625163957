type DayMap = {
  [key: number]: string
}
export const DAY_MAP: DayMap = {
  0: '일',
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
}
