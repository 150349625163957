import { useEffect, useState } from 'react'
import '../../../css/Common.scss'
import { CalculatePrice } from '../../utility/utils'
import ProgressBar from '../PageElement/ProgressBar'
import { NextButtonWithPrice } from '../PageElement/NextButton'
import {
    ExamplePhoto,
    HorizontalChoiceButton,
    LetterInput,
    Title,
} from '../Format'
import { Option } from '../type'
import { useNavigate, useParams } from 'react-router-dom'

export default function HorizontalChoiceLayout({
    progressOrder,
    title,
    subTitle,
    argument,
    options,
    draftLetter,
}: any) {
    let storage = sessionStorage
    let originPrice = CalculatePrice(argument)
    const contentWidth = Number(storage.getItem('contentWidth'))
    const color1 = storage.getItem('color1') as string
    const checkMode = storage.getItem('checkMode')

    const [estimatePrice, setEstimatePrice] = useState(originPrice)
    const [buttonDisabled, setButtonDisabled] = useState(true)

    useEffect(() => {
        if (options.length !== 0) {
            let tmp: Option = JSON.parse(storage.getItem(argument) as string)
            if (tmp) {
                setSelectedOption(tmp)
                setEstimatePrice(estimatePrice + tmp.value)
            } else {
                setSelectedOption(options[0])
                setEstimatePrice(estimatePrice + options[0].value)
            }
        }
        setButtonDisabled(false)
    }, [options])

    const onClickButton = (option: any) => {
        if (selectedOption && option.name === selectedOption.name) {
            let x = { ...option }
            x.name = ''
            setSelectedOption(x)
            setButtonDisabled(true)
        } else {
            setSelectedOption(option)
            setButtonDisabled(false)
            setEstimatePrice(originPrice + option.value)
        }
    }
    const nav = useNavigate()
    const { shopName, orderPk } = useParams()
    const [selectedOption, setSelectedOption] = useState<Option>()

    const onClickSubmit = () => {
        if (selectedOption) {
            storage.removeItem(argument)
            storage.setItem(argument, JSON.stringify(selectedOption))
            if (checkMode) {
                nav(`/order/${shopName}/${orderPk}/Check`)
            } else {
                nav(`/order/${shopName}/${orderPk}/${selectedOption.next_point}`)
            }
        }
    }


    useEffect(() => {
        if (selectedOption) {
            setButtonDisabled(false)
            setEstimatePrice(origin => origin + selectedOption.value)
        }
        else {
            setButtonDisabled(false)
        }
    }, [selectedOption])
    return (
        <div
            style={{
                width: contentWidth,
                textAlign: 'center',
            }}
        >
            <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={progressOrder} />

            <Title contentWidth={contentWidth} title={title} subTitle={subTitle} />
            <div
                style={{
                    marginTop: 35,
                    marginLeft: 25,
                    width: contentWidth - 50,
                }}
            >
                {options.length !== 0 ? (
                    <HorizontalChoiceButton
                        options={options}
                        selected={selectedOption}
                        contentWidth={contentWidth}
                        color1={color1}
                        onClickButton={onClickButton}
                    />
                ) : null}
                {selectedOption && selectedOption.example_photos ? (
                    <ExamplePhoto contentWidth={contentWidth} selected={selectedOption} />
                ) : (
                    <></>
                )}
                <div
                    style={{
                        width: contentWidth - 50,
                        marginLeft: 25,
                        height: 150,
                    }}
                ></div>
                <NextButtonWithPrice
                    buttonDisabled={buttonDisabled}
                    onClickSubmit={onClickSubmit}
                    contentWidth={contentWidth}
                    color1={color1}
                    checkMode={checkMode}
                    estimatePrice={estimatePrice}
                />
            </div>
        </div>
    )
}
