import { Title } from "../../components/OrderSequence/Format";
import ProgressBar from "../../components/OrderSequence/PageElement/ProgressBar";
import { useState, useEffect } from "react";
import { CalculatePrice, FilterSortOption } from "../../components/utility/utils";
import "../../css/SelectNumber.scss"
import { NextButtonWithPrice } from "../../components/OrderSequence/PageElement/NextButton";
import { useNavigate, useParams } from "react-router-dom";
import { Option } from '../../components/OrderSequence/type'


export default function SelectNumber() {
    const storage = sessionStorage;
    const contentWidth = Number(storage.getItem("contentWidth"));
    const progressOrder = 4;
    const checkMode = storage.getItem('checkMode')
    const color1 = storage.getItem('color1') as string
    const argument = "number"
    let originPrice = CalculatePrice(argument)
    const [estimatePrice, setEstimatePrice] = useState(originPrice)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [maxLength, setMaxLength] = useState(0);

    let optionData = JSON.parse(storage.getItem('options') as string)
    const [isSetFinished, SetIsSetFinished] = useState(false)
    const [options, setOptions] = useState<any[]>([])

    const [instructionMessage, setInstructionMessage] = useState("")
    const getInstructionMessage = (numberOptions: any) => {
        let numberOptionWithoutZero = [...numberOptions]
        numberOptionWithoutZero.splice(0, 1)
        let message = numberOptionWithoutZero.reduce(
            (acc: any, cur: any) =>
                acc + `${cur.name} ${cur.value / 10000}만원, `, '');
        message = message.replace(/,\s*$/, '');

        return message
    }
    useEffect(() => {
        if (optionData && !isSetFinished) {
            const tempSort = FilterSortOption(optionData, 'number')
            setOptions(tempSort)
            let lastOptionName = tempSort[tempSort.length - 1].name
            if (lastOptionName) {
                let extractedDigit = lastOptionName.match(/\d+/)
                if (extractedDigit) setMaxLength(Number(extractedDigit[0]))
            }
            let numberOption = JSON.parse(storage.getItem('number') as string)
            let numberContent = storage.getItem('numberContent') as string
            setInstructionMessage(getInstructionMessage(tempSort))
            if (numberOption) {
                numberContent = numberContent.replace(/[^0-9.]+/g, "");
                setNumber(numberContent)
                setNumberOption(numberOption)
            }
            SetIsSetFinished(true)
        }
    }, [optionData])
    const [number, setNumber] = useState("")
    const [numberOption, setNumberOption] = useState<Option>();
    const onChangeNumber = (event: any) => {
        if (event.target.value.length <= maxLength) {
            setNumber(event.target.value)
            setNumberOption(options[event.target.value.length])
            setEstimatePrice(originPrice + options[event.target.value.length].value)
        }
    }
    const nav = useNavigate();
    const { shopName, orderPk } = useParams();
    const onClickSubmit = () => {
        if (numberOption) {
            storage.setItem('number', JSON.stringify(numberOption))
            storage.setItem('numberContent', JSON.stringify(number))
            if (checkMode) {
                nav(`/order/${shopName}/${orderPk}/Check`)
            } else {
                nav(`/order/${shopName}/${orderPk}/${numberOption.next_point}`)
            }
        }
    }
    useEffect(() => {
        if (numberOption && numberOption.value !== 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [
        numberOption
    ])
    return (
        <div
            style={{
                width: contentWidth,
                textAlign: 'center',
            }}
        >
            <ProgressBar checkMode={checkMode} contentWidth={contentWidth} orderIndex={progressOrder} />

            <Title
                title={'숫자 입력'}
                subTitle={'행사 규모에 맞추어 사이즈를 골라주세요'} />
            <input
                className="number-input"
                type="text"
                placeholder="ex) 60"
                value={number}
                onChange={onChangeNumber}
            />
            <div style={{ marginLeft: 25, textAlign: 'left', marginTop: '12px' }}>
                <span
                    className="instruction-message">{instructionMessage}</span>
            </div>
            <NextButtonWithPrice
                buttonDisabled={buttonDisabled}
                onClickSubmit={onClickSubmit}
                contentWidth={contentWidth}
                color1={color1}
                checkMode={checkMode}
                estimatePrice={estimatePrice}
            />
        </div>)

}